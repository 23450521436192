import axios from "axios";
import { Ref, reactive } from "vue";
import { Http } from "./Http";
import { setAxiosInterceptors } from "./AxiosInterceptors";
import { User } from "./User";
import { Comparison } from "./Comparison";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { setI18n } from "../translations";
import { vueRouter, setRouter } from "../router";
import { Chart } from "chart.js";

const http = new Http();
const user = reactive(new User());
const comparison = new Comparison();
const i18n = setI18n(user);
const t = i18n.global.t;
let route, router, _toast;

export { route, router, user, http, t, comparison, i18n, vueRouter };

setAxiosInterceptors(user);
setRouter(user);

export const formatMoney = (value) => {
  if (!value) return "";
  value = value.toString();
  return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

export const defaultsDeepWithSet = (targetObj, sourceObj) => {
  for (const prop in sourceObj) {
    if (Object.prototype.hasOwnProperty.call(sourceObj, prop)) {
      if (!Object.prototype.hasOwnProperty.call(targetObj, prop)) {
        targetObj[prop] = sourceObj[prop];
      }
      if (sourceObj[prop] === Object(sourceObj[prop])) {
        defaultsDeepWithSet(targetObj[prop], sourceObj[prop]);
      }
    }
  }
};

export const chunk = (arr: any[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_: any, i: number) =>
    arr.slice(i * size, i * size + size)
  );

export const remove = (arr, func) =>
  Array.isArray(arr)
    ? arr.filter(func).reduce((acc, val) => {
      arr.splice(arr.indexOf(val), 1);
      return acc.concat(val);
    }, [])
    : [];

export const toggle = (value: Ref) =>
  value.value = !value.value;

export const init = () => {
  route = useRoute();
  router = useRouter();
  _toast = useToast();
};

export const toast = (message, level = "info") => { 
  if (!message) return;

  _toast.add({
    severity: level,
    summary: message,
    life: 5000,
    closable: false,
    styleClass: "text-center"
  });
};

export const chartResize = () => {
  console.log(Chart.instances);
  for (let id in Chart.instances) {
    Chart.instances[id].resize(300, 150);
    console.log("chart " + id + " resized");
  }
};