<template>
  <div v-if="!loading"
       class="page-marketing">
    <div class="container-fluid p-initial">
      <div class="row print-new-page">
        <div class="col-12 col-xxl-8">
          <PageCard :title="t('marketingData.title')"
                    icon="marketing">
            <template #content>
              <div class="co-marketing">
                <div v-if="marketing.contact?.phones?.length"
                     class="info-card">
                  <div class="info-main">
                    <div class="icon marketing-phone" />
                    <h3 class="title">
                      {{ t('marketingData.mobile') }}
                    </h3>
                  </div>
                  <div class="info-list">
                    <div v-for="(phone, index) in marketing.contact.phones"
                         :key="'phone-' + index"
                         class="info-mobile">
                      <div class="icon mobile" />
                      <a :href="'tel:' + phone"
                         class="link">{{ phone }}</a>
                    </div>
                  </div>
                </div>
                <div v-if="marketing.contact?.emails?.length"
                     class="info-card">
                  <div class="info-main">
                    <div class="icon marketing-mail" />
                    <h3 class="title">
                      {{ t('marketingData.email') }}
                    </h3>
                  </div>
                  <div class="info-list">
                    <div v-for="(email, index) in marketing.contact.emails"
                         :key="'email-' + index"
                         class="info-email">
                      <div class="icon email" />
                      <a :href="'mailto:' + email "
                         class="link">{{ email }}</a>
                    </div>
                  </div>
                </div>
                <div v-if="marketing.contact?.websites?.length"
                     class="info-card">
                  <div class="info-main">
                    <div class="icon marketing-web" />
                    <h3 class="title">
                      {{ t('marketingData.web') }}
                    </h3>
                  </div>
                  <div class="info-list">
                    <div v-for="(website, index) in marketing.contact.websites"
                         :key="'website-' + index"
                         class="info-web">
                      <div class="icon web" />
                      <a :href="website"
                         target="_blank"
                         class="link">{{ website }}</a>
                    </div>
                  </div>
                </div>
                <template v-if="marketing.interests?.length">
                  <div class="interests">
                    <i class="icon connections_1" />
                    <h3 class="title">
                      {{ t('marketingData.interests.title') }}
                    </h3>
                  </div>
                  <InteractiveTable :columns="interests"
                                    :items="marketing.interests" />
                </template>
                <template v-if="marketing.associations?.length">
                  <div class="interests">
                    <i class="icon connections_1" />
                    <h3 class="title">
                      {{ t('marketingData.associations.title') }}
                    </h3>
                  </div>
                  <InteractiveTable :columns="associations"
                                    :items="marketing.associations" />
                </template>
              </div>
            </template>
          </PageCard>
        </div>
        <div class="col-12 col-xxl-4">
          <DidYouKnow page-name="marketing"
                      :sticky="true"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, watch, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { http, t } from "@/logic";

  const route = useRoute();
  const marketing = ref({} as any);
  const loading = ref(false);
  const interests = ref([
    {
      value: t("marketingData.interests.columns.registrationNumber"),
      prop: "registrationNumber",
      type: "url",
      width: "15%",
    },
    {
      value: t("marketingData.interests.columns.name"),
      prop: "name",
      width: "30%",
    },
    {
      value: t("marketingData.interests.columns.status"),
      prop: "status",
      type: "icon",
      width: "30%",
      class: "justify-content-center",
    },
    {
      value: t("marketingData.interests.columns.validity"),
      prop: "validity",
      type: "icon",
      width: "20%",
      class: "justify-content-center",
    },
  ]);
  const associations = ref([
    {
      value: t("marketingData.associations.columns.name"),
      prop: "type",
      width: "60%",
    },
    {
      value: t("marketingData.associations.columns.website"),
      prop: "link",
      type: "link",
      width: "40%",
    },
  ]);

  onMounted(async () => {
    http.fetchWithLoading(marketing, loading, "/api/report/" + route.params.id + "/marketing");
  });
</script>

<style lang="scss" scoped>
.co-marketing {
  padding: 2rem;

  .info-list {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
  }

  & .info-card {
    display: flex;
    gap: 3rem;
    align-items: center;
    margin: 2rem 0;
    padding: 2rem;
    border: 2px solid $color-border-secondary;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & .info-main {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }

      & .icon {
        margin-right: 0.5rem;
        color: $color-primary;
        font-weight: 400;
        font-size: 2.4rem;
      }

      & .title {
        min-width: 10rem;
        color: $color-font-primary;
        font-weight: 500;
        font-size: 1.6rem;
        text-transform: uppercase;
      }
    }

    & .info-mobile,
    & .info-email,
    & .info-web {
      display: flex;
      align-items: center;
      margin-left: 3rem;
      white-space: nowrap;

      @include media-breakpoint-down(md) {
        margin: 0.5rem 0;
      }

      & .link {
        color: $color-font-primary;
        font-weight: 500;
        font-size: 1.4rem;
        transition: color 0.2s ease-in-out;

          &:hover {
            color: $color-blue;
            text-decoration: none;
            cursor: pointer;
          }
      }

      & .icon {
        color: $color-blue;
        font-size: 3rem;
      }
    }
  }

  & .interests {
    display: flex;
    align-items: center;
    margin: 3rem 0;

    & img {
      height: 2rem;
      margin-right: 1rem;
    }

    & .title {
      color: $color-font-primary;
      font-weight: 500;
      font-size: 1.6rem;
    }
  }

  & .icon {
    margin-right: 0.5rem;
    color: $color-primary;
    font-weight: 400;
    font-size: 2.4rem;
  }
}
</style>
