<template>
  <div class="errorbg"
       :style="{'backgroundImage': 'url(/assets/img/error/' + name + '.png)' }">
    <div v-if="!requestOfferSent"
         class="error">
      <h3 class="mb-4">
        {{ t('error.dearUser') }}
      </h3>
      <div>
        <p>{{ t('error.' + type) }}</p>
      </div>
      <div v-if="type == 'module'"
           class="button button--primary mt-5"
           @click="requestOffer">
        {{ t('error.requestOffer') }}
      </div>
      <div v-else
           class="button button--primary mt-5"
           @click="sendMessage">
        {{ t('sendMessage.title') }}
      </div>
    </div>
    <div v-else
         class="error">
      <h3 class="mb-4">
        {{ t('error.dearUser') }}
      </h3>
      <div>
        <p>{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { http, t } from "@/logic";

  const route = useRoute();
  const router = useRouter();
  const requestOfferSent = ref(false);
  const message = ref("");

  const type = computed(() => {
    return route.params.type;
  });

  const name = computed(() => {
    return route.params.name;
  });

  function sendMessage() {
    return router.push({ name: "send-message" });
  }

  async function requestOffer() {
    const res = await http.post("/api/request-offer", {
      feature: route.params.name,
    });
    requestOfferSent.value = true;
    message.value = res.notification;
  }
</script>

<style scoped lang="scss">
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  margin: 15rem auto 15rem auto;
  padding: 2rem;
}

.errorbg {
  background-repeat: no-repeat, no-repeat;
  margin: auto;
  height: 800px;
  background-size: contain;
  background-position: center, center;
}
</style>
