<template>
  <div>
    <SearchPage v-if="!loading && meta.count > 0"
                icon="favourite-add"
                title="Kedvencek">
      <template #content>
        <Results :columns="columns"
                 :items="data.results"
                 :meta="meta"
                 @on-order="onOrder">
          <template #delete="props">
            <i class="icon delete"
               @click="remove(props.row.id)" />
          </template>
        </Results>
      </template>
    </SearchPage>

    <div v-else
         class="container-fluid p-initial">
      <div class="row">
        <div class="col-12">
          <PageCard class="pb-5"
                    icon="favourite-add"
                    title="Kedvencek">
            <template #content>
              <div class="p-5">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h3 class="mb-3">
                      {{ t('favorites.empty1') }}
                    </h3>
                    <p class="mb-3">
                      {{ t('favorites.empty2') }}
                    </p>
                    <h4 class="mb-5">
                      {{ t('favorites.empty3') }}
                    </h4>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img class="img-fluid"
                         src="/assets/img/favourite.png"
                         alt="">
                  </div>
                </div>
              </div>
            </template>
          </PageCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { http, t } from "@/logic";

  const route = useRoute();
  const loading = ref(false);
  const meta = ref({
    orderBy: "name",
    order: "asc",
    currentPage: 1,
    perPage: 10,
    count: 0,
  } as any);
  const data = ref({} as any);
  const columns = ref([
    {
      value: "Név",
      prop: "name",
      type: "url",
      orderable: true,
      width: "60%",
    },
    {
      value: "Létrehozva",
      prop: "createdAt",
      width: "20%",
    },
    {
      value: "Törlés",
      prop: "delete",
      type: "slot",
      width: "20%",
      class: "justify-content-md-center d-md-flex cursor",
    },
  ]);

  async function load() {
    let query = `?orderBy=${meta.value.orderBy}&order=${meta.value.order}&currentPage=${meta.value.currentPage}&perPage=${meta.value.perPage}`;
    await http.fetchWithLoading(data, loading, `/api/favorites${query}`);
    meta.value.count = data.value.meta.count;
  }

  async function onOrder(order) {
    meta.value.currentPage = 1;
    meta.value.orderBy = order.orderBy;
    meta.value.order = order.order;
    await load();
  }

  async function remove(id) {
    await http.delete("/api/favorites/" + id + "/delete");
    await load();
  }

  onMounted(async () => {
    await load();
  });
</script>

<style lang="scss" scoped>
.img-fluid {
  border: 1px solid $color-border-primary;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}
</style>
