<template>
  <div v-if="!loading"
       class="page-common-ratings">
    <div class="container-fluid p-initial">
      <div class="row">
        <div class="col-12">
          <PageCard :title="t('commonRatings.title')"
                    icon="common-ratings">
            <template #content>
              <div v-if="charts"
                   class="px-5">
                <div class="main-commmon-ratings clearfix">
                  <div class="row print-row">
                    <div v-if="charts.operatingProfit.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.operatingProfit.title')"
                                 :info-text="t('commonRatings.charts.operatingProfit.info')"
                                 :data="charts.operatingProfit.data" />
                    </div>
                    <div v-if="charts.turnover.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.turnover.title')"
                                 :info-text="t('commonRatings.charts.turnover.info')"
                                 :data="charts.turnover.data" />
                    </div>
                    <div v-if="charts.ebitda.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.ebitda.title')"
                                 :info-text="t('commonRatings.charts.ebitda.info')"
                                 :data="charts.ebitda.data" />
                    </div>
                    <div v-if="charts.employees.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.employees.title')"
                                 :info-text="t('commonRatings.charts.employees.info')"
                                 :data="charts.employees.data" />
                    </div>
                  </div>
                </div>

                <Collapse :opened="true"
                          :title="t('commonRatings.collapses.capitalStructure')"
                          :chart-collapse="true">
                  <div class="row print-row">
                    <div v-if="charts.capitalSupply.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.capitalStructure.capitalSupply.title')"
                                 :info-text="t('commonRatings.charts.capitalStructure.capitalSupply.info')"
                                 :data="charts.capitalSupply.data" />
                    </div>
                    <div v-if="charts.debtRate.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.capitalStructure.debtRate.title')"
                                 :info-text="t('commonRatings.charts.capitalStructure.debtRate.info')"
                                 :data="charts.debtRate.data" />
                    </div>
                    <div v-if="charts.netDebtRate.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.capitalStructure.netDebtRate.title')"
                                 :info-text="t('commonRatings.charts.capitalStructure.netDebtRate.info')"
                                 :data="charts.netDebtRate.data" />
                    </div>
                    <div v-if="charts.capitalStress.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.capitalStructure.capitalStress.title')"
                                 :info-text="t('commonRatings.charts.capitalStructure.capitalStress.info')"
                                 :data="charts.capitalStress.data" />
                    </div>
                    <div v-if="charts.capitalFinancing.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.capitalStructure.capitalFinancing.title')"
                                 :info-text="t('commonRatings.charts.capitalStructure.capitalFinancing.info')"
                                 :data="charts.capitalStress.data" />
                    </div>
                    <div v-if="charts.ownCapitalRate.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.capitalStructure.ownCapitalRate.title')"
                                 :info-text="t('commonRatings.charts.capitalStructure.ownCapitalRate.info')"
                                 :data="charts.capitalStress.data" />
                    </div>
                  </div>
                </Collapse>

                <Collapse :opened="true"
                          :title="t('commonRatings.collapses.workingCapital')"
                          :chart-collapse="true">
                  <div class="row print-row">
                    <div v-if="charts.workingCapitalSupply.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.workingCapital.workingCapitalSupply.title')"
                                 :info-text="t('commonRatings.charts.workingCapital.workingCapitalSupply.info')"
                                 :data="charts.workingCapitalSupply.data" />
                    </div>
                    <div v-if="charts.workingCapitalTurnover.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.workingCapital.workingCapitalTurnover.title')"
                                 :info-text="t('commonRatings.charts.workingCapital.workingCapitalTurnover.info')"
                                 :data="charts.workingCapitalTurnover.data" />
                    </div>
                    <div v-if="charts.capitalTurnoverRate.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.workingCapital.capitalTurnoverRate.title')"
                                 :info-text="t('commonRatings.charts.workingCapital.capitalTurnoverRate.info')"
                                 :data="charts.capitalTurnoverRate.data" />
                    </div>
                  </div>
                </Collapse>

                <Collapse :opened="true"
                          :title="t('commonRatings.collapses.financialPosition')"
                          :chart-collapse="true">
                  <div class="row print-row">
                    <div v-if="charts.liquidityRate.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.financialPosition.liquidityRate.title')"
                                 :info-text="t('commonRatings.charts.financialPosition.liquidityRate.info')"
                                 :data="charts.liquidityRate.data" />
                    </div>
                    <div v-if="charts.liquidityQuickRate.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.financialPosition.liquidityQuickRate.title')"
                                 :info-text="t('commonRatings.charts.financialPosition.liquidityQuickRate.info')"
                                 :data="charts.liquidityQuickRate.data" />
                    </div>
                  </div>
                </Collapse>

                <Collapse :opened="true"
                          :title="t('commonRatings.collapses.revenueExamination')"
                          :chart-collapse="true">
                  <div class="row print-row">
                    <div v-if="charts.toolEfficiency.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.revenueExamination.toolEfficiency.title')"
                                 :info-text="t('commonRatings.charts.revenueExamination.toolEfficiency.info')"
                                 :data="charts.toolEfficiency.data" />
                    </div>
                    <div v-if="charts.activityProfitMargin.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.revenueExamination.activityProfitMargin.title')"
                                 :info-text="t('commonRatings.charts.revenueExamination.activityProfitMargin.info')"
                                 :data="charts.activityProfitMargin.data" />
                    </div>
                    <div v-if="charts.grossProfitMargin.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.revenueExamination.grossProfitMargin.title')"
                                 :info-text="t('commonRatings.charts.revenueExamination.grossProfitMargin.info')"
                                 :data="charts.grossProfitMargin.data" />
                    </div>
                  </div>
                </Collapse>

                <Collapse :opened="true"
                          :title="t('commonRatings.collapses.profitability')"
                          :chart-collapse="true">
                  <div class="row print-row">
                    <div v-if="charts.roe.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.profitability.roe.title')"
                                 :info-text="t('commonRatings.charts.profitability.roe.info')"
                                 :data="charts.roe.data" />
                    </div>
                    <div v-if="charts.ros.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.profitability.ros.title')"
                                 :info-text="t('commonRatings.charts.profitability.ros.info')"
                                 :data="charts.ros.data" />
                    </div>
                    <div v-if="charts.roa.data.datasets.length"
                         class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('commonRatings.charts.profitability.roa.title')"
                                 :info-text="t('commonRatings.charts.profitability.roa.info')"
                                 :data="charts.roa.data" />
                    </div>
                  </div>
                </Collapse>
              </div>
            </template>
          </PageCard>
        </div>

        <div class="col-12 col-xxl-10">
          <DidYouKnow page-name="common-ratings"
                      :sticky="false"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import { http, t } from "@/logic";

  const route = useRoute();
  const loading = ref(false);
  const charts = ref(null);

  onMounted(async () => {
    await http.fetchWithLoading(charts, loading, "/api/report/" + route.params.id + "/indicators");
  });
</script>

<style lang="scss" scoped>
.main-commmon-ratings {
  margin-bottom: 4rem;
  padding-top: 1rem;
}

  .card {
    padding: 2rem;
    margin: 2.5rem 0.3rem 0rem 0.3rem;
  }

  .co-collapse {
    border-top: none !important;
  }
</style>
