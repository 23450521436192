<template>
  <div>
    <div class="background-image" />
    <Card class="page-profile-settings">
      <template #header>
        <i class="pi icon home-settings" />
        <span class="title">{{ t('profileSettings.title') }}</span>
      </template>
      <template #content>
        <div class="settings">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="box">
                  <h3 class="title">
                    {{ t('profileSettings.basicSettingsUser') }}
                  </h3>
                  <hr class="line">
                  <div class="co-checkbox-primary">
                    <Checkbox v-model="data.companyReportValidDetails"
                              input-id="companyReportValidDetails"
                              :binary="true" />
                    <label for="companyReportValidDetails">{{ t('profileSettings.companyReportValidDetails') }}</label>
                  </div>
                  <div class="co-checkbox-primary">
                    <Checkbox v-model="data.companyReportOpenAll"
                              input-id="companyReportValidDetails"
                              :binary="true" />
                    <label for="companyReportOpenAll">{{ t('profileSettings.companyReportOpenAll') }}</label>
                  </div>
                  <div class="co-checkbox-primary">
                    <Checkbox v-model="data.companyReportThousands"
                              input-id="companyReportThousands"
                              :binary="true" />
                    <label for="companyReportThousands">{{ t('profileSettings.companyReportThousands') }}</label>
                  </div>
                  <div class="co-checkbox-primary">
                    <Checkbox v-model="data.companyReportHideHistory"
                              input-id="companyReportHideHistory"
                              :binary="true" />
                    <label for="companyReportHideHistory">{{ t('profileSettings.companyReportHideHistory') }}</label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="box">
                  <h3 class="title">
                    {{ t('profileSettings.basicSettingsCompany') }}
                  </h3>
                  <hr class="line">
                  <div class="co-checkbox-primary">
                    <Checkbox v-model="data.monitoringNegativeEvents"
                              input-id="monitoringNegativeEvents"
                              :binary="true" />
                    <label for="monitoringNegativeEvents">{{ t('profileSettings.monitoringNegativeEvents') }}</label>
                  </div>
                  <div class="co-checkbox-primary">
                    <Checkbox v-model="data.notificationOfCloses"
                              input-id="notificationOfCloses"
                              :binary="true" />
                    <label for="notificationOfCloses">{{ t('profileSettings.notificationOfCloses') }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="box">
                  <h3 class="title">
                    {{ t('profileSettings.monitoringSettings') }}
                  </h3>
                  <hr class="line">
                  <div class="co-checkbox-primary">
                    <Checkbox v-model="data.monitoringTable"
                              input-id="monitoringTable"
                              :binary="true" />
                    <label for="monitoringTable">{{ t('profileSettings.monitoringTable') }}</label>
                  </div>
                  <div class="co-checkbox-primary">
                    <Checkbox v-model="data.weeklyMonitoringReport"
                              input-id="weeklyMonitoringReport"
                              :binary="true" />
                    <label for="weeklyMonitoringReport">{{ t('profileSettings.weeklyMonitoringReport') }}</label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="box">
                  <h3 class="title">
                    {{ t('profileSettings.marketingSettings') }}
                  </h3>
                  <hr class="line">
                  <div class="co-checkbox-primary">
                    <Checkbox v-model="data.salesView"
                              input-id="salesView"
                              :binary="true" />
                    <label for="salesView">{{ t('profileSettings.salesView') }}</label>
                  </div>
                  <div class="co-input-profile">
                    <label style="margin-right: 1rem">Keresési találatok száma / oldal</label>
                    <Dropdown v-model="data.perPage"
                              :options="perPageOptions"
                              option-label="name"
                              option-value="value" />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <p class="text">
                {{ t('profileSettings.saveSettings') }}
              </p>
              <Button :label="t('profileSettings.save')"
                      class="mt-3"
                      @click="store" />
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted } from "vue";
  import { http, t, toast, user, init } from "@/logic";

  init();

  const perPageOptions = ref([
    {
      name: "10",
      value: 10
    },
    {
      name: "20",
      value: 20
    },
    {
      name: "50",
      value: 50
    }
  ]);

  const data = ref({
    companyReportValidDetails: false,
    companyReportOpenAll: false,
    companyReportThousands: false,
    companyReportHideHistory: false,
    monitoringNegativeEvents: false,
    notificationOfCloses: false,
    monitoringTable: false,
    weeklyMonitoringReport: false,
    salesView: false,
    perPage: 10,
  });

  async function load() {
    await http.fetch(data, "/api/settings");
  }

  async function store() {
    await user.fetchMe();
    await toast((await http.post("/api/settings", data.value)).message, "success");
  }

  onMounted(async () => {
    await load();
  });
</script>

<style lang="scss" scoped>
.page-profile-settings {
  position: relative;
  left: 50%;
  width: 100%;
  max-width: 110rem;
  margin-top: 25vh;
  transform: translateX(-50%);

  & .settings {
    & .row {
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    & .col-12 {
      @include media-breakpoint-down(md) {
        margin-bottom: 3rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    & .box {
      margin: 3rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      & .title {
        margin-bottom: 1rem;
        color: $color-font-quaternary;
        font-weight: 500;
        font-size: 1.2rem;
        text-transform: uppercase;
      }
    }
  }
}

.search-results {
  margin-left: 10px;
  background-color: #fff;
}
</style>
