<template>
  <div v-if="!loading"
       class="page-negative-info">
    <div class="p-initial">
      <div class="row">
        <div class="col-12 col-xxl-8">
          <PageCard :title="t(type + 'Infos.title')"
                    :icon="type + '-info'" 
                    :icon-color="(type == 'negative' ? colorNegative : colorPositive) + ' !important'">
            <template #controls>
              <div class="d-md-flex flex-md-row d-none">
                <Button v-if="openAll === false"
                        size="small"
                        :label="t('negativeInfos.buttons.openAll')"
                        icon="pi pi-angle-down"
                        icon-pos="right"
                        @click="onOpenAll" />                
                <Button v-else
                        :label="t('negativeInfos.buttons.closeAll')"
                        size="small"
                        icon="pi pi-angle-up"
                        icon-pos="right"
                        @click="onOpenAll" />
              </div>
            </template>

            <template #content>
              <EmptyState v-if="!negativeInfo.groups?.length"
                          page="negativeInfos" />
              <div v-else
                   class="negative-collapse-container">
                <InfoCollapse v-for="(group, index) in filteredGroups(negativeInfo.groups)"
                              :key="'group-' + index" 
                              page="negativeInfo" 
                              :title="group.title"
                              :icon="{key: group.icon.key, color: group.icon.color }">
                  <div v-for="(item, itemIndex) in filteredItems(group.items)"
                       :key="'item-' + itemIndex + index">
                    <InnerCollapse v-if="itemIndex < 5 || showMoreItems === 'group-' + index"
                                   :key="'group-' + index"
                                   :item="item"
                                   :disabled="!item.hasDetails && !item.hasRows"
                                   @open="loadDetails(index, itemIndex)">
                      <table>
                        <tr v-for="(row, rowIndex) in item.rows"
                            :key="'row-' + rowIndex + index">
                          <td style="width: 18rem">
                            <span style="padding-right: 2rem">
                              {{ row.key }}
                            </span>                            
                          </td>
                          <td>
                            <span v-if="!row.url">
                              {{ row.value }}
                            </span>
                            <span v-else>
                              <a :href="row.url"
                                 target="_blank">{{ row.value }}</a>
                            </span>
                          </td>
                        </tr>
                      </table>
                      <div>
                        <router-link v-if="item.hasDetails"
                                     :to="{name: 'report.negative-info.details', params: {id: $route.params.id, itemId: item.id}}"
                                     class="details">
                          {{ t("negativeInfos.details.details") }}
                        </router-link>
                      </div>
                    </InnerCollapse>
                  </div>
                  <div v-if="filteredItems(group.items).length > 5"
                       class="show-more-items__container">
                    <div class="show-more-items__button "
                         @click="toggleShowMoreItems('group-' + index)">
                      {{ showMoreItems === 'group-' + index ? t('hideEvents') : t('moreEvents') }}
                    </div>
                  </div>
                </InfoCollapse>
              </div>
            </template>
          </PageCard>
        </div>
        <div class="col-12 col-xxl-4">
          <DidYouKnow page-name="negative-info"
                      :sticky="true"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed, watch, onUnmounted } from "vue";
  import { http, t, init, route, user, toggle } from "@/logic";
  import { useEventBus } from "@vueuse/core";

  init();

  const props = defineProps(["type"]);

  const openAllBus = useEventBus<boolean>("connectionList/openAll");
  const showOnlyValidEventBus = useEventBus<boolean>("showOnlyValid");
  const colorNegative = ref("#E81313");
  const colorPositive = ref("#21C100");
  const colorInactive = ref("#90949B");
  const empty = ref(false);
  const loading = ref(false);
  const negativeInfo = ref({} as any);
  const openAll = ref(false);
  const showMoreItems = ref(null);

  async function load() {
    await http.fetchWithLoading(negativeInfo, loading, "/api/report/" + route.params.id + "/" + props.type + "-info");
  }

  function onOpenAll() {
    toggle(openAll);
    openAllBus.emit(openAll.value);
  }

  function filteredGroups(groups) {
    if (user.onlyValid) {
      return groups.filter((g) => g.actual);
    }

    return groups;
  }

  function filteredItems(items) {
    if (user.onlyValid) {
      return items.filter((i) => i.actual);
    }

    return items;
  }

  function toggleShowMoreItems(key) {
    if (showMoreItems.value === key) {
      showMoreItems.value = null;
    } else {
      showMoreItems.value = key;
    }
  }

  async function loadDetails(groupIndex, itemIndex) {
    const item = negativeInfo.value.groups[groupIndex].items[itemIndex];
    if (!item.rows && item.hasRows) {
      const data = await http.get("/api/report/" + route.params.id + "/" + props.type + "-info/" + 
        encodeURIComponent(item.id) + "/rows");
      item["rows"] = data;
    }
  }

  onMounted(async () => {
    load();
    if (!route.path.includes("print")) {
      showOnlyValidEventBus.emit(true);
    }
  });

  onUnmounted(async () => {
    if (!route.path.includes("print")) {
      showOnlyValidEventBus.emit(false);
    }
  });

  watch(
    () => route.path,
    load
  );
</script>

<style lang="scss" scoped>
.negative-collapse-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  & p {
    margin-bottom: 4rem;
    color: $color-font-primary;
    font-weight: 600;
    font-size: 1.4rem;
  }

  & img {
    width: 35rem;
  }
}

.negative-collapse-container {
  padding: 2rem;
}

.details {
  color: $orange;
  text-decoration: none;
}

.show-more-items__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.show-more-items__button {
  color: #3498db;
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration: none;
  cursor: pointer;
}

  @media screen and (min-width: 776px) {
    .negative-collapse__content__inner-content-container .negative-collapse__content__inner-content__title {
      width: 100px;
    }
  }
</style>
