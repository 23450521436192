<template>
  <Chart type="bar"
         :data="barChartData"
         :options="options" />
</template>

<script setup lang="ts">
  import { ref, onMounted, computed } from "vue";

  const props = defineProps(["row", "columns"]);

  const rowData = ref([]);
  const columnsData = ref([]);

  const options = ref({
    plugins: {
      legend: {
        display: false,
      }
    },
    maintainAspectRatio: false
  });

  const barChartData = computed(() => {
    return {
      labels: columnsData.value,
      datasets: [
        {
          label: "",
          backgroundColor: "#7979d4",
          data: rowData.value,
        },
      ],
    };
  });

  onMounted(() => {
    rowData.value = props.row.slice().map((c) => c.value);
    rowData.value.shift();
    columnsData.value = props.columns.map((c) => c.title);
    columnsData.value.shift();
  });
</script>
