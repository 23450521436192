<template>
  <div :class="['table-container table-responsive', { 'inner': innerTable }]">
    <table class="table table-striped">
      <thead>
        <th v-for="(column, index) in columns" 
            :key="column.name"
            :style="{'text-align': column.align, 'width': (column.width || 'auto')}" 
            :class="column.class">
          <slot :name="'heading-' + index">
            <div class="title"
                 :style="{'color': column.color}">
              {{ column.title }}
            </div>
            <div v-if="column.description"
                 class="sub-title"
                 :style="{'color': column.color}">
              {{ column.description }}
            </div>
          </slot>
        </th>
      </thead>
      <tbody>
        <tr />
        <tr />
        <template v-for="(row, index) in items"
                  :key="'row-' + index">
          <tr>
            <td v-for="(column, columnIndex) in columns"
                :key="'row-' + index + '-' + columnIndex"
                :class="tdClass(row, column, columnIndex)"
                :style="{
                  'text-align': column.rowAlign || column.align,
                  'color': column.color,
                  'whiteSpace': column.wrap || 'nowrap'
                }">
              <slot :name="columnIndex"
                    :row="row"
                    :columns="columns"
                    :index="index">
                <div v-if="row[columnIndex]">
                  <a v-if="row[columnIndex].url"
                     :href="row[columnIndex].url"
                     target="_blank">
                    {{ filtered(row[columnIndex], column).value }} {{ filtered(row[columnIndex], column).postfix }}
                  </a>
                  <span v-if="!row[columnIndex].url && !row[columnIndex].dotColor">
                    {{ filtered(row[columnIndex], column).value || 0 }} {{ filtered(row[columnIndex], column).postfix }}
                  </span>
                  <span v-if="row[columnIndex].dotColor"
                        style="height: 15px;width: 15px; border-radius: 50%;display: inline-block;"
                        :style="{backgroundColor: row[columnIndex].dotColor}" />
                  <a v-if="row[columnIndex].icon"
                     :href="row[columnIndex].icon.url"
                     target="_blank"
                     class="d-inline-block ml-2 text-decoration-none">
                    <i class="icon"
                       :class="row[columnIndex].icon.key"
                       :style="row[columnIndex].icon.style" />
                  </a>
                </div>
              </slot>
            </td>
          </tr>
          <tr v-if="openedRows.includes(index)">
            <td :colspan="columns.length">
              <slot name="rowDetails"
                    :row="row"
                    :columns="columns" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="showCount"
         class="grid-shower">
      <span><b>{{ numResults }} {{ t('monitoring.numRows') }}</b></span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { t, formatMoney } from "@/logic";

  const props = withDefaults(defineProps<{
    columns: Array<any>,
    items: Array<any>,		
    isBold?: boolean,
    innerTable?: boolean,
    openedRows?: any,
    mask?: any,
    meta?: any,
    numResults?: number,
    showCount?: boolean,
  }>(), {
    mask: null,
    meta: null,
    isBold: false,
    openedRows: [],
    numResults: 1,
    showCount: false
  });

  function filtered(td, th) {
    const value = formatMoney(td.value);
    let postfix = td.postfix || "";
    switch (th.mask) {
    case "money":
      if (props.mask) {
        return props.mask(td, props.meta);
      }

      postfix = props.meta.currency || td.postfix || "";
      return {
        value: value,
        postfix: td.postfix,
      };
    default:
      return {
        value: td.value || 0,
        postfix: postfix,
      };
    }
  }

  function tdClass(row, column, columnIndex) {
    let obj = {
      bold: row[columnIndex] && row[columnIndex].isBold,
    };

    if (column.class) {
      obj[column.class] = true;
    }

    return obj;
  }
</script>

<style lang="scss" scoped>
.grid-shower {
  float: right;
  margin: 12px 6px 10px 0;
}

.table-container {
  padding: 2rem;

  &.inner {
    padding: 0;
  }
}

  .table {
    margin: 0;
    border: 0;
    border-collapse: collapse;

    & thead {
      padding: 0;

      & th {
        padding: 0.5rem;
        border: 2px solid white;

        & .title {
          color: $color-font-primary;
          font-weight: 600;
          font-size: 1.4rem;
        }

        & .sub-title {
          color: $color-font-primary;
          font-weight: 500;
          font-size: 1rem;
        }
      }
    }

    & tbody {
      & tr {

        & td {
          padding: 0.5rem;
          font-weight: 400;
          font-size: 1.3rem;
          white-space: nowrap;
          vertical-align: middle;
          border: 2px solid white;

          &.no-border {
            border: none;
          }

          &.bold {
            font-weight: 600;
          }

          & .icon {
            margin-right: 0.5rem;
            color: $color-blue;
            font-size: 2rem;

              &:hover {
                cursor: pointer;
              }
        
          }
        }
      }
    }
  }
</style>
