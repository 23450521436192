<template>
  <InfoCard id="ratingDetails"
            :is-header-hidden="true">
    <template #content>
      <IndicatorTable :items="tableItems" />
    </template>
  </InfoCard>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { t } from "@/logic";

  const tableItems = ref([
    {
      ccPoints: [85, 100],
      rating: t("summary.indicator.level.minimalRisk"),
      description: t("summary.indicator.indicatorTable.minimalRisk"),
      creditAdvice: t("summary.indicator.creditAdvice.veryLowRiskLoan"),
    },
    {
      ccPoints: [67, 84],
      rating: t("summary.indicator.level.lowRisk"),
      description: t("summary.indicator.indicatorTable.lowRisk"),
      creditAdvice: t("summary.indicator.creditAdvice.lowRiskLoan"),
    },
    {
      ccPoints: [51, 66],
      rating: t("summary.indicator.level.mediumRisk"),
      description: t("summary.indicator.indicatorTable.mediumRisk"),
      creditAdvice: t("summary.indicator.creditAdvice.considered"),
    },
    {
      ccPoints: [34, 50],
      rating: t("summary.indicator.level.risky"),
      description: t("summary.indicator.indicatorTable.risky"),
      creditAdvice: t("summary.indicator.creditAdvice.onlyWithCover"),
    },
    {
      ccPoints: [16, 33],
      rating: t("summary.indicator.level.veryRisky"),
      description: t("summary.indicator.indicatorTable.veryRisky"),
      creditAdvice: t("summary.indicator.creditAdvice.notRecommended"),
    },
    {
      ccPoints: [0, 15],
      rating: t("summary.indicator.level.extremelyRisky"),
      description: t("summary.indicator.indicatorTable.extremelyRisky"),
      creditAdvice: t("summary.indicator.creditAdvice.notRecommended"),
    },
    {
      ccPoints: null,
      rating: t("summary.indicator.level.other"),
      description: t("summary.indicator.indicatorTable.other"),
      creditAdvice: null,
    },
  ]);
</script>
