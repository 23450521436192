<template>
  <div>
    <header>
      <TheNavigation />
      <TheSubNavigation />
    </header>
    <aside>
      <TheSidebar />
    </aside>
    <main :class="{'no-sidebar position-relative': $route.name === 'report.connections' }">
      <router-view />
    </main>
  </div>
</template>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-navigation;
  width: 100vw;
}

main {
  margin-top: $height-nav + $height-company-info;
  margin-left: $width-sidebar-desktop;
  padding: 0 $padding-main-desktop $padding-main-desktop;

  @include media-breakpoint-up(lg) {
    &.no-sidebar {
      margin-left: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-top: $height-nav + $height-company-info + 3.5rem;
    margin-left: $width-sidebar-mobile;
    padding: 0 $padding-main-mobile $padding-main-mobile;
  }

  @include media-breakpoint-only(xs) {
    padding: 0 1.5rem $padding-main-mobile;
  }
}
</style>
