<template>
  <div class="companyDetails">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.companyName') }}</label>
          <InputText v-model="data.companyName"
                     :placeholder="t('marketing.companyDetails.inputs.companyName')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.taxNumber') }}</label>
          <InputText v-model="data.taxNumber"
                     :placeholder="t('marketing.companyDetails.inputs.taxNumber')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.companyNumber') }}</label>
          <InputText v-model="data.companyNumber"
                     :placeholder="t('marketing.companyDetails.inputs.companyNumber')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.bankAccountNumber') }}</label>
          <InputText v-model="data.bankAccountNumber"
                     :placeholder="t('marketing.companyDetails.inputs.bankAccountNumber')" />
        </div>
        <div class="co-input-marketing multiple">
          <label for="">{{ t('marketing.companyDetails.inputs.address') }}</label>
          <div class="row m-initial w-100">
            <div class="col-12 col-md-2 p-initial">
              <InputText v-model="data.zip"
                         :placeholder="t('marketing.companyDetails.inputs.zip')" />
            </div>
            <div class="col-12 col-md-4 p-initial">
              <InputText v-model="data.city"
                         :placeholder="t('marketing.companyDetails.inputs.city')" />
            </div>
            <div class="col-12 col-md-6 p-initial">
              <InputText v-model="data.street"
                         :placeholder="t('marketing.companyDetails.inputs.street')" />
            </div>
          </div>
        </div>
        <div class="co-input-marketing multiple">
          <label for="">{{ t('marketing.companyDetails.inputs.foundation') }}</label>
          <div class="row m-initial w-100">
            <div class="col-6 p-initial min-max">
              <InputText v-model="data.foundation.min"
                         :placeholder="t('marketing.companyDetails.inputs.min')" />
            </div>
            <div class="col-6 p-initial min-max">
              <InputText v-model="data.foundation.max"
                         :placeholder="t('marketing.companyDetails.inputs.max')" />
            </div>
          </div>
        </div>
        <div class="co-input-marketing multiple">
          <label for="">{{ t('marketing.companyDetails.inputs.employees') }}</label>
          <div class="row m-initial w-100">
            <div class="col-6 p-initial min-max">
              <InputText v-model="data.employees.min"
                         :placeholder="t('marketing.companyDetails.inputs.min')" />
            </div>
            <div class="col-6 p-initial min-max">
              <InputText v-model="data.employees.max"
                         :placeholder="t('marketing.companyDetails.inputs.max')" />
            </div>
          </div>
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.memberTaxNumber') }}</label>
          <InputText v-model="data.memberTaxNumber"
                     :placeholder="t('marketing.companyDetails.inputs.memberTaxNumber')" />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.type') }}</label>
          <MultiSelect v-model="data.type" 
                       :options="prefill.types"  
                       option-label="value" 
                       :placeholder="t('multiselect.placeholder')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.status') }}</label>
          <MultiSelect v-model="data.status" 
                       :options="prefill.statuses" 
                       option-label="value"
                       :placeholder="t('multiselect.placeholder')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.county') }}</label>
          <MultiSelect v-model="data.county" 
                       :options="prefill.counties" 
                       option-label="value"
                       :placeholder="t('multiselect.placeholder')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.region') }}</label>
          <MultiSelect v-model="data.region" 
                       :options="prefill.regions" 
                       option-label="value" 
                       :placeholder="t('multiselect.placeholder')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.personName') }}</label>
          <InputText v-model="data.personName"
                     :placeholder="t('marketing.companyDetails.inputs.personName')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.motherName') }}</label>
          <InputText v-model="data.motherName"
                     :placeholder="t('marketing.companyDetails.inputs.motherName')" />
        </div>
        <div class="co-input-marketing multiple">
          <label for="">{{ t('marketing.companyDetails.inputs.personAddress') }}</label>
          <div class="row m-initial w-100">
            <div class="col-12 col-md-2 p-initial">
              <InputText v-model="data.personZip"
                         :placeholder="t('marketing.companyDetails.inputs.zip')" />
            </div>
            <div class="col-12 col-md-4 p-initial">
              <InputText v-model="data.personCity"
                         :placeholder="t('marketing.companyDetails.inputs.city')" />
            </div>
            <div class="col-12 col-md-6 p-initial">
              <InputText v-model="data.personStreet"
                         :placeholder="t('marketing.companyDetails.inputs.street')" />
            </div>
          </div>
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.personTaxID') }}</label>
          <InputText v-model="data.personTaxId"
                     :placeholder="t('marketing.companyDetails.inputs.personTaxID')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, watch, onMounted } from "vue";
  import { http, t } from "@/logic";

  const props = defineProps(["prefill"]);

  const emit = defineEmits<{
    (e: "input", data: any): void;
  }>();

  defineExpose({ setDefaults });

  const defaultValues = {
    companyName: "",
    taxNumber: "",
    companyNumber: "",
    bankAccountNumber: "",
    address: "",
    zip: "",
    city: "",
    street: "",
    employees: {
      min: "",
      max: "",
    },
    foundation: {
      min: "",
      max: "",
    },
    memberTaxNumber: "",
    type: "",
    status: "",
    county: [],
    region: [],
    personName: "",
    motherName: "",
    personZip: "",
    personCity: "",
    personStreet: "",
    personTaxId: "",
  };

  const data = ref(defaultValues as any);

  function setDefaults(newValues = null) {
    data.value = Object.assign(data.value, newValues || defaultValues);   
    emit("input", data.value);
  }

  onMounted(() => {
    setDefaults();
  });

  watch(() => data, () => emit("input", data.value), { deep: true });
</script>

<style scoped lang="scss">
.m-initial {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
</style>
