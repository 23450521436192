<template>
  <SearchPage icon="international"
              :title="t('international.title')">
    <template #content>
      <div class="page-international">
        <div class="container-fluid p-initial">
          <div class="row">
            <div class="col-12">
              <Card>
                <template #header>
                  <i class="pi pi-globe" />
                  <span class="title">{{ t('international.onlineTitle') }}</span>
                </template>
                <template #content>
                  <p class="subtitle">
                    {{ $t('international.chooseCountry') }}
                  </p>
                  <div class="d-flex align-items-center flex-wrap">
                    <div v-for="country in data.online"
                         :key="country.key"
                         class="co-radio-country mb-3">
                      <RadioButton v-model="onlineCountry"
                                   :input-id="country.key"
                                   :value="country.key" />
                      <label :for="country.key"
                             class="ml-1">
                        <span :class="'fi fi-' + country.key"></span>
                        {{ country.name }}
                      </label>
                    </div>
                  </div>
                  <p class="subtitle">
                    {{ $t('international.inputs.companyNameLabel') }}
                  </p>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="co-input-marketing">
                        <label for="">{{ $t('international.inputs.companyName') }}</label>
                        <InputText v-model="order.companyName" @keyup.enter="onSearch" />
                      </div>
                      <div class="co-input-marketing">
                        <label for="">{{ $t('international.inputs.address') }}</label>
                        <InputText v-model="order.zip" @keyup.enter="onSearch"
                                   :placeholder="t('marketing.companyDetails.inputs.zip')" />
                        <InputText v-model="order.city" @keyup.enter="onSearch"
                                   class="ml-1"
                                   :placeholder="t('marketing.companyDetails.inputs.city')" />
                        <InputText v-model="order.street" @keyup.enter="onSearch"
                                   class="ml-1"
                                   :placeholder="t('marketing.companyDetails.inputs.street')" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="co-input-marketing">
                        <label for="">{{ $t('international.inputs.companyNumber') }}</label>
                        <InputText v-model="order.companyNumber" @keyup.enter="onSearch" />
                      </div>
                      <div class="co-input-marketing">
                        <label for="">{{ $t('international.inputs.taxNumber') }}</label>
                        <InputText v-model="order.taxNumber" @keyup.enter="onSearch" />
                      </div>
                    </div>
                  </div>
                  <div class="search-field">
                    <!--<p>{{ $t('marketing.search') }}</p>-->
                    <button class="button button--primary mt-5"
                            @click="onSearch">
                      {{ $t('marketing.buttons.search') }}
                    </button>
                  </div>
                </template>
              </Card>
              <Card>
                <template #header>
                  <i class="pi pi-copy" />
                  <span class="title">{{ t('international.offlineTitle') }}</span>
                </template>
                <template #content>
                  <div>
                    <div class="info-box">
                      {{ t('international.infoBox') }}
                    </div>
                    <p class="subtitle">
                      {{ t('international.companyDetails') }}
                    </p>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="co-input-marketing">
                          <label for="">{{ t('international.inputs.country') }}</label>
                          <Dropdown v-model="offlineCountry"
                                    :options="data.offline"
                                    option-label="name"
                                    :placeholder="t('multiselect.placeholder')"
                                    class="w-100"
                                    @change="offlineCountrySelected" />
                        </div>
                        <div class="co-input-marketing">
                          <label for="">{{ t('international.inputs.companyName') }}</label>
                          <InputText v-model="order.companyName" />
                        </div>
                        <div class="co-input-marketing">
                          <label for="">{{ $t('international.inputs.address') }}</label>
                          <InputText v-model="order.zip"
                                     :placeholder="t('marketing.companyDetails.inputs.zip')" />
                          <InputText v-model="order.city" 
                                     class="ml-1"
                                     :placeholder="t('marketing.companyDetails.inputs.city')" />
                          <InputText v-model="order.street"
                                     class="ml-1"
                                     :placeholder="t('marketing.companyDetails.inputs.street')" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="co-input-marketing">
                          <label for="">{{ t('international.inputs.taxNumber') }}</label>
                          <InputText v-model="order.taxNumber"/>
                        </div>
                        <div class="co-input-marketing">
                          <label for="">{{ t('international.inputs.companyNumber') }}</label>
                          <InputText v-model="order.companyNumber" />
                        </div>
                        <!--<div class="co-input-marketing">
                          <label for="inAllActivity">
                            Express
                          </label>
                          <Checkbox id="express"
                                    v-model="order.express"
                                    :binary="true" />
                        </div>-->
                      </div>
                    </div>
                    <div class="search-field mt-5">
                      <div v-if="offlinePrice"
                           class="text-center mb-5">
                        {{ t('international.download.purchase.purchasePriceText') }}
                        <b>{{ offlinePrice }} EUR</b>
                      </div>
                      <button class="button button--primary"
                              v-if="!offlineOrderSent"
                              @click="onOrder">
                        {{ t('international.download.purchase.purchaseButtonText') }}
                      </button>
                    </div>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SearchPage>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted } from "vue";
  import { http, t, router, route, init, toast } from "@/logic";

  init();
  
  const onlineCountry = ref("at");
  const offlineCountry = ref(null);
  const offlineOrderSent = ref(false);
  const offlinePrice = ref(null);
  const data = ref({
    online: [],
    offline: [],
  });
  const order = reactive({
    companyName: "",
    zip: "",
    city: "",
    street: "",
    taxNumber: "",
    companyNumber: "",
    comment: "",
    express: false
  });

  function offlineCountrySelected(event) {
    offlinePrice.value = event.value.price;
  }

  function onSearch() {
    router.push({
      name: "international.results",
      query: {
        country: onlineCountry.value,
        name: order.companyName,
        zip: order.zip,
        city: order.city,
        street: order.street,
        taxNumber: order.taxNumber,
        companyNumber: order.companyNumber,
      },
    });
  }

  async function onOrder() {
    offlineOrderSent.value = true;

    const response = await http.post("/api/international-new/offline", {
      country: offlineCountry.value.key,
      name: order.companyName,
      zip: order.zip,
      city: order.city,
      street: order.street,
      taxNumber: order.taxNumber,
      companyNumber: order.companyNumber,
    });

    toast(response.message);
  }

  onMounted(async () => {
    await http.fetch(data, "/api/international-new/country-list");
  });
</script>

<style lang="scss" scoped>
  .co-radio-country {
    width: 200px;

    label {
      cursor: pointer;
      margin-left: 10px;      
    }
  }

  .co-input-marketing {
    label {
      min-width: 12rem !important;
    }
  }

  .page-international {
    max-width: 120rem;
    margin: 0 auto;

    & .marketing-card {
      margin-top: 1rem;
    }

    & .subtitle {
      margin: 2rem 0 1rem;
      color: $color-font-quaternary;
      font-weight: 500;
      font-size: 1.2rem;
      text-transform: uppercase;
    }

    & .search-field {
      & p {
        margin: 4rem 0 2rem;
        font-weight: 500;
        font-size: 1.4rem;
        text-align: center;
      }

      & button {
        margin: 0 auto;
      }
    }

    & .info-box {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      color: $color-font-primary;
      font-weight: 500;
      font-size: 1.4rem;
      background-color: $color-international-info-bg;

      &::before {
        margin-right: 0.5rem;
        color: $color-primary;
        font-size: 2.4rem;
        font-family: $font-icon;
        content: "\e932";
      }
    }
  }

.error {
  margin-top: -11px;
  margin-left: 19rem;
  padding: 0;
  color: #e04242;
  font-size: 1.2rem;
  list-style: none;

  @include media-breakpoint-down(lg) {
    margin-left: 0;
    padding: 0;
  }
}
</style>
