<template>
  <div v-if="!loading"
       class="page-sector">
    <div class="container-fluid p-initial">
      <div class="row print-new-page">
        <div class="col-12">
          <PageCard v-if="sector.charts"
                    :title="t('sector.title')"
                    icon="benefit-stat">
            <template #content>
              <div class="sector-select">
                <div class="title">
                  {{ t('sector.industry') }}
                </div>
                <div>
                  <Dropdown v-model="selectedSector"
                            :options="sector.sectors"
                            option-value="id"
                            option-label="value" 
                            :placeholder="t('multiselect.placeholder')"
                            filter
                            @change="load" />
                </div>
              </div>
              <Table :columns="sector.table.columns"
                     :items="sector.table.items"
                     :meta="sector.table.meta" />

              <div class="collapses">
                <Collapse :opened="true"
                          :title="t('sector.collapses.liquidity.title')"
                          :chart-collapse="true"
                          page="sector">
                  <div class="row print-row">
                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.liquidity.rate')"
                                 :info-text="t('sector.collapses.liquidity.rateInfoText')"
                                 :data="sector.charts.liquidityRate.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.liquidity.swiftRate')"
                                 :info-text="t('sector.collapses.liquidity.swiftRateInfoText')"
                                 :data="sector.charts.liquidityQuickRate.data"
                                 legend="true" />
                    </div>
                  </div>
                </Collapse>

                <Collapse :opened="true" 
                          :title="t('sector.collapses.capitalStructure.title')" 
                          :chart-collapse="true" 
                          page="sector">
                  <div class="row print-row">
                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.capitalStructure.adequacy')"
                                 :info-text="t('sector.collapses.capitalStructure.adequacyInfoText')"
                                 :data="sector.charts.capitalSupply.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.capitalStructure.debtRate')"
                                 :info-text="t('sector.collapses.capitalStructure.debtRateInfoText')"
                                 :data="sector.charts.debtRate.data"
                                 legend="true" />
                    </div>
                  </div>
                </Collapse>

                <Collapse :opened="true" 
                          :title="t('sector.collapses.activity.title')" 
                          :chart-collapse="true" 
                          page="sector">
                  <div class="row print-row">
                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.activity.key')"
                                 :info-text="t('sector.collapses.activity.keyInfoText')"
                                 :data="sector.charts.activityProfitMargin.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.activity.margin')"
                                 :info-text="t('sector.collapses.activity.marginInfoText')"
                                 :data="sector.charts.grossProfitMargin.data"
                                 legend="true" />
                    </div>
                  </div>
                </Collapse>

                <Collapse :opened="true" 
                          :title="t('sector.collapses.profitability.title')" 
                          :chart-collapse="true"
                          page="sector">
                  <div class="row print-row">
                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.profitability.ros')"
                                 :info-text="t('sector.collapses.profitability.rosInfoText')"
                                 :data="sector.charts.ros.data"
                                 legend="true" />
                    </div>
                  </div>
                </Collapse>

                <Collapse :opened="true" 
                          :title="t('sector.collapses.balanceStructure.title')" 
                          :chart-collapse="true" 
                          page="sector">
                  <div class="row print-row">
                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.balanceStructure.equity')"
                                 :info-text="t('sector.collapses.balanceStructure.equityInfoText')"
                                 :data="sector.charts.equity.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.balanceStructure.provisions')"
                                 :info-text="t('sector.collapses.balanceStructure.provisionsInfoText')"
                                 :data="sector.charts.provisions.data"
                                 legend="true" />
                    </div>
                  </div>
                  <div class="row print-row">
                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.balanceStructure.obligations')"
                                 :info-text="t('sector.collapses.balanceStructure.obligationsInfoText')"
                                 :data="sector.charts.obligations.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.balanceStructure.passiveDelimitations')"
                                 :info-text="t('sector.collapses.balanceStructure.passiveDelimitationsInfoText')"
                                 :data="sector.charts.passiveDelimitations.data"
                                 legend="true" />
                    </div>
                  </div>
                  <div class="row print-row">
                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.balanceStructure.fixedAssets')"
                                 :info-text="t('sector.collapses.balanceStructure.fixedAssetsInfoText')"
                                 :data="sector.charts.fixedAssets.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.balanceStructure.currentAssets')"
                                 :info-text="t('sector.collapses.balanceStructure.currentAssetsInfoText')"
                                 :data="sector.charts.currentAssets.data"
                                 legend="true" />
                    </div>
                  </div>
                  <div class="row print-row">
                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.balanceStructure.activeDelimitations')"
                                 :info-text="t('sector.collapses.balanceStructure.activeDelimitationsInfoText')"
                                 :data="sector.charts.activeDelimitations.data"
                                 legend="true" />
                    </div>
                  </div>
                </Collapse>

                <Collapse :opened="true" 
                          :title="t('sector.collapses.resultStructure.title')" 
                          :chart-collapse="true"
                          page="sector">
                  <div class="row print-row">
                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.resultStructure.operatingActivity')"
                                 :info-text="t('sector.collapses.resultStructure.operatingActivityInfoText')"
                                 :data="sector.charts.operatingActivity.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6 print-percent-50">
                      <ChartCard :title="t('sector.collapses.resultStructure.beforeTax')"
                                 :info-text="t('sector.collapses.resultStructure.beforeTaxInfoText')"
                                 :data="sector.charts.beforeTax.data"
                                 legend="true" />
                    </div>
                  </div>
                </Collapse>
              </div>
            </template>
          </PageCard>
        </div>
        <div class="col-12">
          <DidYouKnow page-name="sector"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, watch, computed } from "vue";
  import { http, t, toggle, route, init } from "@/logic";
  import { useEventBus } from "@vueuse/core";

  init();

  const bus = useEventBus<boolean>("sector/collapseToggle");
  const key = ref(0);
  const loading = ref(false);
  const openAll = ref(false);
  const selectedSector = ref(null);
  const sector = ref({
    sectors: [],
    selectedSector: null,
    table: {
      meta: {},
      columns: [],
      items: [],
    },
  } as any);

  function onOpenAll() {
    toggle(openAll);
    bus.emit(openAll.value);
  }

  async function load() {
    let query = "";

    if (selectedSector.value) {
      query += "sector=" + selectedSector.value;
    }

    await http.fetch(sector, "/api/report/" + route.params.id + "/sector?" + query);
    key.value++;
  }

  onMounted(async () => {
    await load();
    selectedSector.value = sector.value.selectedSector.id;
  });
</script>


<style lang="scss" scoped>
.p-dropdown {
	width: 100%;
	border-color: $color-primary;
}

.table-container {
	padding-top: 0px;
}

.sector-select {
  margin: 2rem 2rem 0;

  & .title {
    /*margin: 0 0 1rem 1rem;*/
    font-weight: 500;
    font-size: 1.4rem;
  }

  & .multiselect {
    max-width: 200rem;
  }
}
.collapses {
  margin: 2rem 2rem;
}

.card {
  padding: 2rem;
  margin: 2.5rem 0.3rem 0rem 0.3rem;
}

.co-collapse {
  border-top: none !important;
}
</style>
