import { createI18n } from "vue-i18n";
import hu from "./hu.json";
import en from "./en.json";
import de from "./de.json";
import { User } from "../logic/User";

export const setI18n = (user: User) => {
  return createI18n({
    legacy: false,
    locale: user?.locale || "hu",
    fallbackLocale: "hu",
    globalInjection: true,
    warnHtmlMessage: false,
    messages: { hu, en, de },
  });
};
