export class Comparison {
  get companies() {
    return JSON.parse(localStorage.getItem("comparison")) || [];
  }

  set companies(value) {
    localStorage.setItem("comparison", JSON.stringify(value));
  }

  emptyAll() {
    this.companies = [];
  }

  addCompany(company) {
    if (this.companies.find((c) => c.id === company.id)) return;

    const newList = this.companies;
    newList.push(company);
    this.companies = newList;
  }

  deleteCompany(index) {
    const newList = this.companies;
    newList.splice(index, 1);
    this.companies = newList;
  }

  setCompanies(companies) {
    this.companies = [];
    companies.forEach((company) => {
      this.companies.push({ id: company, name: null });
    });
  }

  getIds() {
    return this.companies.map((c) => c.id).join(",");
  }
}