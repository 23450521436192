export default {
  columns: [
    {
      title: "",
      description: "",
      align: "left",
      width: "50%",
    },
    {
      title: "monitoring.levels.basic",
      description: "",
      align: "center",
      width: "auto",
    },
    {
      title: "monitoring.levels.mid",
      description: "",
      align: "center",
      width: "auto",
    },
    {
      title: "monitoring.levels.high",
      description: "",
      align: "center",
      width: "auto",
    },
  ],
  items: [
    [
      { value: "monitoring.events.cegnev", isBold: true, isTitle: true },
      { dotColor: "#f3792b" },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.szekhely",
        isBold: true,
        isTitle: true,
      },
      { dotColor: "#f3792b" },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      { value: "monitoring.events.toke", isBold: true, isTitle: true },
      { dotColor: "#f3792b" },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.cegjegyzesre_jogosult",
        isBold: true,
        isTitle: true,
      },
      { dotColor: "#f3792b" },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.tulajdonos_tag",
        isBold: true,
        isTitle: true,
      },
      { dotColor: "#f3792b" },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.csodeljaras",
        isBold: true,
        isTitle: true,
      },
      { dotColor: "#f3792b" },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.felszamolasi_eljaras",
        isBold: true,
        isTitle: true,
      },
      { dotColor: "#f3792b" },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.vegelszamolasi_eljaras",
        isBold: true,
        isTitle: true,
      },
      { dotColor: "#f3792b" },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],

    [
      {
        value: "monitoring.events.tevekenysegi_kor",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.adoszam",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.penzforgalmi_jelzoszam",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.torlesi_eljaras",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.kenyszertorlesi_eljaras",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.adoszam_felfuggesztese",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.adoszam_torles",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.vagyonrendezesi_eljaras",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.adossagrendezesi_eljaras",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.adovegrehajtas",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.nav_afa_bevallast_elmulasztok",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.nav_koztartozas_mentes_adozok",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { dotColor: "#5d92d2" },
      { dotColor: "rgb(33, 193, 0)" },
    ],

    [
      {
        value: "monitoring.events.konyvvizsgalo",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.felugyelo_bizottsagi_tagok",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.ceg_mukodesenek_befejezesenek_datuma",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.ceg_mukodesenek_felfuggesztese",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.megszunes_elhatarozasanak_kelte",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.cegjegyzes_modja",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.szankcios_jelleggel_torolt_adoszam_hirdetmeny",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.adotartozas_100_millio_felett",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.adohiany_100_millio_felett",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.fekete_foglalkoztatok",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.gvh_elmarasztalas",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.mukodestol_eltiltas",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.vagyoni_reszesedes_letiltasa",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.buntetojogi_intezkedes",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.nfhh_elmarasztalas",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.ommf_elmarasztalas",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
    [
      {
        value: "monitoring.events.fogyasztovedelmi_pozitiv_minosites",
        isBold: true,
        isTitle: true,
      },
      { value: " " },
      { value: " " },
      { dotColor: "rgb(33, 193, 0)" },
    ],
  ],
};