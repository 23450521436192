<template>
  <SearchPage :icon="(props.type == 'marketing' ? 'marketing-search' : 'search-detailed')"
              :title="t((props.type == 'marketing' ? 'marketing' : 'detailedSearch') + '.title')">
    <template #content>
      <div class="page-main-marketing"
           @keyup.enter="onSearch">
        <div class="container-fluid p-initial">
          <div class="row">
            <div class="col-12">
              <Card>
                <template #header>
                  <i class="icon company-report pi" />
                  <span class="title">{{ t('marketing.cards.companyDetails.title') }}</span>
                </template>
                <template #content>
                  <SearchDetails ref="companyDetailsBox"
                                 :prefill="prefill"
                                 @input="onCompanyDetailsChange" />
                </template>
              </Card>

              <Card>
                <template #header>
                  <i class="icon financial-reports pi" />
                  <span class="title">{{ t('marketing.cards.financialDetails.title') }}</span>
                </template>
                <template #content>
                  <FinancialSearch ref="financialDetailsBox"
                                   :prefill="prefill"
                                   @input="onFinancialDetailsChange" />
                </template>
              </Card>

              <Card v-if="type == 'marketing'">
                <template #header>
                  <i class="icon activity-scope pi" />
                  <span class="title">{{ t('marketing.cards.activityScope.title') }}</span>
                </template>
                <template #content>
                  <div class="text-end d-none d-md-block"
                       style="z-index: 900">
                    <span style="line-height: 2.5rem">
                      <Checkbox id="inAllActivity"
                                v-model="params.inAllActivity"
                                :binary="true" />
                      <label for="inAllActivity">
                        {{ t('activities.inAllActivity') }}
                      </label>
                    </span>
                    <span class="mx-4"
                          style="line-height: 2.5rem">
                      <Checkbox id="inAllActivity"
                                v-model="params.showOnlySelected"
                                :binary="true" />
                      <label for="inAllActivity">
                        {{ t('activities.showSelected') }}
                      </label>
                    </span>
                    <Button :label="t('activities.deleteSelected')"
                            class="mx-3"
                            @click="clearSelected" />
                  </div>
                  <Tree v-model:selectionKeys="selectedActivities"
                        :value="activities"
                        selection-mode="checkbox"
                        :filter="true"
                        :filter-placeholder="t('activities.searchActivities')"
                        scroll-height="300px" />
                </template>
              </Card>
              <div class="search-field my-5">
                <div class="d-flex justify-content-end align-items-center">
                  <div class="co-checkbox-primary-marketing"
                       style="margin-right: 1rem">
                    <Checkbox id="onlyValid"
                              v-model="params.onlyValid"
                              :binary="true" />
                    <label for="onlyValid">
                      {{ t('marketing.checkboxes.onlyValid') }}
                    </label>
                  </div>
                  <Button :label="t('marketing.buttons.delete')"
                          class="mx-3 d-none d-md-block button-red"
                          @click="onClear" />
                  <Button :label="t('marketing.buttons.search')"
                          @click="onSearch" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SearchPage>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, computed, nextTick } from "vue";
  import { http, t, toggle, route, router, init } from "@/logic";

  init();

  const props = defineProps<{
    type: string
  }>();

  const prefill = ref({} as any);
  const selectedActivities = ref({});
  const params = reactive({
    companyDetails: {},
    financialDetails: {},
    activities: [],
    onlyValid: false,
    inAllActivity: false,
    showOnlySelected: false,
  } as any);
  const url = computed(() => {
    return props.type == "marketing" ? "/api/marketing/" : "/api/advanced-search/";
  });
  const activities = computed(() => {
    const keys = Object.keys(selectedActivities.value);
    const selected = keys 
      ? keys.filter((x) => x.length == 4)
      : [];

    var filtered = prefill.value.activities2;

    if (filtered && params.showOnlySelected) {
      filtered = JSON.parse(JSON.stringify(filtered));

      filtered.forEach(first => {
        first.children.forEach(second => {
          second.children = second.children.filter((x) => selected.indexOf(x.key) > -1);
        });

        first.children = first.children.filter((x) => x.children.length > 0);
      });

      filtered = filtered.filter((x) => x.children.length > 0);
    }
    

    return filtered;
  });

  const companyDetailsBox = ref(null);
  const financialDetailsBox = ref(null);

  function onCompanyDetailsChange(data) {
    params.companyDetails = data;
  }

  function onFinancialDetailsChange(data) {
    params.financialDetails = data;
  }

  async function onSearch() {    
    params.activities = Object.keys(selectedActivities.value).filter((x) => x.length == 4);    
    const data = await http.post(url.value, {
      version: 1,
      query: params,
    });
    data.value = data;
    await router.push({
      name: props.type + ".search.results",
      params: {
        id: data.id,
      },
    });
  }

  function onClear() {
    companyDetailsBox.value.clear();
    financialDetailsBox.value.clear();
    clearSelected();
  }

  function clearSelected() {
    selectedActivities.value = {};
  }

  onMounted(async () => {
    let query = "";

    if (route.params.id) {
      query = route.params.id as string;      
    }

    const data = await http.get(url.value + query);

    if (route.params.id) {
      companyDetailsBox.value.setDefaults(data.params.companyDetails);
      financialDetailsBox.value.setDefaults(data.params.financialDetails);

      if (data.params.activities) {
        selectedActivities.value = {};
        data.params.activities.forEach(x => {
          selectedActivities.value[x] = { checked: true, partialChecked: false };
        });
      }      
    }
		
    prefill.value = data.prefill;
    params.onlyValid = props.type == "marketing";
  });
</script>

<style lang="scss" scoped>
.page-main-marketing {
  max-width: 120rem;
  margin: 0 auto;

  & .search-field {
    & p {
      margin: 4rem 0 2rem;
      font-weight: 500;
      font-size: 1.4rem;
      text-align: center;
    }
  }
}

.tab-header {
  cursor: pointer;
}

.not-useful {
  display: inline-block;
  margin-right: 0.5rem;
  margin-left: 1rem;
  color: #f3792b;
  font-size: 2rem;
}

  .p-tree {
    margin-top: -40px;
  }
</style>
