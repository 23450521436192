<template>
  <div class="print-view">
    <div v-if="!isEmpty"
         class=""
         style="max-width:1100px; margin: auto;">
      <div class="print-co-header no-color">
        <h1>{{ t('comparison.title') }}</h1>
        <p>
          {{ date }} napján hatályos
        </p>
      </div>
      <Comparison />  
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed } from "vue";
  import { http, t, init, route, chartResize } from "@/logic";
  import Comparison from "../Comparison.vue";

  init();

  const loading = ref(false);
  const comparison = ref(null);
  const date = ref("");
  const isEmpty = computed(() => {
    return !route.query.companies;
  });

  async function load() {
    if (!isEmpty.value) {
      await http.fetch(comparison, `/api/comparison/?companies=${route.query.companies}`);
    }
  }

  onMounted(async () => {
    date.value = new Date().toLocaleString("hu");
    await load();   
  });

  window.addEventListener('beforeprint', () => {
    chartResize();
  });
</script>

<style scoped lang="scss">
.print-view:deep(.no-print) {
  display: none;
}

:deep() {
  .tab-header {
    display: none !important;
  }
  & .widget-controller,
  & .alternativeCard,
  & .map-marker,
  & .copy,
  & .inner-wrapper-sticky,
  & .button,
  & .sort-up,
  & .sort-down,
  & table a {
    color: $color-font-primary !important;
    text-decoration: none !important;
  }

  & table td {
    padding: 0.25rem 0.5rem !important;
  }
}
.company-deleted {
  font-size: 3rem;
}

:deep() {
  & .print-row {
    display: block !important;
  }

  & .print-percent-50 {
    display: block !important;
    float: left !important;
    page-break-inside: avoid !important;
  }

  & .print-new-page {
    display: block !important;
    page-break-before: always !important;
  }
}

.print-co-header {
  justify-content: space-between;
  padding: 2rem;

  & img {
    height: 6rem;
  }

  & p {
    margin-top: 1rem;
    font-style: italic;
  }
}

.no-color {
  filter: grayscale(100);
}
</style>
