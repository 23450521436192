<template>
  <div class="card co-card"
       :class="{'height-100': contentOpened}"
       :style="{ 'border-radius': borderRadius }">
    <div v-if="$slots.header"
         class="card__header">
      <slot name="header" />
    </div>
    <div v-if="!isHeaderHidden && !$slots.header"
         :class="{ 'opened': contentOpened }"
         class="card__header">
      <div class="card__header__title">
        <i class="pi icon"
           :class="[iconName]"
           :style="{color: iconColor}"
           style="font-size: 2.5rem; margin: 0.5rem" />
        <h3 class="card__header__title__text">
          {{ title }}
        </h3>
      </div>
      <div v-if="cardType === 'info'"
           class="card__header__info"
           @click="toggleInfo" />
      <div v-else-if="cardType === 'graph'"
           class="card__header__graph">
        <div v-if="infoText"
             :class="graphView !== 'info' ? 'card__header__graph__info' : 'card__header__graph__view-changer--open'"
             @click="toggleInfo" />
        <div :class="graphView !== 'table' ? 'card__header__graph__view-changer' : 'card__header__graph__view-changer--open'"
             @click="toggleTable" />
      </div>
    </div>
    <div :class="{ 'show': contentOpened }"
         class="card__content">
      <p v-if="graphView === 'info'"
         class="card__content__info-box"
         v-html="infoText" />
      <slot v-if="graphView === 'chart'"
            name="content" />
      <slot v-if="graphView === 'table'"
            name="graph-table" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";

  const props = defineProps<{
    iconName?: string;
    iconColor?: string;
    borderRadius?: string;
    title?: string;
    cardType?: string;
    infoText?: string;
    isHeaderHidden?: boolean;
  }>();
  const contentOpened = ref(true);
  const graphView = ref("chart");

  function toggleContent() {
    contentOpened.value = !contentOpened.value;
  }

  function toggleInfo() {
    if (graphView.value === "info") {
      graphView.value = "chart";
    } else {
      graphView.value = "info";
    }
  }

  function toggleTable() {
    if (graphView.value === "table") {
      graphView.value = "chart";
    } else {
      graphView.value = "table";
    }
  }
</script>