<template>
  <div class="p-3 alert-wrapper"
       :style="{backgroundColor: bg, color: color}"
       @click="toggle">
    <div class="d-flex align-items-center justify-content-between">
      <i :class="['icon', icon]" />
      <div class="title">
        {{ title }}
      </div>
      <i class="icon"
         :class="{'sort-down': !show, 'sort-up': show}" />
    </div>
    <div v-if="show"
         class="description mt-3">
      {{ description }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";

  const props = defineProps(["icon", "title", "bg", "color", "description"]);

  const show = ref(false);

  function toggle() {
    show.value = !show.value;
  }
</script>

<style lang="scss" scoped>
.alert-wrapper {
  margin: 1rem 2rem 0 2rem;

  & .title {
    font-weight: 600;
    font-size: 1.4rem;
    margin-right: auto;
    margin-left: 2rem;
  }

  & .icon {
    font-weight: 600;
    font-size: 2rem;

    &.sort-down {
      font-size: 2rem;
    }
  }

  .description {
    color: $color-font-primary;
    font-weight: 600;
    font-size: 1.2rem;
  }
}
</style>
