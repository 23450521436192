<template>
  <div class="monitoring-changes">
    <InfoCard icon="changes" 
              icon-color="orange" 
              :title="t('home.monitoringChanges.title')">
      <template #content>
        <InteractiveTable :columns="columns"
                          :items="data.results"
                          :meta="meta" />
        <Paginator :rows="perPage"
                   :total-records="meta.count"
                   @page="pageChange" />
      </template>
    </InfoCard>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, watch } from "vue";
  import { http, t } from "@/logic";

  const perPage = 5;
  const columns = ref([
    {
      prop: "name",
      type: "url",
      width: "70%",
      class: "ellipsis",
    },
    {
      prop: "date",
      width: "30%",
    },
  ]);

  const meta = ref({
    currentPage: 1,
    count: 0,
  });

  const data = ref({
    meta: {
      count: 0
    },
    results: []
  });
  const loading = ref(false);

  async function pageChange(event) {
    meta.value.currentPage = event.page + 1; 
    await load();
  }

  async function load() {
    const query = `?currentPage=${meta.value.currentPage}&perPage=${perPage}`;
    await http.fetchWithLoading(data, loading, `/api/monitoring/alerts${query}`);
    meta.value.count = data.value.meta.count;
  }

  onMounted(async () => {
    await load();
  });
</script>

<style lang="scss" scoped>
.monitoring-changes {
  height: 100%;
}
</style>
