<template>
  <Chart v-if="data"
         type="line"
         :data="data"
         :options="options" />
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { formatMoney } from "@/logic";

  const props = defineProps(["data", "legend"]);

  const options = ref({
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            if (Number.isInteger(value)) {              
              var txt = formatMoney(value.toString());
              return txt + (props.data.unit || "");
            }
          },
        },
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        borderColor: "#f2792b",
        borderWidth: 1,
        titleColor: "#f2792b",
        bodyColor: "#f2792b",
        displayColors: false,
      },
      legend: {
        display: props.legend == "true",
        position: "bottom",
      }
    },
    datasets: {
      line: {
        fill: false,
        borderColor: "#f2792b",
        pointBackgroundColor: "#ffffff",
        lineTension: 0
      }
    },
    maintainAspectRatio: false,
  });
</script>
