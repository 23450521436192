<template>
  <div>
    <div class="row">
      <div class="col-12 col-xxl-8 d-flex align-items-stretch print-percent-100">
        <InfoCard icon-color="orange"
                  card-type="dropdown"
                  is-header-hidden>
          <template #content>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 pl-0">
                  <div class="row mb-5">
                    <div class="col-12">
                      <h3 class="cc-title">
                        {{ data.details.name }}
                      </h3>
                      <h3 class="cc-address">
                        <span>{{ data.details.headquarter }}</span>
                        <a class="cc-map"
                           :href="data.details.mapUrl"
                           target="_blank"> {{ t("summary.card.companyDetails.map") }}</a>
                      </h3>
                      <h3 class="cc-location">
                        <a :href="'/quick/search?query=GEO_' + data.details.registrationNumber.value"
                           target="_blank"
                           :style="{color: data.details.locationInfo.color }">
                          {{ data.details.locationInfo.value }}
                        </a>                        
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6 p-initial ">
                  <div class="cardCompanyDetails">
                    <h4 class="title">
                      {{ t('summary.card.companyDetails.registrationNumber') }}
                    </h4>
                    <p class="content">
                      {{ data.details.registrationNumber.value }}
                      <copy v-if="!data.details.registrationNumber.empty"
                            :text="data.details.registrationNumber.value" />
                    </p>
                  </div>
                  <hr class="line line--card-separator">
                  <div class="cardCompanyDetails">
                    <h4 class="title">
                      {{ t('summary.card.companyDetails.taxNumber') }}
                    </h4>
                    <p class="content">
                      {{ data.details.taxNumber.value }}
                      <copy v-if="!data.details.taxNumber.empty"
                            :text="data.details.taxNumber.value" />
                    </p>
                  </div>
                  <hr class="line line--card-separator">
                  <div class="cardCompanyDetails">
                    <h4 class="title">
                      {{ t('summary.card.companyDetails.incorporationYear') }}
                    </h4>
                    <p class="content">
                      {{ data.details.incorporationYear.value }}
                      <copy v-if="!data.details.incorporationYear.empty"
                            :text="data.details.incorporationYear.value" />
                    </p>
                  </div>
                  <hr class="line line--card-separator">
                </div>
                <div class="col-12 col-xl-6 p-initial ">
                  <div class="cardCompanyDetails">
                    <h4 class="title">
                      {{ t('summary.card.companyDetails.revenue') }}
                    </h4>
                    <p class="content"
                       style="white-space: nowrap">
                      {{ data.details.revenue.value }}
                      <span v-if="data.details.revenue.year"
                            class="badge">{{ data.details.revenue.year }}</span>
                      <copy v-if="!data.details.revenue.empty"
                            :text="data.details.revenue.value" />
                    </p>
                  </div>
                  <hr class="line line--card-separator">
                  <div class="cardCompanyDetails">
                    <h4 class="title">
                      {{ t('summary.card.companyDetails.creditability') }}
                    </h4>
                    <p class="content"
                       style="white-space: nowrap">
                      {{ data.details.creditability.value }}
                      <span class="badge">{{ data.details.creditability.year }}</span>
                      <copy :text="data.details.creditability.value" />
                    </p>
                  </div>
                  <hr class="line line--card-separator">
                  <div class="cardCompanyDetails">
                    <h4 class="title">
                      {{ t('summary.card.companyDetails.employees') }}
                    </h4>
                    <p class="content">
                      {{ data.details.employees.value }}
                      <span class="badge">{{ data.details.employees.date }}</span>
                      <copy :text="data.details.employees.value" />
                    </p>
                  </div>
                  <hr class="line line--card-separator">
                </div>
                <div class="col-12 p-initial">
                  <div class="cardCompanyDetails">
                    <h4 class="title">
                      {{ t('summary.card.companyDetails.mainActivity') }}
                    </h4>
                    <p class="content">
                      {{ data.details.mainActivity }}
                    </p>
                  </div>
                  <hr class="line line--card-separator">
                </div>
                <div class="col-12 p-initial">
                  <div class="cardCompanyDetails">
                    <h4 class="title">
                      {{ t('summary.card.companyDetails.rating') }}
                    </h4>
                    <p class="content">
                      <span v-if="summary.certificate" style="text-align: center">
                        <span style="font-weight: bold; font-size: 6rem; color: rgb(243, 121, 43)">
                          {{ summary.certificate }}
                        </span>
                        <br />
                        <span style="font-weight: bold; font-size: 1.3rem">
                          {{ t('summary.certificateDesciption') }}
                        </span>                        
                      </span>
                      <span v-else>
                        {{ data.details.rating }}
                      </span>                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </InfoCard>
      </div>

      <div class="col-12 col-xxl-4 print-percent-100">
        <div class="row print-indicator-row">
          <div class="col-12 col-lg-6 col-xxl-12 d-flex align-items-stretch print-percent-50">
            <Indicator :indicator-value="data.risk || null"
                       :indicator-alpha="data.riskAlpha || null" 
                       :disabled="data.riskDisabled"
                       :lite="data.liteRating" />
          </div>
          <div class="col-12 col-lg-6 col-xxl-12 d-flex align-items-stretch print-percent-50">
            <SectorRisk :company="data" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="summary.covid"
         class="row print-covid">
      <div class="col-12">
        <InfoCard :title="summary.covid.title"
                  :icon="summary.covid.icon.key"
                  :icon-color="summary.covid.icon.color"
                  card-type="dropdown">
          <template #content>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <p class="covid">
                    {{ summary.covid.description }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </InfoCard>
      </div>
    </div>

    <div v-if="summary.taxTable"
         class="row print-taxTable">
      <div class="col-12">
        <InfoCard :title="t('companyDetailsCards.taxTableTitle')"
                  icon="activity-scope"
                  icon-color="orange"
                  card-type="dropdown">
          <template #content>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <InteractiveTable :columns="taxTable"
                                    :items="summary.taxTable" />
                </div>
              </div>
            </div>
          </template>
        </InfoCard>
      </div>
    </div>

    <div v-if="summary.closedCompaniesTable"
         class="row print-closedCompaniesTable">
      <div class="col-12">
        <InfoCard :title="t('companyDetailsCards.closedCompaniesTableTitle')"
                  icon="activity-scope"
                  icon-color="orange"
                  card-type="dropdown">
          <template #content>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <InteractiveTable :columns="closedCompaniesTable"
                                    :items="summary.closedCompaniesTable" />
                </div>
              </div>
            </div>
          </template>
        </InfoCard>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import { http, t } from "@/logic";

  const props = defineProps(["data"]);

  const route = useRoute();
  const loading = ref(false);
  const summary = ref({} as any);
  const taxTable = ref([
    {
      value: t("companyDetailsCards.taxTableColumns.memberTaxNumber"),
      prop: "memberTaxNumber",
      type: "link",
      width: "10%",
    },
    {
      value: t("companyDetailsCards.taxTableColumns.memberName"),
      prop: "memberName",
      width: "35%",
    },
    {
      value: t("companyDetailsCards.taxTableColumns.address"),
      prop: "address",
      width: "25%",
    },
    {
      value: t("companyDetailsCards.taxTableColumns.memberIDNumber"),
      prop: "memberIDNumber",
      width: "15%",
    },
    {
      value: t("companyDetailsCards.taxTableColumns.joinDate"),
      prop: "joinDate",
      width: "15%",
    },
  ]);
  const closedCompaniesTable = ref([
    {
      value: t("companyDetailsCards.closedCompaniesTableColumns.companyIDNumber"),
      prop: "registrationNumber",
      width: "15%",
    },
    {
      value: t("companyDetailsCards.closedCompaniesTableColumns.companyName"),
      prop: "companyName",
      width: "70%",
    },
    {
      value: t("companyDetailsCards.closedCompaniesTableColumns.status"),
      prop: "status",
      width: "15%",
    },
  ]);

  onMounted(async () => {
    await http.fetchWithLoading(summary, loading, "/api/report/" + route.params.id + "/summary");
  });
</script>

<style lang="scss" scoped>
.badge {
  margin-left: 0.5rem;
  padding: 0.4rem 1rem;
  color: #868a92;
  font-weight: 300;
  font-size: 1.2rem;
  background: #ebeced;
  border-radius: 50px;
}

.cc-title {
  margin-bottom: 3rem;
  color: $color-font-primary;
  font-weight: 600;
  font-size: 2rem;
}

.cc-address {
  display: flex;
  align-items: center;
  color: $color-font-primary;
  font-weight: 400;
  font-size: 1.4rem;
}

.cc-location a {
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration: none;
}

.cc-map {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: $color-blue;
  font-weight: 400;
  font-size: 1.4rem;
  text-transform: uppercase;


    &:hover {
      color: $color-blue;
      text-decoration: none;
      cursor: pointer;
    }


  &::before {
    margin-right: 0.5rem;
    color: $color-blue;
    font-size: 2rem;
    font-family: $font-icon;
    content: "\e92e";
  }
}

.covid {
  color: $color-red;
  font-style: italic;
}
</style>
