import { http } from "./index";

export class User {
  get name() {
    return localStorage.getItem("name") || "";
  }

  set name(value) {
    localStorage.setItem("name", value);
  }

  get onlyValid() {
    return localStorage.getItem("onlyValid") == "true" || false;
  }

  set onlyValid(value: boolean) {
    localStorage.setItem("onlyValid", value.toString());
  }

  get dataExpanded() {
    return localStorage.getItem("dataExpanded") == "true" || false;
  }

  set dataExpanded(value: boolean) {
    localStorage.setItem("dataExpanded", value.toString());
  }

  get inThousandHUF() {
    return localStorage.getItem("inThousandHUF") == "true" || false;
  }

  set inThousandHUF(value: boolean) {
    localStorage.setItem("inThousandHUF", value.toString());
  }

  get hideSearchHistory() {
    return localStorage.getItem("hideSearchHistory") == "true" || false;
  }

  set hideSearchHistory(value: boolean) {
    localStorage.setItem("hideSearchHistory", value.toString());
  }

  get perPage() {
    return parseInt(localStorage.getItem("perPage")) || 10;
  }

  set perPage(value: number) {
    localStorage.setItem("perPage", value.toString());
  }

  get claims() {
    return localStorage.getItem("claims") || "";
  }

  set claims(value) {
    localStorage.setItem("claims", value);
  }

  get token() {
    return localStorage.getItem("token") || "";
  }

  set token(value) {
    localStorage.setItem("token", value);
  }

  get locale() {
    return localStorage.getItem("locale") || "";
  }

  set locale(value) {
    localStorage.setItem("locale", value);
  }

  async fetchMe() {
    const data = await http.get("/api/me");
    this.name = data.name;
    this.onlyValid = data.onlyValid;
    this.dataExpanded = data.dataExpanded;
    this.inThousandHUF = data.inThousandHUF;
    this.hideSearchHistory = data.hideSearchHistory;
    this.perPage = data.perPage;

    if (data.claims) {
      this.claims = data.claims.join(",");
    }
  }

  login(token) {
    this.token = token;
  }

  logout() {
    this.token = "";
    this.claims = "";
  }
}