<template>
  <div>
    <div class="tab-inner-header">
      <h4 class="title">
        {{ data.header.title }}
      </h4>
      <div class="mt-3">
        <span class="mr-1">
          <Chip :label="t('financialReport.type')" />					
          <span class="text">
            {{ data.header.type }}
          </span>
        </span>
        <span class="mr-1">
          <Chip :label="t('financialReport.variant')" />
          <span class="text">
            {{ data.header.variant }}
          </span>
        </span>
        <span class="mr-1">
          <Chip :label="t('financialReport.currency')" />
          <span class="text">
            {{ data.header.currency }}
          </span>
        </span>
        <span class="mr-1">
          <Chip :label="t('financialReport.date')" />
          <span class="text">
            {{ data.header.date }}
          </span>
        </span>
      </div>
    </div>
    <Table :columns="data.table.columns" 
           :items="data.table.items" 
           :meta="data.table.meta" 
           :inner-table="true" 
           :mask="mask" />
  </div>  
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { formatMoney, t } from "@/logic";

  const props = defineProps(["data", "showInThousand"]);

  function mask(td, meta) {
    if (props.showInThousand) {
      const value = formatMoney(td.value / 1000);
      return {
        value: value,
        postfix: `e${meta.currency}`,
      };
    } else {
      const value = formatMoney(td.value);
      return {
        value: value,
        postfix: `${meta.currency}`,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .title {
      color: $color-primary;
  }
</style>
