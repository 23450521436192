<template>
  <InfoCard icon="switch"
            icon-color="orange"
            :title="t('summary.card.otherCompanies.title')"
            card-type="dropdown">
    <template #content>
      <InteractiveTable :items="data.otherCompanies"
                        :columns="columns" />
    </template>
  </InfoCard>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { t } from "@/logic";

  const props = defineProps(["data"]);

  const columns = ref([
    {
      value: t("summary.card.otherCompanies.columns.regNumber"),
      prop: "regNumber",
      type: "url",
      width: "20%",
    },
    {
      value: t("summary.card.otherCompanies.columns.name"),
      prop: "name",
      width: "50%",
    },
    {
      value: t("summary.card.otherCompanies.columns.status"),
      prop: "status",
      width: "30%",
    },
  ]);
</script>
