<template>
  <div id="app">
    <Toast position="top-center" />
    <router-view />
    <ScrollTop />
  </div>
</template>
<script setup lang="ts">
  import { onMounted, ref } from "vue";
  import { user, init } from "@/logic";
  import Toast from "primevue/toast";

  init();

  onMounted(async () => {
    if (user.token) {      
      await user.fetchMe();
    }
  });

  window.addEventListener('vite:preloadError', (event) => {
    window.location.reload()
  })
</script>