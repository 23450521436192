<template>
  <div class="widget">
    <InfoCard icon="home-international"
              icon-color="orange"
              :title="t('home.widgets.internationalOrders')">
      <template v-if="data"
                #content>
        <DataTable :value="data.items"
                   selection-mode="single"
                   class="mt-3">
          <Column field="date"
                  style="width: 30px" />
          <Column field="type"
                  style="width: 30px" />
          <Column field="name">
            <template #body="item">
              <a :href="'/international/download/' + item.data.id">{{ item.data.name }}</a>
            </template>
          </Column>
        </DataTable>
        <Paginator v-if="data.count > data.perPage"
                   :rows="data.perPage"
                   :total-records="data.count"
                   @page="pageChange" />
      </template>
    </InfoCard>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";	
  import { http, t } from "@/logic";

  const page = ref(0);
  const data = ref(null);

  async function load() {
    await http.fetch(data, `/api/international/orders?page=${page.value}`);    
  }

  async function pageChange(event) {
    page.value = event.page;
    await load();
  }

  onMounted(async () => {
    await load();
  });
</script>
