<template>
  <div class="send-message">
    <div v-if="!data.notification"
         class="header">
      {{ t("sendMessage.title") }}
    </div>
    <div v-if="!data.notification"
         class="form">
      <textarea v-model="data.message"
                rows="10"
                :placeholder="t('sendMessage.message')" />
      <Button :label="t('sendMessage.send')"
              class="mt-3"
              @click="send" />
    </div>
    <div v-if="data.notification"
         class="thanks">
      {{ data.notification }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed } from "vue";
  import { http, t } from "@/logic";

  const data = ref({
    message: "",
    notification: false,
  });

  async function send() {
    data.value = await http.post("/api/send-message", data.value);
  }
</script>

<style scoped lang="scss">
.send-message {
  width: 70%;
  max-width: 70rem;
  margin: 25vh auto 3rem;

  @include media-breakpoint-down(lg) {
    margin-top: 15rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: white;
    font-weight: 500;
    font-size: 1.4rem;
    background-color: $color-send-message;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    user-select: none;

      &:hover {
        cursor: pointer;
      }

  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & input,
    & textarea {
      width: 100%;
      padding: 1rem;
      color: $color-font-primary;
      font-size: 1.4rem;
      border: 1px solid $color-border-primary;
      border-top: none;

      &:focus,
      &:hover,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .thanks {
    margin-top: 20rem;
    font-size: 2.5rem;
    text-align: center;
  }
}
</style>
