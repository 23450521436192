<template>
  <div class="page-home">
    <div v-if="loaded"
         class="row">
      <div class="col-12 col-xxl-8">
        <div class="row">
          <div v-for="widget in widgets"
               :key="widget.name"
               :class="widget.class">
            <component :is="widget.component"
                       v-if="widget.show" />
          </div>
        </div>
      </div>
      <div class="col-12 col-xxl-4">
        <News />
      </div>
    </div>
    <WidgetController :list="widgets"
                      group="home"
                      @update="widgetUpdate" />
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, markRaw } from "vue";
  import HomeNavigation from "../components/pages/home/HomeNavigation.vue";
  import MonitoringChanges from "../components/pages/home/MonitoringChanges.vue";
  import SearchHistory from "../components/pages/home/SearchHistory.vue";
  import InternationalAlerts from "../components/pages/home/InternationalAlerts.vue";
  import InternationalOrders from "../components/pages/home/InternationalOrders.vue";
  import { t } from "@/logic";
	
  const loaded = ref(false);
  const widgets = ref([
    {
      name: "HomeNavigation",
      component: markRaw(HomeNavigation),
      title: t("home.widgets.navigation"),
      class: "col-12",
      show: true
    },
    {
      name: "MonitoringChanges",
      component: markRaw(MonitoringChanges),
      title: t("home.widgets.monitoringChanges"),
      class: "col-12 col-xl-6",
      show: true
    },
    {
      name: "SearchHistory",
      component: markRaw(SearchHistory),
      title: t("home.widgets.searchHistory"),
      class: "col-12 col-xl-6",
      show: true
    },
    {
      name: "InternationalOrders",
      component: markRaw(InternationalOrders),
      title: t("home.widgets.internationalOrders"),
      class: "col-12",
      show: false
    },
    {
      name: "InternationalAlerts",
      component: markRaw(InternationalAlerts),
      title: t("home.widgets.internationalAlerts"),
      class: "col-12",
      show: false
    },
  ]);

  function widgetUpdate(updatedWidgets) {
    widgets.value = updatedWidgets;
    loaded.value = true;
  }
</script>