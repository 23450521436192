<template>
  <SearchPage icon="monitoring"
              title="Monitoring"
              max-width="100%">
    <template #controls>
      <div class="marketing-title__content__controls">
        <div class="co-checkbox-primary" />
      </div>
    </template>

    <template #content>
      <Results :columns="columns"
               :items="data.results"
               :meta="meta"
               max-width="100%"
               :per-page="[10, 20, 50]"
               @page-change="pageChange">
        <template #headerLeft>
          <div class="d-block">
            <Dropdown v-model="customOrder" 
                      :options="data.meta.orderOptions" 
                      option-label="label" 
                      :placeholder="t('customOrder.sorting')"
                      @change="onOrder" />
          </div>
        </template>

        <template #headerRight>
          <div>
            <div v-show="importOpen"
                 class="mb-3 import-box">
              <div class="import-close-button"
                   @click="importOpen = false">
                <div class="import-close icon status-end" />
              </div>
              <div class="input-box"
                   @click="importer.click()">
                <span v-if="file === null">{{ t('monitoring.import.selectFile') }}</span>
                <span v-else>{{ file.name }}</span>
              </div>
              <input ref="importer"
                     type="file"
                     style="display: none"
                     @change="selectFile">
              <div class="d-flex justify-content-between mt-5 align-items-end">
                <a class="example mr-5"
                   href="/assets/sample/Monitoring_import_minta.xlsx"
                   download>
                  {{ t('monitoring.import.downloadExample') }}
                </a>
                <div class="button button--primary button--small"
                     @click="importList">
                  {{ t('monitoring.import.upload')
                  }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <Button v-if="!exportStarted"
                      :label="t('monitoring.buttons.export')"
                      icon="icon export"
                      @click="exportList" />
              <Button :label="t('monitoring.buttons.import')"
                      icon="icon import"
                      style="margin-left: 1rem"
                      @click="importOpen = !importOpen" />
            </div>
          </div>
        </template>

        <template #name="{ row }">
          <router-link class="ellipsis"
                       :to="row.name.url">
            {{ row.name.value }}
          </router-link>
          <div class="ellipsis">
            {{ row.address }}
          </div>
        </template>

        <template #numbers="{ row }">
          <div class="text-md-center ellipsis">
            {{ row.registrationNumber }}
          </div>
          <div class="text-md-center ellipsis">
            {{ row.taxNumber }}
          </div>
        </template>

        <template #cor="{ row }">
          <div class="text-md-center ellipsis"
               :style="{ color: row.cor.color }">
            {{ row.cor.value }}
          </div>
          <div class="text-md-center ellipsis">
            {{ row.creditability }}
          </div>
        </template>

        <template #lastEvents="{ row }">
          <div v-for="(value, index) in row.lastEvents"
               :key="index"
               class="ellipsis">
            {{ value }}
          </div>
        </template>

        <template #delete="props">
          <i class="icon delete"
             style="font-size: 2rem"
             @click="remove(props.row.delete)" />
        </template>
      </Results>

      <div v-if="meta.count"
           class="grid-shower">
        <span><b>{{ meta.count }} {{ t('monitoring.numRows') }}</b></span>
      </div>

      <div style="margin-top:60px;">
        <h1 class="text-md-center">
          {{ t('monitoring.currentMonitoringLevel') }}: {{ monitoringLevel }}
        </h1>
        <PageCard :title="t('monitoring.monitoringLevels')">
          <template #content>
            <div style="width: 70%;margin:auto">
              <Table class="financial-report-table"
                     :columns="monitoringLevels.columns"  
                     :items="monitoringLevels.items" />  
            </div>
          </template>
        </PageCard>
      </div>
    </template>
  </SearchPage>
</template>

<script setup lang="ts">
  import { ref, onMounted, watch } from "vue";
  import { http, t, toggle, toast } from "@/logic";
  import * as MonitoringTable from "./MonitoringTable";

  const loading = ref(false);
  const customOrder = ref({ value: " " } as any);
  const search = ref("");
  const file = ref(null);
  const importOpen = ref(false);
  const exportStarted = ref(false);
  const monitoringLevel = ref("Alap");
  const meta = ref({
    orderBy: "name",
    order: "asc",
    currentPage: 1,
    perPage: 10,
    count: null,
    search: "",
  });
  const data = ref({
    downloadUrl: "",
    importExampleUrl: "",
    meta: {
      orderOptions: [],
    },
  } as any);
  const columns = ref([
    {
      value: t("monitoring.nameHq"),
      type: "slot",
      prop: "name",
      // orderable: true,
      width: "20%",
    },
    {
      value: t("monitoring.regTaxNumber"),
      prop: "numbers",
      // orderable: true,
      type: "slot",
      class: "justify-content-md-center text-md-center",
      width: "15%",
    },
    {
      value: t("monitoring.ratingCreditLimit"),
      prop: "cor",
      // orderable: true,
      type: "slot",
      class: "justify-content-md-center text-md-center",
      width: "20%",
    },
    {
      value: t("monitoring.status"),
      prop: "status",
      type: "icon",
      // orderable: true,
      class: "justify-content-lg-left",
      width: "13%",
    },
    {
      value: t("monitoring.lastEvent"),
      prop: "lastEvents",
      class: "",
      type: "slot",
      width: "25%",
    },
    {
      value: t("monitoring.delete"),
      prop: "delete",
      type: "slot",
      class: "justify-content-lg-center d-md-flex justify-content-md-center cursor",
      width: "5%",
    },
  ]);
  const monitoringLevels = ref(MonitoringTable.default as any);
  const importer = ref(null);

  function changeView() {
    toggle(this.monitoringView);
  }

  async function load() {
    let query =
      `?orderBy=${meta.value.orderBy}&order=${meta.value.order}&currentPage=${meta.value.currentPage}` +
      `&perPage=${meta.value.perPage}&search=${meta.value.search}`;
    await http.fetchWithLoading(data, loading, `/api/monitoring${query}`);
    meta.value.count = data.value.meta.count;
    monitoringLevel.value = data.value.monitoringLevel;
  }

  async function onOrder(order) {
    meta.value.orderBy = order.value.orderBy;
    meta.value.order = order.value.order;
    await load();
  }

  async function remove(url) {
    await http.delete(url);
    await load();
  }

  async function importList() {
    const formData = new FormData();

    formData.append("file", file.value);
    const res = await http.postForm("/api/monitoring/import", formData);
    file.value = null;
    importOpen.value = false;
    toast(res.message);
  }

  async function exportList() {
    exportStarted.value = true;
    const res = await http.get("/api/monitoring/export");

    toast(res.message);
  }

  function selectFile(event) {
    if (event.target.files[0]) {
      file.value = event.target.files[0];
    }
  }

  async function onCustomOrder() {
    meta.value.currentPage = 1;
    meta.value.orderBy = customOrder.value.orderBy;
    meta.value.order = customOrder.value.order;
    await load();
  }

  async function pageChange(event) {
    meta.value.currentPage = event.page + 1;
    meta.value.perPage = event.rows;
    await load();
  }

  onMounted(async () => {
    monitoringLevels.value.columns.forEach(x => x.title = t(x.title));
    monitoringLevels.value.items.forEach(x => x[0].value = t(x[0].value));

    await load();
  });

  watch(() => meta.value.currentPage, load);
</script>

<style lang="scss" scoped>
.grid-shower {
  float: right;
  margin: 12px 6px 10px 0;
}
.table-container {
  padding: 0 !important;
  margin-top: 2rem !important;
  border: 1px solid black !important;
}

.search {
  background: transparent;
  border: none;
  border-bottom: 1px solid $color-border-primary;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.import-box {
  padding: 2rem;
  background-color: #fff;
  border: 1px solid $color-border-primary;
  border-radius: 10px;

  & .input-box {
    padding: 10px;
    font-size: 1.4rem;
    text-align: center;
    border: 1px solid $color-primary;
    border-style: dashed;
    border-radius: 10px;
  }

  & .example {
    font-size: 1.4rem;
  }
}

.delete {
  cursor: pointer;
}

.marketing-card {
  margin-top: 0;
}

.import-close-button {
  position: absolute;
  top: -10px;
  right: 29px;
  align-items: center;
  justify-content: center;
  background-color: $color-body-bg;
  border-radius: 100%;
  cursor: pointer;
}

.import-close {
  margin-top: 1px;
  margin-left: 1px;
  color: $color-font-tertiary;
  font-size: 25px;
}

.import-close-button:hover .import-close {
  color: $color-primary;
}

.import-open {
  color: $color-primary !important;
}
</style>
