<template>
  <div class="indicator-table">
    <div class="container-fluid">
      <div class="row d-none d-md-flex">
        <div class="col-12 col-md-2 d-flex align-items-end p-initial">
          <h4 class="indicator-table__title">
            {{ t('summary.indicator.indicatorTable.title.ccPoints') }}
          </h4>
        </div>
        <div class="col-12 col-md-2 d-flex align-items-end p-initial">
          <h4 class="indicator-table__title">
            {{ t('summary.indicator.indicatorTable.title.rating') }}
          </h4>
        </div>
        <div class="col-12 col-md-5 d-flex align-items-end p-initial">
          <h4 class="indicator-table__title" />
        </div>
        <div class="col-12 col-md-3 d-flex align-items-end p-initial">
          <h4 class="indicator-table__title">
            {{ t('summary.indicator.indicatorTable.title.creditAdvice') }}
          </h4>
        </div>
      </div>
      <div v-for="item in items"
           :key="item.rating"
           class="row indicator-table__content">
        <div class="col-12 col-md-2 p-initial">
          <div :class="indicatorColor(item.ccPoints)"
               class="indicator-table__content__data">
            <h3 class="d-block d-md-none">
              {{ t('summary.indicator.indicatorTable.title.ccPoints') }}
            </h3>
            <span v-if="item.ccPoints !== null"
                  class="bold">
              {{ item.ccPoints[0] }} - {{ item.ccPoints[1] }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-2 p-initial">
          <div :class="indicatorColor(item.ccPoints)"
               class="indicator-table__content__data">
            <h3 class="d-block d-md-none">
              {{ t('summary.indicator.indicatorTable.title.rating') }}
            </h3>
            <span class="bold">
              {{ item.rating }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-5 p-initial">
          <div :class="indicatorColor(item.ccPoints)"
               class="indicator-table__content__data">
            <span>
              {{ item.description }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-3 p-initial">
          <div v-if="item.creditAdvice !== null"
               :class="indicatorColor(item.ccPoints)"
               class="indicator-table__content__data">
            <h3 class="d-block d-md-none">
              {{ t('summary.indicator.indicatorTable.title.creditAdvice') }}
            </h3>
            <span v-if="item.creditAdvice !== null">
              {{ item.creditAdvice }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { http, t } from "@/logic";

  const props = defineProps(["items"]);

  function indicatorColor(points: number) {
    if(points == null) {
      return "grey";
    } else if (points[0] >= 85 && points[1] <= 100) {
      return "green";
    } else if (points[0] >= 67 && points[1] <= 84) {
      return "lime";
    } else if (points[0] >= 51 && points[1] <= 66) {
      return "yellow";
    } else if (points[0] >= 34 && points[1] <= 50) {
      return "orange";
    } else if (points[0] >= 16 && points[1] <= 33) {
      return "orange-dark";
    } else if (points[0] >= 0 && points[1] <= 15) {
      return "red";
    }
  }
</script>

<style lang="scss" scoped>
.indicator-table {
  margin: 0;

  .row > * {
    padding: 0px !important;
  }

  &__title {
    width: 100%;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    color: $color-font-quaternary;
    font-weight: 600;
    font-size: 1.6rem;
    border-bottom: 1px solid $color-font-tertiary;
  }

  &__content {
    overflow: hidden;
    border-radius: 4px;

    @include media-breakpoint-down(md) {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &__data {
      height: 100%;
      padding: 1.5rem;

      &.green,
      &.lime,
      &.yellow,
      &.orange,
      &.orange-dark,
      &.red,
      &.gray {
        -webkit-text-stroke: 0.2px rgba(0, 0, 0, 0.5);
      }

      &.green {
        background-color: $color-bg-green;

        & span {
          color: $color-green;
        }
      }

      &.lime {
        background-color: $color-bg-lime;

        & span {
          color: $color-lime;
        }
      }

      &.yellow {
        background-color: $color-bg-yellow;

        & span {
          color: $color-yellow;
        }
      }

      &.orange {
        background-color: $color-bg-orange;

        & span {
          color: $color-orange;
        }
      }

      &.orange-dark {
        background-color: $color-bg-orange-dark;

        & span {
          color: $color-orange-dark;
        }
      }

      &.red {
        background-color: $color-bg-red;

        & span {
          color: $color-red;
        }
      }

      &.gray {
        background-color: $color-bg-gray;

        & span {
          color: $color-gray;
        }
      }

      & h3 {
        margin-bottom: 1rem;
        color: $color-font-quaternary;
        font-weight: 600;
        font-size: 1.8rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      & span {
        color: $color-font-primary;
        font-weight: 400;
        font-size: 1.4rem;

        &.bold {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
