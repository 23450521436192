<template>
  <div :class="['item', {'inactive': !item.active}]"
       :style="{borderColor: item.borderColor}">
    <div class="header d-flex align-items-center justify-content-between"
         @click="onClick">      
      <div class="title ml-1 mr-auto cursor-pointer">
        {{ item.title }}
      </div>
      <div class="icons d-flex align-items-center">
        <div v-for="(icon, index) in item.icons"
             :key="'icon-' + index + icon.key" 
             :class="[{'icon': icon.key, 'rounded': !icon.key}, icon.key]" 
             :style="{color: icon.color, backgroundColor: icon.backgroundColor }">
          <span v-if="icon.value">{{ icon.value }}</span>
        </div>
      </div>
    </div>
    <div v-if="show || forceOpen"
         class="content">
      <div v-if="item.address"
           class="d-flex align-items-center">
        <div class="icon map-marker mr-1" />
        <div class="address">
          {{ item.address }}
        </div>
      </div>
      <div v-if="item.connections"
           class="d-flex align-items-center">
        <div class="icon connections_1 mr-1" />
        <div class="connections-title connection-number">
          {{ item.connections.title }}
        </div>        
      </div>
      <div v-if="item.connections"
           class="d-flex flex-wrap">
        <div v-for="(connection, index) in item.connections.items"
             :key="'connection-' + index" 
             class="connection ml-2 mt-2" 
             :style="{ backgroundColor: connection.backgroundColor, color: connection.color }">
          {{ connection.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { http, t } from "@/logic";
  import { useEventBus } from "@vueuse/core";

  const props = defineProps(["item", "forceOpen"]);

  const show = ref(true);
  const showConnections = ref(false);

  function onClick() {
    if (props.item.scrollTo) {
      document.getElementById(props.item.scrollTo).scrollIntoView();
    } else if (props.item.url) {
      window.open(props.item.url, "_blank");
    }
  }

  const bus = useEventBus<boolean>("connectionList/openAll");

  bus.on((event) => {
    if (!props.forceOpen) {
      show.value = event;
    }
  });
</script>

<style scoped lang="scss">
.item.inactive {
  border-color: #939393 !important;
  opacity: 0.4 !important;
  //background-color: #00000041 !important;

  & > .icon {
    color: #c8cbce;
  }
}

.item {
  margin-top: 1rem;
  padding: 1rem;
  border: 3px solid;
  border-radius: 1rem;
}

.title {
  overflow: hidden;
  color: $color-font-primary;
  font-size: 1.4rem;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  /*font-weight: 500;*/
}

.title:hover {
  color: $color-font-primary;
  text-decoration: none;
}

.icon {
  font-size: 2.5rem;
}
.rounded {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.1rem;
  min-height: 2.1rem;
  font-size: 10px !important;
  border-radius: 50% !important;

  & span {
    //
  }
}

.connection {
  padding: 0 0.5rem;
  font-size: 1.2rem;
  border-radius: 1rem;
}

.content {
  & .address,
  .connections-title {
    overflow: hidden;
    font-size: 1.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    /*font-weight: 500;*/
  }
}

.map-marker {
  color: #5d92d2;
  font-size: 1.6rem;
}

.connections_1 {
  color: #5d92d2;
  font-size: 1.6rem;
}

.connection-net {
  height: 1.4rem;
  margin-right: 0.2rem;
}

.sort-down,
.sort-up {
  font-size: 1.6rem;
}

.connection-number {
  /*color: $color-blue;*/
  /*font-weight: 600;*/
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}
</style>
