<template>
  <div v-if="!loading"
       class="page-financial-report-summary">
    <div class="p-initial">
      <div class="row">
        <div class="col-12">
          <PageCard :title="t('financialReportSummary.title')"
                    icon="financial-reports">
            <template #content>
              <EmptyState v-if="financialReportSummary.empty"
                          page="financialReportSummary" />
              <template v-else>
                <Alert v-for="(alert, index) in financialReportSummary.alerts"
                       :key="'alert-' + index "
                       :icon="alert.icon"
                       :title="alert.title" 
                       :bg="alert.bg"
                       :color="alert.color" 
                       :description="alert.description" />
                <Table class="financial-report-table"
                       :columns="financialReportSummary.table.columns" 
                       :items="financialReportSummary.table.items"
                       :meta="financialReportSummary.table.meta" 
                       :opened-rows="openedRows"
                       :mask="mask">
                  <template #heading-0>
                    <div class="">
                      <div class="d-flex flex-row">
                        <div class="co-checkbox-primary">
                          <Checkbox v-model="showInThousand"
                                    input-id="showInThousand"
                                    :binary="true" />
                          <label for="showInThousand">
                            {{
                              t('financialReportSummary.checkboxes.showInThousand',
                                {currency: financialReportSummary.table.meta.currency })
                            }}
                          </label>
                        </div>
                      </div>
                      <div class="d-flex flex-row mt-2">
                        <a class="button button--primary button--small"
                           @click="download()">
                          {{ t('financialReportSummary.checkboxes.downloadXLSX') }}
                        </a>
                      </div>
                    </div>
                  </template>
                  <template #0="{ row, columns, index }">
                    <div class="d-flex">
                      <i v-if="!row[0].isTitle"
                         class="icon sort-down"
                         @click="toggleRow(index)" />
                      {{ row[0].value }}
                    </div>
                  </template>
                  <template #rowDetails="{ row, columns }">
                    <div>
                      <div v-if="row[0].meta && row[0].meta.tooltip"
                           class="mb-4 details">
                        {{ row[0].meta.tooltip }}
                      </div>
                      <TableChart :row="row"
                                  :columns="columns" />
                    </div>                    
                  </template>
                </Table>
              </template>
            </template>
          </PageCard>
        </div>
        <div class="col-12">
          <DidYouKnow page-name="financial-report-summary"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  import { http, t, formatMoney, user, init, route } from "@/logic";

  init();

  const loading = ref(false);
  const openedRows = ref([]);
  const showInThousand = ref(false);
  const financialReportSummary = ref({
    downloadUrl: "",
    alerts: [],
    empty: false,
    table: {
      meta: {} as any,
      columns: [],
      items: [],
    },
  });

  function toggleRow(id) {
    const index = openedRows.value.indexOf(id);
    if (index > -1) {
      openedRows.value.splice(index, 1);
    } else {
      openedRows.value.push(id);
    }
    console.log(openedRows);
  }

  function mask(td, meta) {
    if (showInThousand.value) {
      const value = formatMoney(td.value / 1000);
      return {
        value: value,
        postfix: `e${meta.currency}`,
      };
    } else {
      const value = formatMoney(td.value);
      return {
        value: value,
      };
    }
  }

  async function download() {
    await http.blob("/api/report/" + route.params.id + "/financial-report-summary-download");
  }

  onMounted(async () => {
    showInThousand.value = user.inThousandHUF;

    await http.fetchWithLoading(financialReportSummary, loading, "/api/report/" + route.params.id + "/financial-report-summary");    
  });
</script>

<style scoped lang="scss">
.details {
  overflow: hidden;
  font-size: 1.4rem;
  white-space: normal;
}

.sort-down {
	font-size: 1.8rem;
	margin-right: 0.5rem;
	color: $color-blue;
	cursor: pointer;
}
</style>
