<template>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-8">
      <InfoCard card-type="info"
                :is-header-hidden="true">
        <template #content>
          <h3>
            {{ data.title }}
          </h3>
          <div v-for="(item, index) in data.items"
               :key="'item-' + index"
               class="item">
            <div>
              <img class="icon"
                   src="/assets/icons/connection-list/company-number.svg"
                   alt="Icon">
              <router-link class="name"
                           :to="'/report/' + item.companyNumber + '/summary'">
                {{ item.name }}
              </router-link>
            </div>
            <div>
              <img class="icon"
                   src="/assets/icons/connection-list/headquarter.svg"
                   alt="Icon">
              <span>{{ item.address }}</span>
            </div>
            <div>
              <img class="icon"
                   src="/assets/icons/connection-list/tax-number.svg"
                   alt="Icon">
              <span>{{ item.taxnumber }}</span>
            </div>
            <ul class="mt-2">
              <li v-for="(section, index2) in item.sections" 
                  :key="'section-' + index2">
                <router-link :to="section.url">
                  {{ section.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </template>
      </InfoCard>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import { http } from "@/logic";

  const route = useRoute();
  const loading = ref(false);
  const data = ref({
    items: [],
    title: "",
  });

  async function load() {
    await http.fetch(data, `/api/monitoring/alert/${route.params.id}`);
  }

  onMounted(async () => {
    await load();
  });
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  text-transform: uppercase;
}

.item {
  margin: 3rem;

  & .name {
    color: $color-blue;
    font-weight: 700;
    text-decoration: none;
  }

  & .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
}
</style>
