<template>
  <div class="search-history">
    <InfoCard icon="history"
              icon-color="orange"
              :title="t('home.searchHistory.title')">
      <template #content>
        <InteractiveTable :columns="columns" 
                          :items="data.results" 
                          :meta="meta" />
        <Paginator :rows="perPage"
                   :total-records="meta.count"
                   @page="pageChange" />
      </template>
    </InfoCard>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";	
  import { http, t, user, init } from "@/logic";

  init();

  const perPage = 5;
  const columns = ref([
    {
      prop: "name",
      type: "url",
      width: "65%",
      class: "ellipsis",
    },
    {
      prop: "date",
      width: "35%",
    },
  ]);
  const meta = ref({
    currentPage: 1,
    count: 0,
  });
  const data = ref({
    results: [],
    meta: {} as any
  });
  const loading = ref(false);

  async function load() {
    if (user.hideSearchHistory) return;

    let query = `?currentPage=${meta.value.currentPage}`;
    await http.fetchWithLoading(data, loading, `/api/search-history${query}`);
    meta.value.count = data.value.meta.count;
  }

  async function pageChange(event) {
    meta.value.currentPage = event.page + 1;
    await load();
  }

  onMounted(async () => {
    await load();
  });
</script>

<style lang="scss" scoped>
.search-history {
  height: 100%;
}
</style>
