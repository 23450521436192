import * as d3 from "d3";

let width = 1200;
let height = 500;
const widthRatio = 120;
const heightRatio = 60;
const nodeHeight = 35;
const nodeWidth = 200;

let deltaTranslateX = 0;
let deltaTranslateY = 0;
let translateX = 0;
let translateY = 0;
let scale = 1; // set later
let selected = null;
let selectedLines = [];
let lastDragX = null;
let lastDragY = null;
let iconFontFace = null;

export function build(graph, _iconFontFace) {
  buildGraph(graph);

  iconFontFace = _iconFontFace;

  onResize(true);
}

export function onResize(adjustHeight) {
  const element = document.querySelector(".connection-graph");

  if (!element) return;

  let deltaXrate = 2.3;
  let deltaYrate = 2.3;
  let newHeight = element.clientHeight;
  let newWidth = element.clientWidth;

  if (!newHeight) {
    newHeight = window.screen.height;
    newWidth = window.screen.width;
    deltaXrate = 2.3;
    deltaYrate = 3;
  }

  if (adjustHeight) setHeight();

  const svg = element.children[0];
  if (svg) {
    svg.setAttribute("width", newWidth.toString());
    svg.setAttribute("height", newHeight.toString());
    const g = svg.children[0];
    if (g) {
      deltaTranslateX = newWidth / deltaXrate;
      deltaTranslateY = newHeight / deltaYrate;
      redraw();
    }
  }
  height = newHeight;
  width = newWidth;
}

export function setHeight() {
  height = window.innerHeight - 160;
}

function buildGraph(graph) {
  buildSvg();

  const force = d3.forceSimulation(graph.nodes).force("link", d3.forceLink(graph.links));
  const vis = d3.select(".connection-graph g");
  const svg = d3.select(".connection-graph svg");
  const drag = d3.drag();
  const links = vis
    .selectAll(".link")
    .data(graph.links)
    .enter()
    .append("path")
    .attr("stroke", (d) => {
      return d.color || "#000";
    })
    .attr("original-color", (d) => {
      return d.color || "#000";
    })
    .attr("target-id", (d) => {
      return d.target.id;
    })
    .attr("source-id", (d) => {
      return d.source.id;
    })
    .attr("stroke-width", (d) => {
      return "1";
    })
    .attr("id", (d) => {
      return `l${d.source}-${d.target}`;
    })
    .attr("class", "link")
    .attr("marker-end", (d) => {
      return "url(#" + d.marker + ")";
    });

  //background drag
  svg
    .call(
      d3
        .drag()
        .on("drag", function (e) {              
          if (lastDragX != null && lastDragY != null) {
            const deltaX = e.x - lastDragX;
            const deltaY = e.y - lastDragY;

            translateX += deltaX;
            translateY += deltaY;
          }

          lastDragX = e.x;
          lastDragY = e.y;
          redraw();
        })
        .on("end", function (e) {
          lastDragX = null;
          lastDragY = null;
        })
    )
    .on("wheel", function (e) {
      zoom(e.deltaY < 0);
    });

  drag.on("start", function (e) {
    if (selected) {
      setAttribute("r" + selected.id, "stroke-width", "2");
      selectedLines.forEach((line) => {
        d3.select(line)
          .attr("stroke-width", "1")
          .attr("stroke", line.getAttribute("original-color"));
        d3.select(line).attr("stroke-width", "1");
      });		
    }
		
    const nodeId = e.subject.id;
    if (nodeId != 0) {
      selected = e.subject;
      setAttribute("r" + nodeId, "stroke-width", "3");
    }

    const linesTo = Array.from(document.querySelectorAll("[target-id=\"" + nodeId + "\"]"));
    const linesFrom = Array.from(document.querySelectorAll("[source-id=\"" + nodeId + "\"]"));
    const lines = linesTo.concat(linesFrom);
    selectedLines = lines;
    lines.forEach((line) => {
      d3.select(line).attr("stroke-width", "1.8").attr("stroke", "#7517eb");
      d3.select(line).attr("stroke-width", "1.8");
    });
  });

  drag.on("drag", function (event, d) {
    d.fx = event.x;
    d.fy = event.y;

    force.alpha(1).restart();
  });

  const nodes = vis
    .selectAll("g")
    .data(graph.nodes)
    .enter()
    .append("g")
    .attr("node-id", (d) => {
      return d.id;
    })
    .attr("id", (d) => {
      return "n" + d.id;
    })
    .attr("class", (d) => {
      return "node";
    })
    .each((d) => {
      if (d.coord) {
        d.fx = d.coord[0] * widthRatio;
        d.fy = d.coord[1] * heightRatio;
      }
    })
    .attr("style", (d) => {
      if (d.shadow) {
        return "filter: url(\"#shadow\")";
      }

      return "";
    })
    .call(drag);

  // node box
  nodes
    .append("rect")
    .attr("id", (d) => {
      return "r" + d.id;
    })
    .attr("class", "rect")
    .attr("height", nodeHeight)
    .attr("width", nodeWidth)
    .attr("rx", 5)
    .attr("ry", 5)
    .attr("fill", (d) => {
      return d.fill || "#FFF";
    })
    .attr("stroke", (d) => {
      return d.strokeColor || "#000";
    })
    .attr("stroke-width", (d) => {
      return d.strokeWidth || 1.5;
    });

  // node expand icon
  nodes
    .filter((d) => {
      return d.rows && d.rows.length > 0;
    })
    .append("polygon")
    .attr("points", "1.45 0 5 3.55 8.55 0 10 1.45 5 6.45 0 1.45 1.45 0")
    .attr("transform", "translate(10,15)");
  nodes
    .filter((d) => {
      return d.rows && d.rows.length > 0;
    })
    .append("rect")
    .style("cursor", "pointer")
    .attr("width", "15")
    .attr("height", "22")
    .attr("transform", "translate(7,7)")
    .attr("fill", "none")
    .on("click", (d) => {
      d.id = d.target.__data__.id;
      const rect = d3.select("#r" + d.id);
      const info = d3.select("#i-" + d.id);
      if (!d.target.__data__.expanded) {
        rect.attr("height", (d) => {
          return nodeHeight + d.rows.length * 15 + 10;
        });
        info.attr("class", "info");
      } else {
        rect.attr("height", nodeHeight);
        info.attr("class", "info hide");
      }
      d.target.__data__.expanded = !d.target.__data__.expanded;
    });

  // company name
  nodes
    .append("a")
    .attr("href", (d) => {
      return d.icons[0].url.replace("connections", "summary");
    })
    .attr("target", (d) => {
      return d.icons.length == 3 ? "_BLANK" : "";
    })
    .append("text")
    .attr("class", "name")
    .text((d) => {
      return d.name;
    })
    .attr("cursor", (d) => {
      return d.icons.length == 3 ? "pointer" : "drag";
    })
    .attr("transform", "translate(25,22)")
    .style("font-family", "\"Montserrat\", sans-serif")
    .attr("font-size", "12")
    .each(function (d) {
      const length = d.icons ? d.icons.length : 0;
      const width = nodeWidth - 50 - length * 16;
      textEllipsis(this, d.name, width);
    });

  // node icons
  const icons = nodes
    .append("g")
    .attr("width", (d) => {
      return "150";
    })
    .attr("id", (d, i) => {
      return "icons-" + i;
    })
    .attr("transform", (d) => {
      return "translate(175,24)";
    });

  icons.each((d, i) => {
    const box = vis.select("#icons-" + i);
    if (d.icons) {
      d.icons.forEach((icon, index) => {
        let el = box;
        if (icon.url) {
          const url = box.append("a").attr("href", icon.url).attr("target", "_blank");
          el = url;
        }

        if (icon.value) {
          const transLeft = -20 * index + 1.5;
          const g = el.append("g").attr("transform", `translate(${transLeft},-15)`);
          g.append("circle")
            .attr("cy", 9)
            .attr("cx", 9)
            .attr("r", 9)
            .attr("fill", icon.backgroundColor);
          g.append("text")
            .attr("cursor", "grab")
            .text(icon.value)
            .attr("font-weight", "bold")
            .attr("fill", icon.color)
            .attr("font-size", 8)
            .attr("y", 12)
            .attr("x", 9)
            .attr("text-anchor", "middle");
        } else {
          const transLeft = -20 * index;
          el.append("text")
            .attr("font-family", "icomoon")
            .attr("font-size", 21)
            .text(icon.key)
            .attr("fill", icon.color)
            .attr("cursor", icon.url ? "pointer" : "grab")
            .attr("transform", `translate(${transLeft}, 3)`);
        }
      });
    }
  });

  const infos = nodes
    .append("g")
    .attr("class", "info hide")
    .attr("id", (d, i) => {
      return "i-" + d.id;
    })
    .attr("transform", "translate(5,50)");

  infos.each((d, i) => {
    const box = vis.select("#i-" + i);
    if (d.rows) {
      d.rows.forEach((row, index) => {
        const paddingTop = 15 * index;

        if (row.icon) {
          let el = box;
          if (row.icon.url) {
            const url = box.append("a").attr("href", row.icon.url).attr("target", "_blank");
            el = url;
          }
          el.append("text")
            .attr("font-family", "icomoon")
            .text(row.icon.key)
            .attr("fill", row.icon.color)
            .attr("cursor", "pointer")
            .attr("transform", `translate(0, ${paddingTop})`);
        }

        box
          .append("text")
          .text(row.value)
          .attr("transform", `translate(15,${paddingTop})`)
          .attr("cursor", "grab")
          .each(function (d) {
            const width = nodeWidth - 30;
            textEllipsis(this, row.value, width);
          });
      });
    }
  });

  force.on("tick", () => {
    if (force.alpha() < 0.1) {
      force.stop();
    }
    nodes.each(function (d) {
      d.bounds = {
        x1: d.x,
        y1: d.y,
        x2: d.x + nodeWidth,
        y2: d.y + nodeHeight,
      };
    });

    links.attr("d", (d) => {
      makeEdgeBetween(d, d.source.bounds, d.target.bounds);
      const lineData = [
        {
          x: d.sourceIntersection.x,
          y: d.sourceIntersection.y,
        },
        {
          x: d.arrowStart.x,
          y: d.arrowStart.y,
        },
      ];

      const lineFunction = d3
        .line()
        .x((d) => {
          return d.x;
        })
        .y((d) => {
          return d.y;
        })
        .curve(d3.curveLinear);

      return lineFunction(lineData);
    });

    nodes.attr("transform", (d) => {
      return `translate(${d.x},${d.y})`;
    });
  });

  const el = d3.select("#n0");
  el.select("rect").attr("stroke-width", "4");
  const nodeId = 0;

  const linesTo = Array.from(document.querySelectorAll("[target-id=\"" + nodeId + "\"]"));
  const linesFrom = Array.from(document.querySelectorAll("[source-id=\"" + nodeId + "\"]"));
  const lines = linesTo.concat(linesFrom);
  selectedLines = lines;

  lines.forEach((line) => {
    d3.select(line).attr("stroke-width", "1.8").attr("stroke", "#7517eb");
    d3.select(line).attr("stroke-width", "1.8");
  });

  return force;
}

function buildSvg() {
  const outer = d3
    .select(".connection-graph")
    .append("svg")
    .attr("class", "svg-graph")
    .attr("width", width)
    .attr("height", height)
    .attr("pointer-events", "all")
    .attr("id", "full-screen-svg")
    .append("g");

  //outer
  //  .append("defs")
  //  .append("style")
  //  .attr("type", "text/css")
  //  .text(
  //    "@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');"
  //  );

  const defs = outer.append("svh:defs");
  defs
    .append("marker")
    .attr("id", "vt")
    .attr("viewBox", [0, 0, 25, 25])
    .attr("refX", 10)
    .attr("refY", 10)
    .attr("markerWidth", 25)
    .attr("markerHeight", 25).html(`<circle cx="9" cy="9" r="9" fill="#90949B"></circle>
<text x="3" y="13" font-size="10" fill="#FFF">VT</text>`);

  defs
    .append("marker")
    .attr("id", "v")
    .attr("viewBox", [0, 0, 25, 25])
    .attr("refX", 10)
    .attr("refY", 10)
    .attr("markerWidth", 25)
    .attr("markerHeight", 25).html(`<circle cx="9" cy="9" r="9" fill="#90949B"></circle>
<text x="5" y="13" font-size="10" fill="#FFF">V</text>`);

  defs
    .append("marker")
    .attr("id", "t")
    .attr("viewBox", [0, 0, 25, 25])
    .attr("refX", 10)
    .attr("refY", 10)
    .attr("markerWidth", 25)
    .attr("markerHeight", 25).html(`<circle cx="9" cy="9" r="9" fill="#90949B"></circle>
<text x="5" y="13" font-size="10" fill="#FFF">T</text>`);

  defs
    .append("marker")
    .attr("id", "é")
    .attr("viewBox", [0, 0, 25, 25])
    .attr("refX", 10)
    .attr("refY", 10)
    .attr("markerWidth", 25)
    .attr("markerHeight", 25).html(`<circle cx="9" cy="9" r="9" fill="#90949B"></circle>
<text x="6" y="13" font-size="10" fill="#FFF">É</text>`);

  defs
    .append("marker")
    .attr("id", "j")
    .attr("viewBox", [0, 0, 25, 25])
    .attr("refX", 10)
    .attr("refY", 10)
    .attr("markerWidth", 25)
    .attr("markerHeight", 25).html(`<circle cx="9" cy="9" r="9" fill="#90949B"></circle>
<text x="8" y="12" font-size="10" fill="#FFF">J</text>`);

  defs
    .append("filter")
    .attr("id", "shadow")
    .attr("height", "180%")
    .attr("filterUnits", "userSpaceOnUse").html(`
<feDropShadow
dx="0"
dy="0"
stdDeviation="4"
flood-color="#000"
flood-opacity="0.3"
/>`);

  const controls = d3
    .select("#full-screen-svg")
    .append("g")
    .attr("class", "controls")
    .attr("transform", "translate(10, 50)");

  controls
    .append("rect")
    .attr("height", 145)
    .attr("width", 40)
    .attr("transform", "translate(-10, -10)");

  //fullscreen
  controls
    .append("svg:image")
    .attr("id", "fullscreen")
    .attr("xlink:href", "/assets/icons/connection-list/fullscreen.svg")
    .attr("width", 23)
    .attr("height", 24)
    .attr("transform", "translate(0, 0)")
    .on("click", () => {
      toggleScreen(true);
    })
    .append("svg:title")
    .text(() => {
      return "Teljes képernyő mód";
    });

  //normal screen
  controls
    .append("svg:image")
    .attr("id", "normalscreen")
    .attr("xlink:href", "/assets/icons/connection-list/normalscreen.svg")
    .attr("width", 23)
    .attr("height", 24)
    .attr("transform", "translate(0, 0)")
    .attr("style", "display: none")
    .on("click", () => {
      toggleScreen(false);
    })
    .append("svg:title")
    .text(() => {
      return "Normál képernyő mód";
    });

  //zoom in
  controls
    .append("svg:image")
    .attr("xlink:href", "/assets/icons/connection-list/zoomin.svg")
    .attr("width", 23)
    .attr("height", 24)
    .attr("transform", "translate(0, 25)")
    .on("click", () => {
      zoom(true);
    })
    .append("svg:title")
    .text(() => {
      return "Nagyítás";
    });

  //center
  controls
    .append("svg:image")
    .attr("xlink:href", "/assets/icons/connection-list/tocenter.svg")
    .attr("width", 23)
    .attr("height", 24)
    .attr("transform", "translate(0, 50)")
    .on("click", () => {
      scale = 1;
      translateX = 0;
      translateY = 0;
      redraw();
    })
    .append("svg:title")
    .text(() => {
      return "Középpontba";
    });

  //zoom out
  controls
    .append("svg:image")
    .attr("xlink:href", "/assets/icons/connection-list/zoomout.svg")
    .attr("width", 23)
    .attr("height", 24)
    .attr("transform", "translate(0, 75)")
    .on("click", () => {
      zoom(false);
    })
    .append("svg:title")
    .text(() => {
      return "Kicsinyítés";
    });

  //save image
  controls
    .append("svg:image")
    .attr("xlink:href", "/assets/icons/connection-list/save.svg")
    .attr("width", 23)
    .attr("height", 24)
    .attr("transform", "translate(0, 100)")
    .on("click", () => {
      saveToImage();
    })
    .append("svg:title")
    .text(() => {
      return "Mentés képként";
    });
}

function zoom(up) {
  if (up) {
    scale += 0.1;
    translateX -= 70;
    translateY -= 20;
  } else {
    scale -= 0.1;
    translateX += 70;
    translateY += 20;
  }
  redraw();
}

function redraw() {
  const localvis = d3.select(".connection-graph g");
  localvis.attr(
    "transform",
    "translate(" +
			translateX +
			"," +
			translateY +
			") " +
			"scale(" +
			scale +
			") " +
			"translate(" +
			deltaTranslateX +
			"," +
			deltaTranslateY +
			")"
  );
}

function makeEdgeBetween(link, source, target) {
  let si = rayIntersection(source, cx(target), cy(target));
  if (!si) {
    si = {
      x: cx(source),
      y: cy(source),
    };
  }

  let ti = rayIntersection(target, cx(source), cy(source), 10);
  if (!ti) {
    ti = {
      x: cx(target),
      y: cy(target),
    };
  }

  const dx = ti.x - si.x;
  const dy = ti.y - si.y;

  link.sourceIntersection = si;
  link.targetIntersection = ti;
  link.arrowStart = {
    x: si.x + dx,
    y: si.y + dy,
  };
}

function rayIntersection(item, x2, y2, margin = 0) {
  const ints = lineIntersections(item, cx(item), cy(item), x2, y2, margin);
  return ints.length > 0 ? ints[0] : null;
}

function lineIntersections(item, x1, y1, x2, y2, margin) {
  const sides = [
    [item.x1 - margin, item.y1 - margin, item.x2 + margin, item.y1 - margin],
    [item.x2 + margin, item.y1 - margin, item.x2 + margin, item.y2 + margin],
    [item.x2 + margin, item.y2 + margin, item.x1 - margin, item.y2 + margin],
    [item.x1 - margin, item.y2 + margin, item.x1 - margin, item.y1 - margin],
  ];
  const intersections = [];
  for (let i = 0; i < 4; ++i) {
    const r = lineIntersection(
      x1,
      y1,
      x2,
      y2,
      sides[i][0],
      sides[i][1],
      sides[i][2],
      sides[i][3]
    );
    if (r) intersections.push({ x: r.x, y: r.y });
  }
  return intersections;
}

function lineIntersection(x1, y1, x2, y2, x3, y3, x4, y4) {
  const dx12 = x2 - x1,
    dx34 = x4 - x3,
    dy12 = y2 - y1,
    dy34 = y4 - y3,
    denominator = dy34 * dx12 - dx34 * dy12;

  if (denominator === 0) return null;

  const dx31 = x1 - x3,
    dy31 = y1 - y3,
    numa = dx34 * dy31 - dy34 * dx31,
    a = numa / denominator,
    numb = dx12 * dy31 - dy12 * dx31,
    b = numb / denominator;

  if (a >= 0 && a <= 1 && b >= 0 && b <= 1) {
    return {
      x: x1 + a * dx12,
      y: y1 + a * dy12,
    };
  }

  return null;
}

function cx(item) {
  return (item.x1 + item.x2) / 2;
}

function cy(item) {
  return (item.y1 + item.y2) / 2;
}

function textEllipsis(el, text, width) {
  if (typeof el.getSubStringLength !== "undefined") {
    el.textContent = text;
    let len = text.length;
    while (el.getSubStringLength(0, len--) > width) {
      el.textContent = text.slice(0, len) + "...";
    }
  } else if (typeof el.getComputedTextLength !== "undefined") {
    while (el.getComputedTextLength() > width) {
      text = text.slice(0, -1);
      el.textContent = text + "...";
    }
  } else {
    while (el.getBBox().width > width) {
      text = text.slice(0, -1);
      el.textContent = text + "...";
    }
  }
}

function toggleScreen(full) {
  if (full) {
    document.querySelector<HTMLElement>("#fullscreen").style.display = "none";
    document.querySelector<HTMLElement>("header").style.display = "none";
    document.querySelector<HTMLElement>(".company-info").style.display = "none";
    document.querySelector<HTMLElement>(".connections-card").style.display = "none";
    document.querySelector<HTMLElement>("#normalscreen").style.display = "block";
    if (document.getElementById("full-screen-svg").requestFullscreen) {
      document.getElementById("full-screen-svg").requestFullscreen();
    }               
  } else {
    document.querySelector<HTMLElement>("#normalscreen").style.display = "none";
    document.querySelector<HTMLElement>("#fullscreen").style.display = "block";
    document.querySelector<HTMLElement>("header").style.display = "block";
    document.querySelector<HTMLElement>(".company-info").style.display = "block";
    document.querySelector<HTMLElement>(".connections-card").style.display = "block";
    location.reload();
  }
}

function saveToImage() {
  const doctype =
		"<?xml version=\"1.0\" standalone=\"no\"?><!DOCTYPE svg PUBLIC \"-//W3C//DTD SVG 1.1//EN\" " +
		"\"http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\">";

  function isExternal(url) {
    return (
      url && url.lastIndexOf("http", 0) === 0 && url.lastIndexOf(window.location.host) === -1
    );
  }

  function styles(el) {
    const css = "";
    const sheets = document.styleSheets;

    for (let i = 0; i < sheets.length; i++) {
      if (isExternal(sheets[i].href)) {
        continue;
      }

      const rules = sheets[i].cssRules;
      if (rules != null) {
        for (let j = 0; j < rules.length; j++) {
          const rule = rules[j];
          //TO_FIX, NEED?
          //if (typeof rule.style != "undefined") {
          //	let match = null;
          //	try {
          //		match = el.querySelector(rule.selectorText);
          //	} catch (err) {
          //		console.error(err);
          //	}
          //	if (match) {
          //		const selector = rule.selectorText;
          //		css += selector + " { " + rule.cssText + " }\n";
          //	}
          //}
        }
      }
    }

    return css;
  }

  function svgAsDataUri(el, cb) {
    const xmlns = "http://www.w3.org/2000/xmlns/";

    const outer = document.createElement("div");
    const clone = el.cloneNode(true);
    const box = el.getBoundingClientRect();
    const width = box.width;
    const height = box.height;

    clone.setAttribute("version", "1.1");
    clone.setAttributeNS(xmlns, "xmlns", "http://www.w3.org/2000/svg");
    clone.setAttributeNS(xmlns, "xmlns:xlink", "http://www.w3.org/1999/xlink");
    clone.setAttribute("width", width);
    clone.setAttribute("height", height);
    clone.setAttribute("viewBox", "0 0 " + width + " " + height);
    outer.appendChild(clone);

    let css = styles(el);
    css = css.replace(/\.connection-graph /g, "");

    const data =
			"@font-face {" +
			"font-family: icomoon;src: url(\"data:application/font-woff;charset=utf-8;base64," +
				iconFontFace +
			"\");" +
			"}";
    css = css + "\r\n.info, .controls{display: none}\r\n" + data + "\r\n"; //embed font

    const s = document.createElement("style");
    s.setAttribute("type", "text/css");
    s.innerHTML = "<![CDATA[\n" + css + "\n]]>";

    const defs = document.createElement("defs");
    defs.appendChild(s);
    clone.insertBefore(defs, clone.firstChild);

    const svg = doctype + outer.innerHTML;

    const uri = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(svg)));

    cb(uri);
  }

  function saveSvgAsPng(el, name) {
    svgAsDataUri(el, function (uri) {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext("2d");

        context.drawImage(img, 0, 0);

        const a = document.createElement("a");
        a.download = name;
        a.href = canvas.toDataURL("image/png");
        document.body.appendChild(a);
        a.addEventListener("click", () => {
          a.parentNode.removeChild(a);
        });
        a.click();
      };
      img.src = uri;
    });
  }

  saveSvgAsPng(document.querySelector("#full-screen-svg"), "kapcsolati-halo.png");
}

function setAttribute(id, name, value) {
  return document.getElementById(id).setAttribute(name, value);
}