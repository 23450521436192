<template>
  <div :class="['co-collapse', { 'chart-collapse': chartCollapse }]">
    <h3 :class="['title', { 'active': open, 'inactive': !open }]"
        @click="open = !open">
      {{ title }}
    </h3>
    <div v-if="open">
      <slot />
    </div>		
  </div>
</template>

<script setup lang="ts">	
  import { ref, onMounted } from "vue";
  import { useEventBus } from "@vueuse/core";
  
  const props = withDefaults(defineProps<{
    title?: string,
    chartCollapse?: boolean,
    page?: string,
    opened?: boolean
  }>(), {
    title: null,
    chartCollapse: false,
    page: null,
    opened: false
  });

  const open = ref(false);

  if (props.page) {
    const bus = useEventBus<boolean>(props.page + "/collapseToggle");

    bus.on((event) => {
      open.value = event;
    });
  }

  onMounted(() => {
    open.value = props.opened;
  });
</script>
