export default {
  columns: [
    {
      title: "",
      description: "",
      align: "left",
      width: "40%",
      prop: "title"
    },
    {
      title: "Extended",
      description: "",
      align: "center",
      width: "15%",
      type: "icon",
      prop: "extended",
    },
    {
      title: "Small",
      description: "",
      align: "center",
      width: "15%",
      type: "icon",
      prop: "small",
    },
    {
      title: "Risk check",
      description: "",
      align: "center",
      width: "15%",
      type: "icon",
      prop: "check",
    },
    {
      title: "Company Profile",
      description: "",
      align: "center",
      width: "15%",
      type: "icon",
      prop: "profile",
    },
  ],
  items: [
    {
      title: "international.table.egyediAzonositok",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      profile: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
    }, {
      title: "international.table.statusz",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      profile: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
    },{
      title: "international.table.jogiForma",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      profile: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
    },{
      title: "international.table.alapitasEve",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      profile: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
    },{
      title: "international.table.elerhetosegek",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      profile: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
    },{
      title: "international.table.vezetok",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      profile: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
    },{
      title: "international.table.nace",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      profile: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
    },{
      title: "international.table.tokeadatok",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      profile: "",
    },{
      title: "international.table.fizetesiMoral",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      profile: "",
    },{
      title: "international.table.bankiAdatok",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: "",
      profile: "",
    },{
      title: "international.table.merlegEredmeny",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: "",
      profile: "",
    },{
      title: "international.table.tulajdonosiSzerkezet",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      check: "",
      profile: "",
    },{
      title: "international.table.cegtortenet",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: "",
      check: "",
      profile: "",
    },{
      title: "international.table.pozNeg",
      extended: { icon: { key: "status-ok", color: "rgb(243, 121, 43)" } },
      small: "",
      check: "",
      profile: "",
    },{
      title: "international.table.kockazatiBesorolas",
      extended: "international.table.600skala",
      small: "international.table.5skala",
      check: "international.table.3skala",
      profile: "",
    },{
      title: "international.table.hitelezesiJavaslat",
      extended: "international.table.1MEURig",
      small: "international.table.250eEURig",
      check: "international.table.25eEURig",
      profile: "",
    },{
      title: "international.table.adatokIdoszaka",
      extended: "international.table.utolso3ev",
      small: "international.table.utolsoEv",
      check: "",
      profile: "",
    }
  ],
};