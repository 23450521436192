<template>
  <div v-if="!loading"
       class="pt-5">
    <div class="button-container no-print">
      <router-link class="button--small button button--primary"
                   :to="{name: 'report.negative-info', params: {id: $route.params.id}}">
        {{ t("negativeInfos.details.back") }}
      </router-link>
      <a v-if="!downloadStarted"
         class="button--small button button--primary d-none d-md-flex mt-0"
         @click="download()">
        {{ t("negativeInfos.details.download") }}
      </a>
      <div v-else
           class="mt-2">
        <span class="spinner-border text-primary" />
        <span class="ml-3 text-primary">{{ t('companyData.buttons.downloading') }}</span>
      </div>      
    </div>
    <h3 class="mt-5 format-title">
      {{ details.title }}
    </h3>
    <div class="mt-4 format-infos"
         v-html="details.html" />
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import { http, t } from "@/logic";

  const route = useRoute();
  const loading = ref(false);
  const downloadStarted = ref(false);
  const details = ref({} as any);

  async function download() {    
    await http.blob(`/api/report/print-info/${route.params.id}/${route.params.itemId}`, downloadStarted);
  }

  onMounted(async () => {
    const url = `/api/report/${route.params.id}/negative-info/${route.params.itemId}/details`;
    await http.fetchWithLoading(details, loading, url);
  });
</script>

<style scoped>
.button {
  width: 150px;
}

.format-infos:deep(p) {
  margin-bottom: 10px;
}

.format-infos {
  font-size: 15px;
  line-height: 20px;
}

.button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .format-infos:deep(blockquote) {
    font-size: 16px;
    line-height: 22px;
  }

  .format-infos:deep(blockquote) {
    padding: 10px 30px 0 30px;
    border: none;
  }

  .format-infos:deep(address) {
    padding: 10px 0 0 0;
  }

  .format-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }
}
</style>

