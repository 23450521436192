<template>
  <div class="page-home">
    <div class="row d-flex align-items-center justify-content-center">
      <div class="col-8 col-xxl-8">
        <InfoCard icon-color="orange"
                  card-type="dropdown"
                  is-header-hidden>
          <template #content>
            <div v-if="!loading"
                 class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <h4 class="mb-2"
                      style="color:darkgray">
                    {{ data.id }}
                  </h4>
                  <h1 class="mb-2">
                    {{ data.name }}
                  </h1>
                  <p class="mb-1">
                    <span :class="'fi fi-' + data.country"></span>
                    {{ data.address }}
                  </p>
                </div>                
                <div v-if="data.availability && (data.availability.check || data.availability.extended || 
                     data.availability.profile || data.availability.small)">
                  <div v-if="!orderStarted">
                    <div v-if="data.lastOrder"
                         class="col-12 text-center mt-5"
                         @click="openLastOrder"
                         v-html="t('international.lastOrder', data.lastOrder)" />
                    <h2 class="mt-5">
                      {{ t("international.newOrder") }}
                    </h2>
                    <table class="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th v-for="col in reportTypes.columns.filter(x => a(x.prop))"
                              :key="col.prop"
                              :style="{width: col.width}">
                            {{ col.title }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ t("international.table.ar") }}</td>
                          <td v-for="col in reportTypes.columns.filter(x => x.prop != 'title' && a(x.prop))"
                              :key="col.prop"
                              class="fw-bold text-center">
                            {{ data.price[col.prop] }} EUR
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td v-for="col in reportTypes.columns.filter(x => x.prop != 'title' && a(x.prop))"
                              :key="col.prop"
                              class="text-center">
                            <button class="button button--primary"
                                    @click="purchase(col.prop)">
                              {{ $t('international.download.purchase.purchaseButtonText') }}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td v-for="col in reportTypes.columns.filter(x => x.prop != 'title' && a(x.prop))"
                              :key="col.prop"
                              class="text-center">
                            <a :href="'https://cdn.creditonline.hu/app/sample/CR_' + col.title.replace(' ', '') + '.pdf'"
                               target="_blank">
                              {{ $t('international.table.minta') }}
                            </a>
                          </td>
                        </tr>
                        <tr v-for="row in reportTypes.items"
                            :key="row.title">
                          <td v-for="col in reportTypes.columns.filter(x => a(x.prop))"
                              :key="col.prop"
                              :style="{textAlign: (col.prop == 'title' ? 'left' : 'center')}">
                            <span v-if="row[col.prop].icon"
                                  class="icon"
                                  :class="row[col.prop].icon.key"
                                  :style="{color: row[col.prop].icon.color}" />
                            <span v-else>
                              {{ t(row[col.prop]) }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="orderStarted && !downloaded"
                       class="text-center">
                    <ProgressSpinner style="width: 50px; height: 50px"
                                     stroke-width="8"
                                     animation-duration=".5s"
                                     class="my-5" />
                    <div>
                      {{ t("international.downloading") }}
                    </div>
                  </div>
                  <div v-if="downloaded"
                       class="text-center my-5">
                    {{ t("international.downloaded") }}
                  </div>
                </div>         
                <div v-else style="text-align: center" class="mt-5">
                  <h3> {{ t("international.onlyOffline") }}</h3>
                  <button class="button button--primary mx-auto mt-5"
                          @click="purchaseOffline()"
                          v-if="!offlineOrderSent">
                    {{ $t('international.download.purchase.purchaseOfflineButtonText') }}
                  </button>              
                </div>
              </div>
            </div>
          </template>
        </InfoCard>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted } from "vue";
  import { http, t, router, route, init, toast } from "@/logic";
  import * as ReportTypes from "./ReportTypes";
  import ProgressSpinner from "primevue/progressspinner";

  init();

  const loading = ref(false);
  const orderStarted = ref(false);
  const downloaded = ref(false);
  const offlineOrderSent = ref(false);
  const data = ref({
    id: null,
    name: null,
    country: null,
    address: null,    
    availability: {
      check: false,
      extended: false,
      profile: false,
      small: false,
    },
    price: {},
    lastOrder: {
      id: ""
    },
  });
  const reportTypes = ref(ReportTypes.default as any);

  async function load() {
    await http.fetchWithLoading(data, loading, "/api/international-new/predownload/" + route.params.id);    
  }

  async function purchase(type) {
    orderStarted.value = true;
    await http.blob("/api/international-new/download/" + data.value.country + "/" + route.params.id + "/" + 
      type + "/" + data.value.availability[type], null, true);
    downloaded.value = true;
  }

  async function purchaseOffline() {
    const resp = await http.post("/api/international/offline", {
      countries: [{ name: data.value.country }],
      companyName: data.value.name,
      address: data.value.address,
    });
    toast(resp.message);
    offlineOrderSent.value = true;
  }

  function a(type) {
    return type == "title" || data.value.availability[type];
  }

  async function openLastOrder() {
    await http.blob("/api/international-new/download/" + data.value.lastOrder.id, null, true);    
  }

  onMounted(async () => {
    await load();
  });
</script>

<style lang="scss" scoped>
  table {
    th {
      text-align: center
    }

    td {
      padding: 5px;
    }

    .icon {
      display: block !important;
      font-weight: 600;
      padding-top: 3px;
    }

    button {
      display: inline-block;
    }
  }
</style>