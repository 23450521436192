<template>
  <div class="news">
    <div class="header">
      <div class="icon tips" />
      <h3 class="title">
        {{ t('home.news.title') }}
      </h3>
    </div>
    <a v-for="(item, index) in data.items"
       :key="index"
       :href="item.url"
       class="box"
       target="_blank">
      <h4 class="title">{{ item.title }}</h4>
      <p class="description">
        {{ item.description }}
      </p>
    </a>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, watch } from "vue";
  import { http, t } from "@/logic";

  const data = ref({
    items: [],
  });
  const loading = ref(false);

  async function load() {
    await http.fetchWithLoading(data, loading, "/api/news");
  }

  onMounted(async () => {
    await load();
  });
</script>

<style lang="scss" scoped>
.news {
  margin: 3rem 0;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: $box-shadow-card;

  & .header {
    display: flex;
    align-items: center;

    & .icon {
      margin-right: 0.5rem;
      color: $color-blue;
      font-size: 3rem;
    }

    & .title {
      color: $color-font-primary;
      font-weight: 600;
      font-size: 1.6rem;
      text-transform: uppercase;
    }
  }

  & .box {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 1rem;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: rgb(247, 247, 247);
        cursor: pointer;
      }


    & .title {
      margin-bottom: 1rem;
      color: $color-blue;
      font-weight: 500;
      font-size: 1.6rem;

      &::after {
        position: relative;
        top: 0.4rem;
        margin-left: 0.5rem;
        color: $color-blue;
        font-size: 2rem;
        font-family: $font-icon;
        content: "\e912";
      }
    }

    & .description {
      color: $color-font-primary;
      font-weight: 500;
      font-size: 1.4rem;
    }
  }
}
</style>
