import { createApp } from "vue";
import App from "./App.vue";
import { vueRouter, i18n } from "./logic";
import "./scss/main.scss";
import PrimeVue from "primevue/config";
import { setSentry } from "./logic/Sentry";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";

const app = createApp(App);

app.use(vueRouter);
app.use(i18n);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);

setSentry(app);

app.mount("#app");