<template>
  <div class="negative-collapse opened">
    <div class="negative-collapse__header">
      <div class="negative-collapse__header__icon icon"
           :class="icon.key"
           :style="{ 'color': icon.color }" />
      <h3 class="negative-collapse__header__title"
          :style="{color: titleColor}">
        {{ title }}
      </h3>
    </div>
    <div v-if="open"
         class="negative-collapse__content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { useEventBus } from "@vueuse/core";

  const props = defineProps(["title", "icon", "page", "titleColor"]);

  const open = ref(true);

  if (props.page) {
    const bus = useEventBus<boolean>(props.page + "/openAll");

    bus.on((event) => {
      open.value = event;
    });
  }
</script>

<style lang="scss" scoped>
.negative-collapse {
  padding: 2rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:first-child) {
    border-top: 1px solid $color-border-secondary;
  }

  &.opened {
    & .negative-collapse__header {
      &::after {
        // content: "\e93c";
        // color: #E81313;
      }
    }

    & .negative-collapse__content {
      display: block;
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;

      &:hover {
        cursor: pointer;
      }

    &::after {
      margin-left: auto;
      font-family: $font-icon;
      // content: "\e93b";
    }

    &__icon {
      margin-right: 0.5rem;
      font-size: 2rem;
    }

    &__title {
      //
    }
  }

  & .negative-collapse__content {
    padding: 1rem 2rem;

    @include media-breakpoint-down(md) {
      padding: 1rem 0;
    }
  }
}
</style>
