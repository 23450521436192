<template>
  <InfoCard :icon-name="icon"
            icon-color="#f3792b"
            :title="chartTitle"
            card-type="graph"
            :info-text="infoText">
    <template #content>
      <div>
        <slot>
          <LineChart :data="props.data"
                     :legend="props.legend" />
        </slot>
      </div>
    </template>
    <template #graph-table>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">
              {{ t('chartCard.date') }}
            </th>
            <th scope="col"
                style="text-align: right;">
              {{ t('chartCard.value') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tableData"
              :key="item.key">
            <td>{{ item.key }}</td>
            <td style="text-align: right;">
              {{ formatMoney(item.value) }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </InfoCard>
</template>

<script setup lang="ts">
  import { ref, computed } from "vue";
  import { formatMoney, t } from "@/logic";

  const props = defineProps(["chartTitle", "icon", "infoText", "data", "legend"]);

  const tableData = computed(() => {
    let tableData = [];
    if (props.data.labels) {
      props.data.labels.forEach((l, index) => {
        tableData.push({
          key: l,
          value: props.data.datasets[0].data[index],
        });
      });
    }
    return tableData;
  });
</script>

<style scoped>
</style>
