<template>
  <div class="page-kim">
    <div class="container-fluid p-initial">
      <div class="row">
        <div class="col-12 col-xxl-8">
          <PageCard :title="t('kim.title')"
                    icon="kim">
            <template #content>
              <div class="description">
                {{ t('kim.description') }}
              </div>

              <div class="selectPublications m-5">
                <p class="orderDetails">
                  {{ t('kim.selectPublications.orderDetails') }}
                </p>

                <div v-for="item in im['electronic']"
                     :key="item.id"
                     class="order">
                  <div class="co-checkbox-tertiary">
                    <Checkbox v-model="selected['i' + item.id]"
                              input-id="leaders"
                              :binary="true"
                              @change="addSum(item)" />
                    <label :for="'i' + item.id">{{ item.value }}</label>
                  </div>
                  <p class="price">
                    {{ item.price }} Ft
                  </p>
                  <div class="separator" />
                </div>
                <div class="order">
                  <div class="sum">
                    <div class="icon sum" />
                    <span>
                      {{ t('kim.selectPublications.orders.sum') }}
                    </span>
                  </div>
                  <p class="price">
                    {{ sum }} Ft
                  </p>
                </div>
                <div class="confirmAndOrder">
                  <p class="content content--bold">
                    {{ t('kim.confirmAndOrder.taxExcluded') }}
                  </p>
                  <p class="content content--bold">
                    {{ t('kim.confirmAndOrder.forwardingType.title') }} <span>{{ t('kim.confirmAndOrder.forwardingType.value') }}</span>
                  </p>
                  <p class="content content--bold">
                    {{ t('kim.confirmAndOrder.paymentType.title') }} <span>{{ t('kim.confirmAndOrder.paymentType.value') }}</span>
                  </p>
                  <p class="content">
                    {{ t('kim.confirmAndOrder.description-3') }}
                  </p>

                  <div v-if="items.length > 0"
                       class="mt-5">
                    <div class="button button--primary"
                         @click="order">
                      {{ t('kim.confirmAndOrder.order') }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </PageCard>
        </div>
        <div class="col-12 col-xxl-4">
          <DidYouKnow page-name="kim"
                      :sticky="true"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, computed } from "vue";
  import { http, t, route, toast } from "@/logic";

  const activeTab = ref(0);
  const selected = ref({});
  const items = ref([]);
  const im = ref({});
  const sum = computed(() => {
    let sum = 0;

    items.value.forEach((i) => (sum += i.price));

    return sum;
  });

  function addSum(item) {
    const index = items.value.indexOf(item);
    if (index > -1) {
      items.value.splice(index, 1);
    } else {
      items.value.push(item);
    }
  }

  async function order() {
    const list = items.value.map(x => x.value).join(", ");
    const data = await http.get("/api/report/" + route.params.id + "/im/order/" + list);

    toast(data.notification, "success");
    activeTab.value = 0;
    items.value = [];
  }

  onMounted(async () => {
    await http.fetch(im, "/api/report/" + route.params.id + "/im/load");		
  });
</script>

<style lang="scss" scoped>
.page-kim {
  & .description {
    margin: 4rem 2rem 1rem;
    color: $color-font-primary;
    font-weight: 500;
    font-size: 1.4rem;
  }

  & .selectPublications {
    & .select-format,
    & .orderDetails {
      margin: 2rem 0;
      color: $color-font-primary;
      font-weight: 600;
      font-size: 1.4rem;
    }

    & .order {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;

      & .separator {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        height: 1px;
        background-color: $color-border-primary;
        transform: translateX(-50%);
      }

      & .price {
        color: $color-green;
        font-weight: 500;
      }

      & .sum {
        display: flex;
        align-items: center;

        & img {
          height: 1.5rem;
          margin-right: 0.75rem;
          margin-left: 1rem;
        }

        & span {
          color: $color-green;
          font-weight: 500;
          font-size: 1.4rem;
        }
      }
    }

    & .tax {
      & p {
        font-weight: 500;
        font-size: 1.2rem;
      }
    }
  }

  & .billingDetails {
    & .input-title {
      margin: 4rem 0 2rem;
      font-weight: 600;
      font-size: 1.4rem;

      &:first-child {
        margin-top: 2rem;
      }
    }
  }

  & .confirmAndOrder {
    & .title {
      margin: 2rem 0 3rem;
      font-weight: 500;
      font-size: 2rem;
      text-align: center;
    }

    & .value {
      font-weight: 500;
    }

    & .sum {
      display: flex;
      justify-content: space-between;

      & .sum-title {
        font-weight: 500;
        font-size: 1.8rem;
      }

      & .amount {
        color: $color-green;
        font-weight: 500;
        font-size: 1.8rem;
      }
    }

    & .content {
      margin: 2rem 0;
      font-size: 1.4rem;

      &--bold {
        font-weight: 500;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
