<template>
  <div v-if="!loading || isEmpty"
       class="page-sector">
    <div class="container-fluid p-initial">
      <div class="row">
        <div class="col-12">
          <PageCard :title="t('comparison.title')"
                    :download="comparison"
                    :download-on-click="downloadUrl"
                    icon="benefit-stat"
                    class="pb-5">
            <template #content>
              <div v-if="!isEmpty && data">
                <Table :columns="data.table.columns" 
                       :items="data.table.items" 
                       :meta="data.table.meta" />
                <div class="collapses">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.classification.title')"
                                 :info-text="t('comparison.classification.infoText')" 
                                 :data="data.charts.classification.data"
                                 legend="true">
                        <BarChart :data="data.charts.classification.data" />
                      </ChartCard>
                    </div>

                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.creditability.title')"
                                 :info-text="t('comparison.creditability.infoText')" 
                                 :data="data.charts.creditability.data"
                                 legend="true">
                        <BarChart :data="data.charts.creditability.data" />
                      </ChartCard>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.salesNetIncome.title')"
                                 :info-text="t('comparison.salesNetIncome.infoText')"
                                 :data="data.charts.salesNetIncome.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.activityResult.title')"
                                 :info-text="t('comparison.activityResult.infoText')"
                                 :data="data.charts.activityResult.data"
                                 legend="true" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.beforeTaxProfit.title')"
                                 :info-text="t('comparison.beforeTaxProfit.infoText')"
                                 :data="data.charts.beforeTaxProfit.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.afterTaxProfit.title')"
                                 :info-text="t('comparison.afterTaxProfit.infoText')"
                                 :data="data.charts.afterTaxProfit.data"
                                 legend="true" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.equity.title')"
                                 :info-text="t('comparison.equity.infoText')"
                                 :data="data.charts.equity.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.registeredCapital.title')"
                                 :info-text="t('comparison.registeredCapital.infoText')"
                                 :data="data.charts.registeredCapital.data"
                                 legend="true" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.materialExpenditure.title')"
                                 :info-text="t('comparison.materialExpenditure.infoText')"
                                 :data="data.charts.materialExpenditure.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.humanExpenditure.title')"
                                 :info-text="t('comparison.humanExpenditure.infoText')"
                                 :data="data.charts.humanExpenditure.data"
                                 legend="true" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.ebitda.title')"
                                 :info-text="t('comparison.ebitda.infoText')"
                                 :data="data.charts.ebitda.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.liquidityRate.title')"
                                 :info-text="t('comparison.liquidityRate.infoText')"
                                 :data="data.charts.liquidityRate.data"
                                 legend="true" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.capitalAdequacy.title')"
                                 :info-text="t('comparison.capitalAdequacy.infoText')"
                                 :data="data.charts.capitalAdequacy.data"
                                 legend="true" />
                    </div>

                    <div class="col-12 col-md-6">
                      <ChartCard :title="t('comparison.indebtednessRatio.title')"
                                 :info-text="t('comparison.indebtednessRatio.infoText')"
                                 :data="data.charts.indebtednessRatio.data"
                                 legend="true" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else
                   class="p-5">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h3 class="mb-3">
                      {{ t('comparison.empty1') }}
                    </h3>
                    <p class="mb-3">
                      {{ t('comparison.empty2') }}
                    </p>
                    <h4 class="mb-5">
                      {{ t('comparison.empty3') }}
                    </h4>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img class="img-fluid"
                         src="/assets/img/comparison.png">
                    <p class="text-center mt-2">
                      {{ t('comparison.imageDescription') }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </PageCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed } from "vue";
  import { http, t, comparison, init, route, router } from "@/logic";

  init();

  const loading = ref(false);
  const data = ref(null);

  const isEmpty = computed(() => {
    return !route.query.companies && comparison.companies.length < 2;
  });

  const companies = computed(() => {
    return route.query.companies
      ? (route.query.companies as string).split(",")
      : comparison.getIds();
  });

  const load = async () => {
    if (!isEmpty.value) {
      const res = await http.fetchWithLoading(data, loading, `/api/comparison/?companies=${companies.value}`);
    }
  };

  const downloadUrl = async () => {
    await http.blob(`/api/comparison/print/${companies.value}`);
  };

  onMounted(async () => {
    let companies = [];
    if (!route.query.companies) {
      router.replace(route.path + "?companies=" + comparison.getIds());
    }
    await load();
  });
</script>


<style lang="scss" scoped>
.collapses {
  margin: 2rem 2rem;
}

.img-fluid {
  border: 1px solid $color-border-primary;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}
</style>
