<template>
  <div class="connection-graph" />
</template>

<script setup lang="ts">
  import { ref, watch, onMounted, onUnmounted, nextTick } from "vue";
  import { build, onResize, setHeight } from "./ConnectionGraphBuilder";

  const props = defineProps(["graph", "iconFontFace"]);

  watch(() => props.graph, function () {
    document.getElementsByClassName("svg-graph")[0].remove();
    build(props.graph, props.iconFontFace);
  });

  onMounted(async () => {
    document.querySelector<HTMLElement>("#body").style.touchAction = "pan-y";

    await nextTick();
    setHeight();
    build(props.graph, props.iconFontFace);

    window.addEventListener("resize", () => {
      onResize(false);
    });
  });

  onUnmounted(() => {
    document.querySelector<HTMLElement>("#body").style.touchAction = "manipulation";
  });
</script>

<style lang="scss">
.connection-graph {
  & .info {
    font-size: 12px;
  }

  & .hide {
    display: none;
  }

  & svg a:hover {
    text-decoration: none;
  }

  & rect {
    cursor: grab;
  }
}

.connection-graph .controls rect {
  cursor: move;
  fill: #f7f7f7;
}

.connection-graph svg {
  background-color: #fff;
}
</style>
