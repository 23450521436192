<template>
  <div class="connection-list">
    <div v-if="limit"
         class="alert-bar">
      {{ t('connections.limit') }}
    </div>
    <div class="container-fluid p-initial">
      <div class="row">
        <div v-if="!loading"
             class="col-12">
          <ConnectionGraph :graph="graphData"
                           :icon-font-face="iconFontFace" />
        </div>
      </div>
    </div>

    <div v-if="!show"
         class="icon-container"
         @click="show = true">
      <img src="/assets/icons/connection-list/table.svg"
           class="floating-icon"
           alt="Icon">
    </div>

    <div class="connections-card"
         :class="{ 'active': show }">
      <div class="close-wrapper"
           @click="show = false">
        <div class="icon company-deleted blue" />
      </div>
      <TabView>
        <TabPanel :header="t('connections.tabs.settings')">
          <div class="controls">
            <div>
              <div>{{ t('connections.card.levels') }}</div>
              <input v-model="settings.levels"
                     type="number"
                     min="1"
                     max="2"
                     @change="load">
            </div>
            <div class="d-flex align-items-center"
                 style="overflow: hidden;">
              <div>
                <div class="m-1">
                  <Checkbox v-model="settings.owners"
                            input-id="owners"
                            :binary="true"
                            @change="load" />
                  <label for="owners">{{ t('connections.card.checkboxes.checkbox-1') }}</label>			
                </div>
                <div class="m-1">
                  <Checkbox v-model="settings.leaders"
                            input-id="leaders"
                            :binary="true"
                            @change="load" />
                  <label for="leaders">{{ t('connections.card.checkboxes.checkbox-2') }}</label>
                </div>
              </div>
              <div>
                <div class="m-1">
                  <Checkbox v-model="settings.onlyActive"
                            input-id="onlyActive"
                            :binary="true"
                            @change="load" />
                  <label for="onlyActive">{{ t('connections.card.checkboxes.checkbox-3') }}</label>
                </div>
                <div class="m-1">
                  <Checkbox v-model="settings.withoutConnections"
                            input-id="withoutConnections"
                            :binary="true"
                            @change="load" />
                  <label for="withoutConnections">{{ t('connections.card.checkboxes.checkbox-4') }}</label>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel :header="t('connections.tabs.notations')">
          <div>
            <div class="mt-2">
              {{ t('connections.card.description-1') }}
            </div>
            <div class="mt-2">
              {{ t('connections.card.description-2') }}
            </div>
          </div>
          <div class="notations">
            <div class="notation-column">
              <div class="notation-row">
                <img class="icon"
                     src="/assets/icons/connection-list/working.svg"
                     alt="Icon">
                <p class="text">
                  {{ t('connections.card.notations.working') }}
                </p>
              </div>
              <div class="notation-row">
                <img class="icon"
                     src="/assets/icons/connection-list/under-procedure.svg"
                     alt="Icon">
                <p class="text">
                  {{ t('connections.card.notations.underProcedure') }}
                </p>
              </div>
              <div class="notation-row">
                <img class="icon"
                     src="/assets/icons/connection-list/ceased.svg"
                     alt="Icon">
                <p class="text">
                  {{ t('connections.card.notations.ceased') }}
                </p>
              </div>
              <div class="notation-row">
                <img class="icon"
                     src="/assets/icons/connection-list/connection-network.svg"
                     alt="Icon">
                <p class="text">
                  {{ t('connections.card.notations.connectionNetwork') }}
                </p>
              </div>
              <div class="notation-row">
                <img class="icon"
                     src="/assets/icons/connection-list/headquarter.svg"
                     alt="Icon">
                <p class="text">
                  {{ t('connections.card.notations.headquarter') }}
                </p>
              </div>
              <div class="notation-row">
                <img class="icon"
                     src="/assets/icons/connection-list/inactive-connection.svg"
                     alt="Icon">
                <p class="text">
                  {{ t('connections.card.notations.inactiveConnection') }}
                </p>
              </div>
              <div class="notation-row">
                <img class="icon"
                     src="/assets/icons/connection-list/predecessed-connection.svg"
                     alt="Icon">
                <p class="text">
                  {{ t('connections.card.notations.predecessedConnection') }}
                </p>
              </div>
            </div>
            <div class="notation-column">
              <div class="notation-row">
                <div class="exception-icon gray">
                  V
                </div>
                <p class="text">
                  {{ t('connections.card.notations.leader') }}
                </p>
              </div>
              <div class="notation-row">
                <div class="exception-icon gray">
                  T
                </div>
                <p class="text">
                  {{ t('connections.card.notations.owner') }}
                </p>
              </div>
              <div class="notation-row">
                <div class="exception-icon gray">
                  VT
                </div>
                <p class="text">
                  {{ t('connections.card.notations.leaderOwner') }}
                </p>
              </div>
              <div class="notation-row">
                <div class="exception-icon gray">
                  É
                </div>
                <p class="text">
                  {{ t('connections.card.notations.interest') }}
                </p>
              </div>
              <div class="notation-row">
                <div class="exception-icon gray">
                  J
                </div>
                <p class="text">
                  {{ t('connections.card.notations.pre') }}
                </p>
              </div>
            </div>
          </div>
          <div class="description">
            {{ t('connections.card.detailsDescription') }}
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, watch } from "vue";
  import { http, t, route, init } from "@/logic";

  init();

  const loading = ref(false);
  const iconFontFace = ref(null);
  const graphData = ref({});
  const settings = ref({
    levels: 1,
    owners: true,
    onlyActive: true,
    leaders: true,
    withoutConnections: true,
  });
  const show = ref(false);
  const showDetails = ref(false);
  const limit = ref(false);

  async function load() {
    let query =
      `?levels=${settings.value.levels}&owners=${settings.value.owners}&onlyActive=${settings.value.onlyActive}&` +
      `leaders=${settings.value.leaders}&withoutConnections=${settings.value.withoutConnections}`;

    const data = await http.get("/api/report/" + route.params.id + "/connections" + query);
    graphData.value = {
      nodes: data.nodes,
      links: data.links,
    };
    limit.value = data.prefill.limit;

    return data;
  }

  onMounted(async () => {
    loading.value = true;

    const fontFace = await http.get("/api/report/icon-font-face");
    iconFontFace.value = fontFace.iconFontFace;

    const data = await load();
    settings.value = data.prefill;
    loading.value = false;

    if (window.innerWidth > 920) {
      show.value = true;
    }

    loading.value = false;
  });
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  & .levels {
    display: flex;
    align-items: center;

    & label {
      margin-right: 0.5rem;
      font-weight: 500;
      font-size: 1.2rem;
    }

    & input {
      max-width: 3rem;
      font-size: 1.2rem;
    }
  }
}

.navigation {
  margin: 2rem 0 0;
  color: $color-blue;
  font-weight: 500;
  font-size: 1.6rem;
}

.description {
  margin: 1rem 0;
  color: $color-font-primary;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2;

  & .link {
    color: $color-blue;
    text-decoration: none;

      cursor: pointer;
  }
}

.notations {
  display: flex;

  & .notation-column {
    min-width: 20rem;

    @include media-breakpoint-down(md) {
      min-width: 17.5rem;
    }

    & .notation-row {
      display: flex;
      align-items: center;
      margin: 1rem 0;

      & .icon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
      }

      & .exception-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        color: white;
        font-weight: 500;
        font-size: 1.1rem;
        font-family: $font-primary;
        border-radius: 50%;

        &.gray {
          background-color: $color-gray;
        }

        &.orange {
          background-color: $color-orange;
        }

        &.dark-orange {
          background-color: $color-orange-dark;
        }

        &.red {
          background-color: $color-red;
        }

        &.blue {
          background-color: $color-blue;
        }
      }

      & .text {
        color: $color-font-primary;
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }
}

.icon-container {
  position: fixed;
  top: 18rem;
  right: 2rem;
  z-index: $z-index-widget-controller;
  width: 5rem;
  height: 5rem;
  margin-left: auto;
  padding: 1rem;
  background-color: white;
  border: 1px solid $color-blue;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  @include media-breakpoint-down(lg) {
    top: 20rem;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;

  &.blue {
    color: $color-blue;
    text-align: center;
  }

  &::before {
    margin: auto;
  }
}

.connections-card {
  position: fixed;
  top: 13.5rem;
  right: 2rem;
  z-index: $z-index-widget-controller;
  display: none;
  width: 50rem;
  height: fit-content;
  overflow: hidden;
  font-size: 1.4rem;
  background-color: white;
  border-radius: $border-radius-card;
  box-shadow: $box-shadow-card;

  @include media-breakpoint-down(lg) {
    top: 19rem;
  }

  @include media-breakpoint-down(md) {
    position: absolute;
    right: initial;
    left: 50%;
    width: calc(100% - 4rem);
    min-width: initial;
    max-width: initial;
    transform: translateX(-50%);
  }

  &.active {
    right: 0;
    bottom: 9rem;
    display: flex;
  }

  & .close-wrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100000;

      &:hover {
        cursor: pointer;
      }

    & .icon {
      width: 3rem;
      height: 3rem;
      font-size: 3rem;
    }
  }

  & .card {
    //max-width: 50rem;
    height: calc(75vh);
    overflow-y: scroll;
  }

  & .description {
    font-weight: 700;
    font-size: 1rem;
  }
}

.alert-bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 0.7rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  background-color: #f3792b;
  opacity: 65% !important;
}
</style>
