<template>
  <div class="mb-3">
    <div class="d-inline-flex flex-nowrap"
         @click="onOpen">
      <div class="d-flex align-items-start flex-column flex-md-row justify-content-between justify-content-md-start w-100">
        <div class="d-flex">
          <div class="d-flex align-items-center overflow-hidden w-mobile-100">
            <div class="negative-collapse__content__icon icon"
                 :class="item.keyIcon.key"
                 :style="{ 'color': item.keyIcon.color }" />
            <div class="negative-collapse__content__title"
                 :style="{color: item.color}">
              <span>{{ item.key }}</span>
            </div>
          </div>
          <div v-if="!disabled"
               class="negative-collapse__content__details"
               :class="{ 'opened': open }" />
        </div>
        <div class="negative-collapse__content__date">
          <div :style="{color: item.color}">
            {{ item.value }}
          </div>
          <div v-if="item.valueIcon"
               :class="['icon', item.valueIcon.key, 'negative-collapse__content__date__to__icon' ]"
               :style="{color: item.valueIcon.color}" />
        </div>
      </div>
    </div>
    <div v-if="open"
         class="negative-collapse__content__inner-content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";

  const props = defineProps(["item", "disabled", "onOpenEvent"]);

  const emit = defineEmits<{
    (e: "open"): void;
  }>();

  const open = ref(false);
 
  async function onOpen(item) {
    if (!props.disabled) {
      open.value = !open.value;
      if (open.value) {
        emit("open");
      }

      if (props.onOpenEvent) {
        props.onOpenEvent(item);
      }
    }
  }
</script>

<style lang="scss" scoped>
.negative-collapse__content {
  &-container {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-only(xs) {
      flex-direction: column;
    }
  }

  &__icon {
    margin-right: 0.5rem;
    font-size: 2rem;
  }

  &__title {
    width: 25rem;
    overflow: hidden;
    font-size: 1.4rem;
    white-space: nowrap;
    text-overflow: ellipsis;

    & span {
      @include media-breakpoint-only(xs) {
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &__date {
    display: flex;
    min-width: 18rem;
    margin-left: 1rem;
    font-size: 1.4rem;

    @include media-breakpoint-down(md) {
      min-width: initial;
    }

    &__from {
      //
    }

    &__to {
      &__date {
        &:not(:empty) {
          &::before {
            margin-right: 0.2rem;
            margin-left: 0.2rem;
            content: "-";
          }
        }
      }

      &__icon {
        margin-left: 0.5rem;
        font-weight: 900;
      }
    }
  }

  &__details {
    min-width: 1rem;
    font-size: 1.4rem;
    line-height: 1.2;

      &:hover {
        cursor: pointer;
      }

    @include media-breakpoint-down(md) {
      margin-right: 1rem;
    }

    &::after {
      display: block;
      font-family: $font-icon;
      content: "\e947";
    }

    &.opened {
      &::after {
        content: "\e948";
      }
    }
  }

  &__inner-content {
    padding: 1rem 0 0;

    &-container {
      display: flex;
      margin: 1rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-only(xs) {
        flex-direction: column;
        margin-left: 3rem;
      }
    }

    &.opened {
      display: block;
    }

    &__title {
      width: 26rem;
      min-width: 18rem;
      margin-left: 2.5rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1;

      @include media-breakpoint-only(xs) {
        margin-left: 0;
      }
    }

    &__data {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.1;
    }
  }
}

@media screen and (max-width: 767px) {
  .negative-collapse__content__date {
    margin-left: 2.5rem;
  }
}
</style>
