import { RouteRecordRaw } from "vue-router";
import CompanyData from "../pages/report/CompanyData.vue";
import ReportLayout from "../layouts/ReportLayout.vue";
import FinancialReportSummary from "../pages/report/FinancialReportSummary.vue";
import FinancialReport from "../pages/report/FinancialReport.vue";
import Summary from "../pages/report/Summary.vue";
import CommonRatings from "../pages/report/CommonRatings.vue";
import Timeline from "../pages/report/Timeline.vue";
import Marketing from "../pages/report/Marketing.vue";
import Login from "../pages/Login.vue";
import SendMessage from "../pages/SendMessage.vue";
import ProfileEdit from "../pages/ProfileEdit.vue";
import ProfileSettings from "../pages/ProfileSettings.vue";
import Home from "../pages/Home.vue";
import InternationalSearch from "../pages/international/InternationalSearch.vue";
import InternationalResults from "../pages/international/InternationalResults.vue";
import InternationalDownload from "../pages/international/InternationalDownload.vue";
import FinalOwner from "../pages/report/FinalOwner.vue";
import IM from "../pages/report/IM.vue";
import InfoList from "../pages/report/InfoList.vue";
import InfoDetails from "../pages/report/InfoDetails.vue";
import ConnectionList from "../pages/report/ConnectionList.vue";
import Sector from "../pages/report/Sector.vue";
import Connections from "../pages/report/Connections.vue";
import MainLayout from "../layouts/MainLayout.vue";
import Monitoring from "../pages/monitoring/Monitoring.vue";
import DetailedSearch from "../pages/search/DetailedSearch.vue";
import DetailedSearchResults from "../pages/search/DetailedSearchResults.vue";
import Favorites from "../pages/Favorites.vue";
import Comparison from "../pages/Comparison.vue";
import ComparisonPrint from "../pages/print/ComparisonPrint.vue";
import SearchResults from "../pages/search/SearchResults.vue";
import Error from "../pages/Error.vue";
import MonitoringAlert from "../pages/monitoring/MonitoringAlert.vue";
import ReportPrint from "../pages/print/Report.vue";
import ExtractPrint from "../pages/print/Extract.vue";
import InfoDetailsPrint from "../pages/print/InfoDetailsPrint.vue";

export default [
  {
    path: "/login",
    name: "login",
    component: Login,
    props: { type: "login" },
  },
  {
    path: "/company-report/demo-login/:email/:password",
    redirect: "/demo/:email/:password",
  },
  {
    path: "/company-report/show/:id/summary",
    redirect: to => "/report/" + to.params.id + "/summary",
  },
  {
    path: "/company-report/show/:id/company-data",
    redirect: to => "/report/" + to.params.id + "/company-data",
  },
  {
    path: "/company-report/show/:id/negative-info",
    redirect: to => "/report/" + to.params.id + "/negative-info",
  },
  {
    path: "/company-report/show/:id/positive-info",
    redirect: to => "/report/" + to.params.id + "/positive-info",
  },
  {
    path: "/company-report/show/:id/financial-report-summary",
    redirect: to => "/report/" + to.params.id + "/financial-report-summary",
  },
  {
    path: "/demo/:email/:password",
    name: "demo-login",
    component: Login,
    props: { type: "demo" },
  },
  {
    path: "/report/:id/print",
    name: "print",
    component: ReportPrint,
  },
  {
    path: "/report/:id/print/extract",
    name: "print-extract",
    component: ExtractPrint,
  },
  {
    path: "/print/comparison",
    name: "print-comparison",
    component: ComparisonPrint,
  },
  {
    path: "/report/print/info/:id/:itemId",
    name: "print-info",
    component: InfoDetailsPrint,
  },
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      {
        path: "/marketing/search/:id?",
        name: "marketing.search",
        component: DetailedSearch,
        props: { type: "marketing" },
        meta: {
          claim: "MarketingAdatok",
        },
      },
      {
        path: "/marketing/search/:id/results",
        name: "marketing.search.results",
        component: DetailedSearchResults,
        props: { type: "marketing" },
        meta: {
          claim: "MarketingAdatok",
        },
      },
      {
        path: "/advanced/search/:id?",
        name: "advanced.search",
        component: DetailedSearch,
        props: { type: "advanced" },
        meta: {
          claim: "Reszleteskereso",
        },
      },
      {
        path: "/advanced/search/:id/results",
        name: "advanced.search.results",
        component: DetailedSearchResults,
        props: { type: "advanced" },
        meta: {
          claim: "Reszleteskereso",
        },
      },
      {
        path: "/quick/search",
        name: "quick.search",
        component: SearchResults,
      },
      {
        path: "/geo/search",
        name: "geo.search",
        component: SearchResults,
      },
      {
        path: "/monitoring",
        name: "monitoring",
        component: Monitoring,
        meta: {
          claim: "Monitoring",
        },
      },
      {
        path: "/favorites",
        name: "favorites",
        component: Favorites,
        meta: {
          claim: "Kedvencek",
        },
      },
      {
        path: "/international",
        name: "international.search",
        component: InternationalSearch,
        meta: {
          claim: "Nemzetkozi",
        },
      },
      {
        path: "/international/results",
        name: "international.results",
        component: InternationalResults,
        meta: {
          claim: "Nemzetkozi",
        },
      },
      {
        path: "/international/download/:id",
        name: "international.download",
        component: InternationalDownload,
        meta: {
          claim: "Nemzetkozi",
        },
      },
      {
        path: "/comparison",
        name: "comparison",
        component: Comparison,
        meta: {
          claim: "Osszehasonlitas",
        },
      },
      {
        path: "/profile-edit",
        name: "profile-edit",
        component: ProfileEdit,
      },
      {
        path: "/profile-settings",
        name: "profile-settings",
        component: ProfileSettings,
      },
      {
        path: "/send-message",
        name: "send-message",
        component: SendMessage,
      },
      {
        path: "/error/:type/:name?",
        name: "error",
        component: Error,
      },
      {
        path: "/monitoring/alert/:id",
        name: "monitoring-alert",
        component: MonitoringAlert,
      },
    ],
  },
  {
    path: "/report/:id",
    name: "report",
    component: ReportLayout,
    children: [
      {
        path: "/:type/:id/error/:name?",
        name: "report-error",
        component: Error,
      },
      {
        path: "summary",
        name: "report.summary",
        component: Summary,
      },
      {
        path: "company-data",
        name: "report.company-data",
        component: CompanyData,
        meta: {
          claim: "Cegriport",
        },
      },
      {
        path: "financial-report-summary",
        name: "report.financial-report-summary",
        component: FinancialReportSummary,
        meta: {
          claim: "GazdasagiAdatok",
        },
      },
      {
        path: "financial-report",
        name: "report.financial-report",
        component: FinancialReport,
        meta: {
          claim: "Beszamolok",
        },
      },
      {
        path: "common-ratings",
        name: "report.common-ratings",
        component: CommonRatings,
        meta: {
          claim: "Mutatok",
        },
      },
      {
        path: "connection-list",
        name: "report.connection-list",
        component: ConnectionList,
        meta: {
          claim: "KapcsolatiHalo",
        },
      },
      {
        path: "connection-list/:section/:article",
        name: "report.connection-list-person",
        component: ConnectionList,
        meta: {
          claim: "KapcsolatiHalo",
        },
      },
      {
        path: "timeline",
        name: "report.timeline",
        component: Timeline,
        meta: {
          claim: "Idovonal",
        },
      },
      {
        path: "marketing",
        name: "report.marketing",
        component: Marketing,
        meta: {
          claim: "MarketingAdatok",
        },
      },
      {
        path: "final-owner",
        name: "report.final-owner",
        component: FinalOwner,
        meta: {
          claim: "TenylegesTulajdonos",
        },
      },
      {
        path: "im",
        name: "report.im",
        component: IM,
      },
      {
        path: "negative-info",
        name: "report.negative-info",
        component: InfoList,
        props: {
          type: "negative",
        },
        meta: {
          claim: "NegativInfo",
        },
      },
      {
        path: "negative-info/details/:itemId",
        name: "report.negative-info.details",
        component: InfoDetails,
        props: {
          type: "negative",
        },
        meta: {
          claim: "NegativInfo",
        },
      },
      {
        path: "positive-info",
        name: "report.positive-info",
        component: InfoList,
        props: {
          type: "positive",
        },
        meta: {
          claim: "PozitivInfo",
        },
      },
      {
        path: "positive-info/details/:itemId",
        name: "report.positive-info.details",
        component: InfoDetails,
        props: {
          type: "positive",
        },
        meta: {
          claim: "PozitivInfo",
        },
      },
      {
        path: "sector",
        name: "report.sector",
        component: Sector,
        meta: {
          claim: "AgazatiMutatok",
        },
      },
      {
        path: "connections",
        name: "report.connections",
        component: Connections,
        meta: {
          claim: "KapcsolatiHalo",
        },
      },
    ],
  },
] as RouteRecordRaw[];
