<template>
  <Chart v-if="data"
         type="bar"
         :data="data"
         :options="options" />
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { formatMoney } from "@/logic";

  const props = defineProps(["data"]);

  const options = ref({
    plugins: {

      legend: {
        display: false,
      }
    },
    datasets: {
      line: {
        fill: false,
        borderColor: "#f2792b",
        pointBackgroundColor: "#ffffff",
        lineTension: 0
      }
    },
    maintainAspectRatio: false,
    aspectRatio: 2.5,
  });
</script>
