export default [
  {
    "key": "Summary",
    "show": true,
    "justInCompany": false
  },
  {
    "key": "CompanyData",
    "show": false,
    "claim": "Cegriport",
    "justInCompany": true
  },
  {
    "key": "FinancialReportSummary",
    "show": false,
    "claim": "GazdasagiAdatok",
    "justInCompany": true
  },
  {
    "key": "FinancialReport",
    "show": false,
    "claim": "Beszamolok",
    "justInCompany": true
  },
  {
    "key": "CommonRatings",
    "show": false,
    "claim": "Mutatok",
    "justInCompany": true
  },
  {
    "key": "NegativeInfo",
    "show": false,
    "claim": "NegativInfo",
    "justInCompany": false
  },
  {
    "key": "PositiveInfo",
    "show": false,
    "claim": "PozitivInfo",
    "justInCompany": true
  },
  {
    "key": "ConnectionList",
    "show": false,
    "claim": "KapcsolatiHalo",
    "justInCompany": true
  },
  {
    "key": "Timeline",
    "show": false,
    "claim": "Idovonal",
    "justInCompany": true
  },
  {
    "key": "Marketing",
    "show": false,
    "claim": "MarketingAdatok",
    "justInCompany": true
  },
  {
    "key": "Sector",
    "show": false,
    "claim": "AgazatiMutatok",
    "justInCompany": true
  },
  {
    "key": "FinalOwner",
    "show": false,
    "claim": "TenylegesTulajdonos",
    "justInCompany": true
  }
];