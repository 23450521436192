<template>
  <div class="rating-component-container">
    <div v-if="score === null">
      <img class="rating-component-bg null-image"
           src="/assets/charts/COR-inactive.svg">
    </div>
    <div v-else>
      <img class="rating-component-bg"
           src="/assets/charts/COR-base.svg">
    </div>
    <div v-if="score !== null"
         ref="chartRef"
         class="rating-component-chart-ref" />
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed } from "vue";
  import { useRoute } from "vue-router";
  import * as d3 from "d3";

  const props = defineProps<{
    score?: number,
    lite: boolean,
  }>();

  const route = useRoute();
  const fullRatings = ref([
    {
      from: 0,
      color: "#e81313",
    },
    {
      from: 16,
      color: "#f95e08",
    },
    {
      from: 34,
      color: "#ff9929",
    },
    {
      from: 51,
      color: "#fde43f",
    },
    {
      from: 67,
      color: "#d1e517",
    },
    {
      from: 85,
      color: "#21c100",
    },
  ]);

  const liteRatings = ref([
    {
      from: 0,
      color: "#e81313",
    },
    {
      from: 34,
      color: "#fde43f",
    },
    {
      from: 67,
      color: "#21c100",
    },
  ]);
  const chartRef = ref(null);

  const ratings = computed(() => {
    return props.lite ? liteRatings.value : fullRatings.value;
  });

  const roundedScore = computed(() => {
    if (props.score === 16) {
      return 17;
    }

    if (props.score === 33) {
      return 32;
    }

    if (props.score === 34) {
      return 35;
    }

    if (props.score === 50) {
      return 49;
    }

    if (props.score === 51) {
      return 52;
    }

    if (props.score === 66) {
      return 65;
    }

    if (props.score === 67) {
      return 68;
    }

    if (props.score === 84) {
      return 83;
    }

    if (props.score === 85) {
      return 86;
    }

    return props.score;
  });

  function drawRatingChart() {
    const zoom = Math.round((window.outerWidth / window.innerWidth) * 100);
    var size = 180;

    if (zoom < 50) {
      size = 360;
    } else if (zoom < 53 || window.outerWidth > 2500) {
      size = 240;
    }

    const arc = d3.arc().outerRadius(65).innerRadius(61);

    const pie = d3
      .pie()
      .startAngle(-1.25 * Math.PI - 0.02)
      .endAngle(0.25 * Math.PI + 0.02)
      .padAngle(0.04)
      .sort(null)
      .value(1);

    const svg = d3
      .select(chartRef.value)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `-10 0 ${size} 180`)
      .append("g")
      .attr("transform", "translate(90,90)");

    const g = svg
      .selectAll(".arc")
      .data(pie(ratings.value))
      .enter()
      .append("g");

    g.append("path")
      .attr("d", arc)
      .style("fill", (data) => data.data.color);


    if (route.path.includes("print")) {
      const rot = d3.interpolate(0, 269)(roundedScore.value / 100);
      const g2 = svg.append("g").attr("transform", `rotate(${rot})translate(-50, -50)`);
      const currentSelected = ratings.value.reduce((acc, from, i) => (from.from <= roundedScore.value ? i : acc), -1);
      const a = d3.arc()
        .outerRadius((_, i) => (i === currentSelected ? 85 : 65))
        .innerRadius(61);

      g.select("path")
        .attr("d", a)
        .style("fill", (data) => data.data.color);

      g2.append("path")
        .attr(
          "d",
          "M44.6 56.5c.3.2.5.4.7.6l39.3 28.4-27.4-39.9c-.1-.2-.3-.5-.5-.7l-.1-.1c-.2-.2-.4-.5-.6-.7-3.3-3.3-8.6-3.4-12-.2-3.3 3.3-3.4 8.6-.2 12 .4.2.6.4.8.6z"
        )
        .style("fill", "#535964");
    } else {
      const g2 = svg.append("g").attr("transform", "translate(-50, -50)");
      const timer = d3.timer((elapsed) => {
        const duration = (roundedScore.value / 100) * 600;
        const t = Math.min(1, elapsed / duration);
        const currentScore = t * roundedScore.value;
        const rotation = d3.interpolate(0, 269)(currentScore / 100);
        g2.attr("transform", `rotate(${rotation})translate(-50, -50)`);

        const currentSelected = ratings.value.reduce((acc, from, i) => (from.from <= currentScore ? i : acc), -1);

        const arc = d3
          .arc()
          .outerRadius((_, i) => (i === currentSelected ? 85 : 65))
          .innerRadius(61);

        g.select("path")
          .attr("d", arc)
          .style("fill", (data) => data.data.color);

        if (elapsed > duration) {
          timer.stop();
        }
      }, 100);

      g2.append("path")
        .attr(
          "d",
          "M44.6 56.5c.3.2.5.4.7.6l39.3 28.4-27.4-39.9c-.1-.2-.3-.5-.5-.7l-.1-.1c-.2-.2-.4-.5-.6-.7-3.3-3.3-8.6-3.4-12-.2-3.3 3.3-3.4 8.6-.2 12 .4.2.6.4.8.6z"
        )
        .style("fill", "#535964");
    }
  }

  onMounted(() => {
    drawRatingChart();
  });
</script>

<style lang="scss" scoped>
  .rating-component-container {
    position: relative;

    @include media-breakpoint-up(xl) {
      height: 200px;
      max-height: 200px;
    }

    @include media-breakpoint-down(xl) {
      height: 184px;
      max-height: 184px;
    }
  }

  .rating-component-bg {
    display: block;

    @include media-breakpoint-up(xl) {
      height: 200px;
      max-height: 200px;
    }

    @include media-breakpoint-down(xl) {
      height: 184px;
      max-height: 184px;
    }
  }

  .rating-component-chart-ref {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @include media-breakpoint-up(xl) {
      height: 200px;
      max-height: 200px;
    }

    @include media-breakpoint-down(xl) {
      height: 184px;
      max-height: 184px;
    }
  }

  .null-image {
    @include media-breakpoint-up(xl) {
      height: 200px;
      max-height: 200px;
    }

    @include media-breakpoint-down(xl) {
      height: 184px;
      max-height: 184px;
    }
  }
</style>
