import { User } from "../logic/User";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";

export const vueRouter = createRouter({
  routes: routes,
  history: createWebHistory()
});

export const setRouter = (user: User) => {
  vueRouter.beforeEach((to, from, next) => {
    const token = user?.token;

    if (to.name !== "login" && !token)
      if (to.name !== "demo-login" && to.name !== "demo-login-new")
        next({
          name: "login",
          query: {
            redirect: to.fullPath,
          },
        });
      else next();
    else {
      const claims = (user?.claims || "").split(",");
      if (claims.includes("ReszletesKereso") || claims.includes("MarketingAdatok")) {
        claims.push("Kedvencek");
      }
      if (to.meta.claim && !claims.includes(to.meta.claim as string)) {
        if (to.path.includes("report"))
          next({
            path: "/module/" + to.params.id + "/error/" + to.meta.claim,
          });
        else
          next({
            path: "/error/module/" + to.meta.claim,
          });
      } else next();
    }
  });

  vueRouter.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('error loading dynamically imported module')) {
      window.location.href = to.fullPath
    }
  })
};