<template>
  <div>
    <Collapse v-for="(group, index) in filteredGroups(groups)"
              :key="'group-' + index"
              :title="group.title" 
              page="companyData"
              :opened="group.open">
      <div class="content">
        <div v-for="(item, itemIndex) in filteredItems(group.items)"
             :key="'item-' + itemIndex" 
             class="item data-section">
          <div v-for="(row, rowIndex) in item.rows"
               :key="'row-' + rowIndex">
            <div :class="['companyDataCollapse', { 'last': rowIndex === item.rows.length - 1 } ]">
              <div v-if="rowIndex !== 0"
                   class="counter" />
              <div v-if="item.counter && rowIndex === 0"
                   class="counter"
                   :class="['hl' + item.id]">
                {{ item.counter }}.
              </div>
              <div>
                <div class="keyIcon">
                  <div v-if="row.keyIcon"
                       class="no-print"
                       :class="['icon', row.keyIcon.key]"
                       :style="{ 'color': row.keyIcon.color }" />
                  <div v-if="row.key"
                       class="key">
                    {{ row.key }}
                  </div>
                </div>
              </div>
              <div v-if="!row.valueIcon"
                   class="valueIcon" />
              <div>
                <div class="valueIcon">
                  <div v-if="row.value"
                       class="value">
                    <a v-if="row.valueUrl"
                       :href="row.valueUrl"
                       class="link link--primary"
                       target="_blank">
                      {{ row.value }}
                    </a>
                    <span v-else
                          :style="row.style">
                      {{ row.value }}
                    </span>
                    <a v-if="row.valueIcon && row.valueIcon.url"
                       :href="row.valueIcon.url"
                       target="_blank"
                       class="d-inline-block">
                      <div v-if="row.valueIcon"
                           class="no-print"
                           :class="['icon', row.valueIcon.key]"
                           :style="{ 'color': row.valueIcon.color }" />
                    </a>
                  </div>
                  <div v-else-if="row.valueIcon"
                       :class="['icon', row.valueIcon.key]"
                       :style="{ 'color': row.valueIcon.color }" />
                </div>
                <div v-if="row.dates">
                  <Collapse title="További információ"
                            class="more-info no-print">
                    <div class="dateIcon-container">
                      <div v-for="(date, dateIndex) in row.dates"
                           :key="'date-' + dateIndex">
                        <div class="dateIcon d-flex align-items-center">
                          <div class="no-print"
                               :class="['icon', date.icon.key]"
                               :style="{ 'color': date.icon.color }" />
                          <div class="date d-flex flex-column">
                            <div class="">
                              {{ date.key }}
                            </div>
                            <div class="">
                              {{ date.value }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="group.hasMore"
             class="mt-2 has-more no-print"
             @click="onMoreData">
          {{ t('companyData.checkboxes.showAllData') }}
        </div>
      </div>
    </Collapse>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  import { useRoute } from "vue-router";
  import { http, t, route, init } from "@/logic";
  import { useEventBus } from "@vueuse/core";

  init();

  const emit = defineEmits<{
    (e: "moreData"): void;
  }>();
  const props = defineProps(["groups", "onlyValid"]);	
  const bus = useEventBus<boolean>("companyData/collapseToggle");
  const hl = ref(null);
  const hlSection = ref(null);

  function onMoreData() {
    emit("moreData");
  }

  function filteredItems(items) {
    if (props.onlyValid) {
      items = items.filter((i) => i.valid);
    }

    if (hl.value) {
      items = items.filter((i) => hl.value.includes(i.id));
    }

    return items;
  }

  function filteredGroups(groups) {
    if (props.onlyValid) {
      groups = groups.filter((i) => i.valid);
    }

    if (hlSection.value) {
      groups = groups.filter((i) => i.id == hlSection.value);
    }

    return groups;
  }

  onMounted(() => {
    if (route.query.hl) {
      hl.value = route.query.hl.toString().split(",");
      if (hl.value.length > 0) {
        hlSection.value = hl.value[0].toString().split("/")[0];
      }
      bus.emit(true);
    }
  });
</script>

<style scoped lang="scss">
.companyDataCollapse {
  display: flex;
  margin: 0.4rem 0 !important;

  &.last {
    margin-bottom: 0 !important;
  }

  & .counter {
    min-width: 5rem !important;
    color: $color-font-quaternary !important;
    font-size: 1.4rem !important;

    @include media-breakpoint-down(md) {
      min-width: 4rem !important;
    }
  }

  & .keyIcon,
  & .valueIcon,
  & .dateIcon {
    display: flex !important;
    align-items: center !important;

    & .icon {
      margin-right: 0.5rem !important;
      font-weight: 700 !important;
      font-size: 2rem !important;
    }

    & .key {
      margin-right: 1rem;
      font-weight: 500 !important;
      font-size: 1.4rem !important;

      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }

    & .value {
      font-weight: 500 !important;
      font-size: 1.4rem !important;
    }

    & .key,
    & .date {
      color: $color-font-quaternary !important;
      text-overflow: ellipsis !important;
    }

    & .date {
      margin-right: 1rem;
      margin-left: 0.2rem !important;
      font-weight: 500 !important;
      font-size: 1.2rem !important;
    }
  }

  & .keyIcon {
    align-items: center;
    min-width: 18rem !important;
    max-width: 18rem !important;

    @include media-breakpoint-down(md) {
      min-width: 0 !important;
    }
  }

  & .dateIcon {
    margin-top: 1rem;

    & .icon {
      margin-right: 0 !important;
    }
  }

  & .valueIcon {
    margin-right: 0 !important;

    @include media-breakpoint-down(md) {
      white-space: normal !important;
    }

    & a {
      text-decoration: none;
    }

    & .icon {
      margin-right: 0 !important;
      margin-left: 0.5rem !important;
    }
  }

  & .dateIcon-container {
    display: flex !important;
    align-items: center !important;

    @include media-breakpoint-down(xs) {
      align-items: flex-start !important;

      & .dateIcon {
        & .date {
          margin-left: 1rem !important;
        }
      }
    }

    & div:not(:first-child) {
      @include media-breakpoint-down(md) {
        margin-left: 0 !important;
      }
    }
  }

  & .more-info.co-collapse {
    padding-top: 0.5rem !important;
    border-top: 0 !important;

    & :deep(.title) {
      width: initial !important;
      font-size: 1.2rem !important;

      &::after {
        display: none !important;
        margin-left: 0 !important;
      }
    }

    & :v-deep(.title.inactive) {
      color: $color-gray !important;
    }
  }
}

.dateIcon {
  & .icon {
    margin-right: 0 !important;
  }
}

.content {
  margin-top: 2rem;
}

.has-more {
  color: $color-primary;
  font-size: 1.6rem;
  cursor: pointer;
  text-align: center;
}
</style>
