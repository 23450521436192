<template>
  <div class="page-financial-report">
    <div class="p-initial">
      <div class="row">
        <div class="col-12 col-xxl-8">
          <PageCard :title="t('financialReport.title')"
                    icon="reports">
            <template v-if="!financialReport.empty"
                      #controls>
              <div class="d-flex flex-column flex-md-row">
                <div class="co-checkbox-primary d-none d-md-block">
                  <Checkbox v-model="showInThousand"
                            input-id="showInThousand"
                            :binary="true" />
                  <label for="showInThousand">
                    {{ t('financialReport.checkboxes.showInThousand', {currency: financialReport['balance'].table.meta.currency }) }}
                  </label>
                </div>
              </div>
              <a class="button button--primary button--small button--arrow downloadButton"
                 @click="download(financialReport.downloadUrl)">
                {{ t('financialReport.checkboxes.downloadXLSX') }}
              </a>
            </template>

            <template #content>
              <EmptyState v-if="financialReport.empty"
                          page="financialReport" />
              <template v-else>
                <div class="year-container">
                  <Chip v-for="y in financialReport.years"
                        :key="y"
                        :label="t('financialReport.type')">
                    <a class="p-chip-text"
                       :class="{active: y === currentYear}"
                       @click="selectYear(y)">{{ y }}</a>
                  </Chip>
                </div>
                <TabView @tabClick="tabClick">
                  <TabPanel :header="t('financialReport.tabs.balance')">
                    <p class="mx-3">
                      <FinancialReportSheet :data="financialReport.balance"
                                            :show-in-thousand="showInThousand" />
                    </p>
                  </TabPanel>
                  <TabPanel :header="t('financialReport.tabs.incomeStatement')">
                    <p class="mx-3">
                      <FinancialReportSheet :data="financialReport.incomeStatement"
                                            :show-in-thousand="showInThousand" />
                    </p>
                  </TabPanel>
                  <TabPanel v-for="(tab, index) in financialReport.downloads"
                            :key="'downloads-' + index"
                            :header="tab.title">
                    <div class="mx-3">
                      <div class="mt-3 mb-3">
                        {{ tab.description }}
                      </div>
                      <a class="button button--primary"
                         :style="{ width: '150px' }"
                         @click="download(tab.downloadUrl)">
                        {{ t('financialReport.download') }}
                      </a>
                    </div>
                  </TabPanel>
                </TabView>
              </template>
            </template>
          </PageCard>
        </div>
        <div class="col-12 col-xxl-4">
          <DidYouKnow page-name="financial-report"
                      :sticky="true"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, watch, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { http, t } from "@/logic";

  const props = defineProps(["onlyLastReport", "year"]);

  const route = useRoute();
  const currentTable = ref(0);
  const showInThousand = ref(false);
  const currentYear = ref("");
  const financialReport = ref({
    downloadUrl: "",
    years: [],
    balance: {
      header: {},
      table: {
        meta: {},
        columns: [],
        items: [],
      },
    },
    incomeStatement: {
      header: {},
      table: {
        meta: {},
        columns: [],
        items: [],
      },
    },
    downloads: [],
  } as any);

  function tabClick(e){
    currentTable.value = e.index;
  }

  async function load() {
    let query = "";
    if (currentYear.value) {
      if (props.year) {
        query += `year=${props.year}&`;
      } else {
        query += `year=${currentYear.value}&`;
      }
    } else {
      if (props.year) query += `year=${props.year}&`;
    }
    if (props.onlyLastReport) {
      query += "only-last=true&";
    }
    await http.fetch(financialReport, `/api/report/${route.params.id}/financial-report?${query}`);    
  }

  async function selectYear(year) {
    currentYear.value = year;
    await load();
  }

  async function download(url) {
    if (currentTable.value == 1) {
      url = url.replace("/M", "/E");
    }

    await http.blob(url);
  }

  onMounted(async () => {
    await load();

    if (financialReport.value.years) {
      currentYear.value = financialReport.value.years[0];
    }
  });

  watch(() => props.onlyLastReport, load);
</script>

<style lang="scss" scoped>
.downloadButton {
  @include media-breakpoint-down(md) {
    top: 13.5rem;
    right: 3rem;
  }

  @include media-breakpoint-only(xs) {
    top: 14.5rem;
    width: 9rem;
    min-width: 9rem;
    text-align: center;
  }
}
</style>
