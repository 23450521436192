<template>
  <div v-if="locale == 'hu'"
       class="didYouKnowMargin">
    <Vue3StickySidebar v-if="sticky" 
                       class="d-none d-xxl-block sidebar" 
                       container-selector="main" 
                       inner-wrapper-selector=".sidebar__inner"
                       :top-spacing="135"
                       :bottom-spacing="30">
      <InfoCard :class="alternativeCard ? 'alternativeCard' : ''"
                icon="tips"
                icon-color="#5d92d2"
                :title="t('companyData.cards.didYouKnow.title')"
                card-type="dropdown"
                dropdown-color="#5d92d2">
        <template #content>
          <div v-html="html" />
        </template>
      </InfoCard>
    </Vue3StickySidebar>

    <InfoCard :class="[alternativeCard ? 'alternativeCard' : '', sticky ? 'd-xxl-none' : '']"
              icon="tips"
              icon-color="#5d92d2"
              :title="t('companyData.cards.didYouKnow.title')"
              card-type="dropdown"
              dropdown-color="#5d92d2">
      <template #content>
        <div v-html="html" />
      </template>
    </InfoCard>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  import Vue3StickySidebar from "vue3-sticky-sidebar";
  import { http, t, user, init } from "@/logic";

  init();

  const props = defineProps(["pageName", "alternativeCard", "sticky"]);

  const html = ref("");
  const locale = ref("hu");

  onMounted(async () => {
    locale.value = user.locale || "hu";
    const rand = Math.floor(Math.random() * (19 - 1)) + 1;
    await http.fetch(html, `/did-you-know/${rand}.html`);
  });
</script>

<style lang="scss" scoped>
.didYouKnowMargin {
  margin-bottom: 10rem;
}
</style>

