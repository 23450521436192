import axios from "axios";
import { User } from "../logic/User";

export const setAxiosInterceptors = (user: User) => {
  axios.interceptors.request.use(function (config) {
    const token = user?.token;
    const locale = user?.locale;

    config.headers.Accept = "application/json";
    config.headers["x-locale"] = locale;

    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response) {
        if (error.response.status === 400) {
          window.location.href = "/error/" + error.response.data.type;
        } else if (error.response.status === 401) {
          user.logout();
          window.location.href = "/login";
        }
      }

      return Promise.reject(error);
    }
  );
};