<template>
  <div :id="group.id"
       class="mt-5">
    <div class="header d-flex align-items-baseline flex-wrap">
      <div class="d-flex flex-nowrap align-items-baseline ellipsis">
        <div :class="['icon', group.icon]" />
        <div class="title ml-2 ellipsis"
             :class="group.active ? '' : 'notActive'">
          {{ group.title }}
        </div>
      </div>
      <div v-if="group.subtitle"
           class="subtitle d-flex ml-2 flex-nowrap ellipsis" 
           :class="group.active ? '' : 'notActive'">
        (
        <div class="ellipsis">
          {{ group.subtitle.count }} {{ group.subtitle.value }}:
        </div>
        <div class="active ml-2 ellipsis"
             :class="group.active ? '' : 'notActive'"> 
          {{ group.subtitle.active }} {{ t("connectionList.active") }}
        </div>,
        <div class="inactive ml-2 ellipsis"
             :class="group.active ? '' : 'notActive'">
          {{ group.subtitle.inactive }} {{ t("connectionList.inactive") }}
        </div>
        )
      </div>
    </div>

    <div v-if="group.subtitle && group.subtitle.banned"
         class="banned">
      {{ t('connections.card.notations.bannedPerson') }}
    </div>

    <div class="row mt-3">
      <div v-for="(item, index) in items"
           :key="'item-' + index"
           class="col-xxl-4 col-md-6 col-12 print-column-3">
        <ConnectionListItem :item="item"
                            :force-open="group.open" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from "vue";
  import { http, t } from "@/logic";

  const props = defineProps(["group", "hideInactives"]);
  const items = computed(() => {
    if (props.hideInactives) {
      return props.group.items.filter((i) => i.active);
    }

    return props.group.items;
  });
</script>

<style scoped lang="scss">
.header {
  font-weight: 500;
  font-size: 1.6rem;
}

.icon {
  color: $color-font-primary;
  font-weight: 500;
  font-size: 2.5rem;
}

.title {
  color: $color-font-primary;
}

.notActive {
  color: silver !important;
}

.active {
  color: #21c100;
}

.inactive {
  color: #000;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.banned {
  margin-left: 0.3em;
  color: $color-red;
  font-weight: bold;
}
</style>
