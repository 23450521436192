<template>
  <div class="interactive-table-header">
    <div class="d-flex">
      <slot name="left" />
    </div>
    <div class="d-flex align-items-center">
      <slot name="right" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.interactive-table-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 2.5rem;

  & .favourite-button,
  & .export-button {
    display: flex;
    align-items: center;
    text-decoration: none;

      &:hover {
        cursor: pointer;

        & span {
          color: $color-primary;
        }
      }


    & .icon {
      margin-right: 0.5rem;
      color: $color-primary;
      font-size: 2.4rem;
    }

    & span {
      color: $color-font-primary;
      font-weight: 500;
      font-size: 1.4rem;
      text-transform: uppercase;
      transition: color 0.2s ease-in-out;
    }
  }

  & .export-button {
    margin-left: 1rem;
  }
}
</style>
