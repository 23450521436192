<template>
  <div class="financialReports">
    <div class="row">
      <div class="col-12 col-md-6">
        <MinMaxField v-model="data.fixedAssets"
                     label="marketing.financialDetails.inputs.investedResources"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.currentAssets"
                     label="marketing.financialDetails.inputs.currentAssets"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.provisions"
                     label="marketing.financialDetails.inputs.reservationGoals"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.liabilities"
                     label="marketing.financialDetails.inputs.responsibilities"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.shareholdersEquity"
                     label="marketing.financialDetails.inputs.ownCapital"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.subscribedCapital"
                     label="marketing.financialDetails.inputs.registeredCapital"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.domesticSales"
                     label="marketing.financialDetails.inputs.inlandIncome"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.exportSales"
                     label="marketing.financialDetails.inputs.foreignIncome"
                     placeholder="marketing.financialDetails.inputs" />
      </div>
      <div class="col-12 col-md-6">
        <MinMaxField v-model="data.totalSales"
                     label="marketing.financialDetails.inputs.netIncome"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.ebitda"
                     label="marketing.financialDetails.inputs.ebitda"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.accruedAndDeferredAssets"
                     label="marketing.financialDetails.inputs.activeTimeLimitation"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.accruedAndDeferredLiabilities"
                     label="marketing.financialDetails.inputs.passiveTimeLimitation"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.incomeFromOperations"
                     label="marketing.financialDetails.inputs.operatingActivityResult"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.incomeBeforeTaxes"
                     label="marketing.financialDetails.inputs.resultBeforeTaxation"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.accountsPayable"
                     label="marketing.financialDetails.inputs.demands"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="data.corporateTax"
                     label="marketing.financialDetails.inputs.corporateTax"
                     placeholder="marketing.financialDetails.inputs" />
      </div>
      <div class="col-12">
        <p class="millions">
          {{ t('marketing.financialDetails.millions') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, watch, onMounted } from "vue";
  import { t } from "@/logic";

  defineExpose({ setDefaults });

  const emit = defineEmits<{
    (e: "input", data: any): void;
  }>();

  const defaultValues = {
    fixedAssets: {
      min: "",
      max: "",
    },
    currentAssets: {
      min: "",
      max: "",
    },
    provisions: {
      min: "",
      max: "",
    },
    liabilities: {
      min: "",
      max: "",
    },
    shareholdersEquity: {
      min: "",
      max: "",
    },
    subscribedCapital: {
      min: "",
      max: "",
    },
    domesticSales: {
      min: "",
      max: "",
    },
    exportSales: {
      min: "",
      max: "",
    },
    totalSales: {
      min: "",
      max: "",
    },
    ebitda: {
      min: "",
      max: "",
    },
    accruedAndDeferredAssets: {
      min: "",
      max: "",
    },
    accruedAndDeferredLiabilities: {
      min: "",
      max: "",
    },
    incomeFromOperations: {
      min: "",
      max: "",
    },
    incomeBeforeTaxes: {
      min: "",
      max: "",
    },
    accountsPayable: {
      min: "",
      max: "",
    },
    corporateTax: {
      min: "",
      max: "",
    },
  };

  const data = ref(defaultValues as any);

  function setDefaults(newValues = null) {
    data.value = Object.assign(data.value, newValues || defaultValues);
    emit("input", data.value);
  }

  onMounted(() => {
    setDefaults();
  });

  watch(() => data, () => emit("input", data.value),  { deep: true });
</script>

<style scoped lang="scss">
.financialReports {
  & .millions {
    margin-top: 3rem;
    font-weight: 600;
    font-size: 1.2rem;
  }
}
</style>
